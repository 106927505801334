import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export const EmailInput = props => {
  const emailErrors = props.errors
    .filter(err => err.code.indexOf('Email') >= 0 || err.code.indexOf('User') >= 0)
    .map(error => <li key={error.code}>{error.description}</li>);
  return (
    <FormGroup>
      <Label for="email" className="sr-only">
        Email
      </Label>
      <Input
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        className={props.className}
        autoComplete="email"
        required
        invalid={props.emailValid != null && !props.emailValid}
        valid={props.emailValid}
        onChange={props.handleChange}
      />
      <FormFeedback>{emailErrors && <ul>{emailErrors}</ul>}</FormFeedback>
    </FormGroup>
  );
};
EmailInput.propTypes = {
  errors: PropTypes.array,
  handleChange: PropTypes.func.isRequired
};
