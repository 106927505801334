import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const RiderAgeCategory = props => {
  const optionItems = props.ageCategories.map(ageCategory => (
    <option key={ageCategory.id} value={ageCategory.id}>
      {ageCategory.name}
    </option>
  ));
  return (
    <FormGroup>
      <Label>Age Category</Label>
      <Input
        type="select"
        name="riderAgeCategoryId"
        className="custom-select"
        onChange={props.handleChange}
        defaultValue={0}
      >
        <option value={0}>Choose...</option>
        {optionItems}
      </Input>
    </FormGroup>
  );
};

RiderAgeCategory.propTypes = {
  ageCategories: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired
};
