import { teamRosterConstants } from '../_constants';

export function teamRoster(state = {}, action) {
  switch (action.type) {
    case teamRosterConstants.TEAM_ROSTER_CREATE_REQUEST:
    case teamRosterConstants.TEAM_ROSTER_CREATE_FAILURE:
    case teamRosterConstants.TEAM_ROSTER_UPDATE_FAILURE:
    case teamRosterConstants.TEAM_ROSTER_DELETE_REQUEST:
    case teamRosterConstants.TEAM_ROSTER_DELETE_FAILURE:
    case teamRosterConstants.TEAM_ROSTER_LIST_REQUEST:
    case teamRosterConstants.TEAM_ROSTER_LIST_FAILURE:
    case teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_REQUEST:
    case teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_FAILURE:
      return {
        ...state
      };
    case teamRosterConstants.TEAM_ROSTER_CREATE_SUCCESS:
      return {
        ...state,
        roster: [...action.data]
      };
    case teamRosterConstants.TEAM_ROSTER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case teamRosterConstants.TEAM_ROSTER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        roster: [...action.data]
      };

    case teamRosterConstants.TEAM_ROSTER_DELETE_SUCCESS:
      return {
        ...state,
        roster: [...action.data]
      };

    case teamRosterConstants.TEAM_ROSTER_LIST_SUCCESS:
      return {
        ...state,
        roster: [...action.data]
      };

    case teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_SUCCESS:
      return {
        ...state,
        riders: [...action.data]
      };

    default:
      return state;
  }
}
