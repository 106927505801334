import { authFetch, headers } from '../_helpers';

export const riderPowerService = {
  getTypes,
  getTypesAndList,
  update
};

async function getTypes() {
  const response = await authFetch(`/api/RiderPower/Types`, headers.get());
  return response;
}

async function getTypesAndList() {
  const response = await authFetch(`/api/RiderPower/TypesAndList`, headers.get());
  return response;
}

async function update(data) {
  const response = await authFetch(`/api/RiderPower/Update`, headers.post(data));
  return response;
}
