import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row } from 'reactstrap';
import { Social, Summary, Subheader, Staff, ProfileHeader, Roster, RaceResults, Notices } from '.';
import { WorldChampLoader } from '../Loaders';
import { teamProfileActions } from '../_actions';
import { colours } from '../_helpers';

function TeamProfile(props) {
  const { list, user, getTeamProfile } = props;
  const profileSlug = props.match.params.profileSlug;

  useEffect(() => {
    getTeamProfile(profileSlug);
  }, [getTeamProfile, profileSlug]);

  let team;
  if (list) {
    team = list[profileSlug];
  }

  return (
    <div className="team-profile">
      <Helmet title={`${team ? team.teamProfile.teamName : 'Team Profile'} - Cycling Connect`} />
      {!team && <WorldChampLoader />}
      {team && <ProfileHeader profile={team.teamProfile} user={user} />}
      <Container
        style={{
          height: `65px`,
          background: `#fff`,
          marginTop: `-65px`,
          borderTop: `4px solid ${colours.teamTheme.primary}`,
          boxShadow: `0px -4px 7px 0px rgba(255, 255, 255, 0.75)`
        }}
      >
        {team && team.teamProfile && <Subheader profile={team.teamProfile} />}
      </Container>
      {team && (
        <Container className="profile-body pt-5">
          <Row>
            <Staff profile={team.teamProfile} />
            <Summary profile={team.teamProfile} />
            <Notices notices={team.teamProfile.notices} />
            <Roster roster={team.teamProfile.roster} />
            <RaceResults races={team.teamProfile.races} />
            <Social profile={team.teamProfile} />
          </Row>
        </Container>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { loading, list } = state.teamProfiles;
  const { user } = state.authentication;
  return {
    loading,
    list,
    user
  };
}

const connectedTeamProfile = connect(
  mapStateToProps,
  { getTeamProfile: teamProfileActions.getTeamProfile }
)(TeamProfile);
export { connectedTeamProfile as TeamProfile };
