import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';

export const SearchPagination = props => {
  const { activePage, itemsCountPerPage, totalItemsCount, onChange } = props;
  return (
    <Row>
      <Col>
        <nav>
          <Pagination
            innerClass="pagination pagination-lg justify-content-center"
            itemClass="page-item"
            linkClass="page-link"
            activePage={activePage + 1}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={10}
            onChange={onChange}
          />
        </nav>
      </Col>
    </Row>
  );
};

SearchPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
