import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { dateHelper } from '../_helpers';

export const NoticeActions = props => {
  const { notice, handleCancel, handleExtend } = props;
  return (
    <ButtonGroup className="btn-block">
      <Button color="primary w-auto">Expires: {dateHelper.toShort(notice.dateExpires)}</Button>
      <Button color="info" className="col" onClick={() => handleExtend(notice.id)}>
        Extend
      </Button>
      <Button color="danger" className="col" onClick={() => handleCancel(notice.id)}>
        Cancel
      </Button>
    </ButtonGroup>
  );
};
