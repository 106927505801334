import { headers, authFetch } from '../_helpers';

export const riderCareerHighlightsService = {
  listCareerHighlights,
  createUpdateCareerHighlight,
  deleteCareerHighlight
};

async function listCareerHighlights() {
  const response = await authFetch(`/api/RiderProfile/ListCareerHighlights`, headers.post());
  return response;
}

async function deleteCareerHighlight(highlightId) {
  const response = await authFetch(
    `/api/RiderProfile/DeleteCareerHighlight`,
    headers.postPrimitive(highlightId)
  );
  return response;
}

async function createUpdateCareerHighlight(careerHighlight) {
  const response = await authFetch(
    `/api/RiderProfile/CreateUpdateCareerHighlight`,
    headers.post(careerHighlight)
  );
  return response;
}
