import { todaysPlanConstants } from '../_constants';
import { todaysPlanService } from '../_services';

export const todaysPlanActions = {
  tokenGrant,
  peakWattsPerKg
};

function tokenGrant(code) {
  return dispatch => {
    dispatch(request(code));
    todaysPlanService.tokenGrant(code).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: todaysPlanConstants.TOKEN_GRANT_REQUEST, data };
  }
  function success(data) {
    return { type: todaysPlanConstants.TOKEN_GRANT_SUCCESS, data };
  }
  function failure(error) {
    return { type: todaysPlanConstants.TOKEN_GRANT_FAILURE, error };
  }
}

function peakWattsPerKg() {
  return dispatch => {
    dispatch(request());
    todaysPlanService.peakWattsPerKg().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: todaysPlanConstants.PEAK_W_PER_KG_REQUEST, data };
  }
  function success(data) {
    return { type: todaysPlanConstants.PEAK_W_PER_KG_SUCCESS, data };
  }
  function failure(error) {
    return { type: todaysPlanConstants.PEAK_W_PER_KG_FAILURE, error };
  }
}
