import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { newsActions } from '../_actions';
import { KingOfMountainLoader } from '../Loaders';
import { NewsSingleHeader } from '.';

class NewsSingle extends React.Component {
  componentDidMount = () => {
    const slug = this.props.match.params.slug;
    this.props.getNewsBySlug(slug);
  };

  render() {
    const {
      news,
      news: { single }
    } = this.props;
    return (
      <div>
        <Helmet title={`${single ? single.title : 'News'} - Cycling Connect`} />
        {news.singleLoading && (
          <Col>
            <KingOfMountainLoader />
          </Col>
        )}
        {!news.singleLoading && single && (
          <div>
            <NewsSingleHeader single={single} />
            <Container>
              <Row>
                <Col xs={12} lg={9} dangerouslySetInnerHTML={{ __html: single.content }} />
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const connectedNewsSingle = connect(
  mapStateToProps,
  { getNewsBySlug: newsActions.getNewsBySlug }
)(NewsSingle);
export { connectedNewsSingle as NewsSingle };
