import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export class RichEditor extends React.Component {
  state = { hasFocus: false };
  classes = 'form-control editor';
  focusClasses = 'form-control editor has-focus';

  onChange = editorState => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const { name = 'summary' } = this.props;
    this.props.handleChange({ target: { name: name, value: markup } });
  };

  render() {
    const { className } = this.props;
    const blocksFromHtml = htmlToDraft(this.props.initialState || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return (
      <div>
        <Editor
          onEditorStateChange={this.onChange}
          editorClassName={`editor ${className} ${
            this.state.hasFocus ? this.focusClasses : this.classes
          }`}
          wrapperClassName={`wrapper ${className}`}
          onFocus={() => this.setState({ hasFocus: true })}
          onBlur={() => this.setState({ hasFocus: false })}
          defaultEditorState={editorState}
          toolbarClassName={`toolbar ${className}`}
          stripPastedStyles={true}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'emoji', 'textAlign'],
            inline: {
              options: ['bold', 'italic', 'underline'],
              bold: { className: 'bordered-option-classname' },
              italic: { className: 'bordered-option-classname' },
              underline: { className: 'bordered-option-classname' }
            },
            textAlign: {
              inDropdown: true
            },
            blockType: {
              className: 'bordered-option-classname',
              options: ['Normal', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
            },
            fontSize: {
              className: 'bordered-option-classname',
              options: [8, 9, 10, 11, 12, 14, 16, 18, 24]
            }
          }}
        />
      </div>
    );
  }
}
