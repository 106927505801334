import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from 'reactstrap';

export const ApprovedFooter = props => (
  <ButtonGroup className="btn-block">
    <Button
      className="col"
      to={`/${props.isRider ? 'rider' : 'team'}/${props.user.profileSlug}`}
      tag={Link}
      color="primary"
    >
      Profile
    </Button>
    <Button className="col" color="danger" value={props.user.id} onClick={props.removeModalToggle}>
      Remove
    </Button>
    {props.isRider && (
      <Button tag={Link} to="/recruit" className="col" color="info">
        Recruitment »
      </Button>
    )}
  </ButtonGroup>
);
