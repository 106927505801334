import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

export const Biography = props => {
  const { profile } = props;

  return (
    profile.biography && (
      <Col xs={12} md={6}>
        <Col className="rider-biography rider-data shadow mb-5 pb-4">
          <FormLabel rider text="Biography" />
          <Row>
            <Col>
              <div dangerouslySetInnerHTML={{ __html: profile.biography }} />
            </Col>
          </Row>
        </Col>
      </Col>
    )
  );
};

Biography.propTypes = {
  profile: PropTypes.object.isRequired
};
