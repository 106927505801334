import React from 'react';
import { CardHeader, ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { RaceResultCard } from './';
import { FormLabel } from '../Shared';
import isEmpty from 'lodash/isEmpty';

export const RaceResults = props => {
  const { races } = props;
  const filteredRaces = races.filter(r => !isEmpty(r.results));

  if (isEmpty(races) || isEmpty(filteredRaces)) {
    return null;
  }

  return (
    <Col xs={12}>
      <Col className="team-data pb-4 shadow">
        <Row>
          <Col xs={12}>
            <FormLabel team text="Race Results" />
            <Row className="mt-4">
              {filteredRaces.map((race, index) => (
                <Col md={4} lg={3} key={index} className="result">
                  <div className="wrap">
                    <CardHeader>
                      <strong>
                        {race.name} - {race.year}
                      </strong>
                    </CardHeader>

                    <ListGroup flush>
                      {race.results.map((result, idx) => (
                        <ListGroupItem key={idx}>
                          <RaceResultCard result={result} />
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
