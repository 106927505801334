import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Row, Col, Label, Form, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import { raceActions } from '../_actions';

const defaultState = {
  place: '',
  stage: '',
  riderName: '',
  editedResults: {},
  editedRaceNames: {}
};

class RaceResults extends React.Component {
  state = { ...defaultState };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  addResult = e => {
    e.preventDefault();
    const { place, riderName } = this.state;
    const { raceId } = this.props;
    if (raceId && place && riderName.length) {
      this.props.createRaceResult({ raceId, place, riderName });
      this.setState({ place: '', riderName: '' });
    }
  };

  editResult = (e, id) => {
    const { name, value } = e.target;
    const { raceId, results } = this.props;
    const result = results[raceId].find(r => r.id === id);
    result[name] = value;
    const { editedResults } = this.state;
    editedResults[id] = result;
    this.setState({ editedResults });
  };

  deleteResult = (e, id) => {
    e.preventDefault();
    this.props.deleteRaceResult(id);
  };

  updateResult = (e, id) => {
    e.preventDefault();
    const result = this.state.editedResults[id];
    if (result) {
      this.props.updateRaceResult(result);
    }
  };

  handeRaceNameChange = (e, id) => {
    const { value } = e.target;
    const { editedRaceNames } = this.state;
    editedRaceNames[id] = value;
    this.setState({ editedRaceNames });
  };

  saveRaceNameChange = (e, id) => {
    e.preventDefault();
    const name = this.state.editedRaceNames[id];
    if (name && name.length) {
      this.props.updateRace({ id, name });
    }
  };

  render() {
    const { raceId, results } = this.props;
    const race = this.props.races.find(r => r.id === raceId);
    const raceResults = results[raceId];
    return (
      <Col>
        <div>
          <Form onSubmit={e => this.saveRaceNameChange(e, race.id)}>
            <Row>
              <Col md={9}>
                <FormGroup>
                  <Input
                    name="raceName"
                    defaultValue={race.name}
                    onChange={e => this.handeRaceNameChange(e, race.id)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Button outline block color="primary">
                  <FontAwesomeIcon icon="check" />
                </Button>
              </Col>
            </Row>
          </Form>

          <Form onSubmit={this.addResult}>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Label for="place">Place</Label>
                  <Input
                    type="number"
                    name="place"
                    id="place"
                    value={this.state.place}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="stage">
                    Stage{' '}
                    <span id="stage-tooltip">
                      {' '}
                      <FontAwesomeIcon icon="question-circle" id="stage-tooltip" />
                    </span>
                  </Label>
                  <UncontrolledTooltip placement="top" target="stage-tooltip">
                    This can be the name or number of the stage
                  </UncontrolledTooltip>
                  <Input
                    type="number"
                    name="stage"
                    id="stage"
                    value={this.state.stage}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="riderName">Rider</Label>
                  <Input
                    name="riderName"
                    id="riderName"
                    value={this.state.riderName}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="add">Add</Label>
                  <Button outline block color="primary">
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {raceResults &&
            raceResults.map((res, idx) => (
              <Form onSubmit={e => this.updateResult(e, res.id)} key={idx} className="mb-2">
                <Row>
                  <Col md={2}>
                    <Input
                      name="place"
                      defaultValue={res.place}
                      onChange={e => this.editResult(e, res.id)}
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      name="stage"
                      defaultValue={res.stage}
                      onChange={e => this.editResult(e, res.id)}
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      name="riderName"
                      defaultValue={res.riderName}
                      onChange={e => this.editResult(e, res.id)}
                    />
                  </Col>
                  <Col md={3}>
                    <Row className="no-gutters">
                      <Col sm={6}>
                        <Button outline block color="primary">
                          <FontAwesomeIcon icon="check" />
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          outline
                          block
                          color="danger"
                          onClick={e => this.deleteResult(e, res.id)}
                        >
                          <FontAwesomeIcon icon="times" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            ))}
        </div>
      </Col>
    );
  }
}
const mapDispatch = {
  createRaceResult: raceActions.createRaceResult,
  deleteRaceResult: raceActions.deleteRaceResult,
  updateRaceResult: raceActions.updateRaceResult,
  updateRace: raceActions.updateRace
};
function mapStateToProps(state) {
  const { results = {}, races } = state.races;
  return { results, races };
}

const connectedRaceResults = connect(
  mapStateToProps,
  mapDispatch
)(RaceResults);
export { connectedRaceResults as RaceResults };
