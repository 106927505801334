export const accountConstants = {
  GET_ACCT_REQUEST: 'GET_ACCT_REQUEST',
  GET_ACCT_SUCCESS: 'GET_ACCT_SUCCESS',
  GET_ACCT_FAILURE: 'GET_ACCT_FAILURE',
  UPDATE_ACCT: 'UPDATE_ACCT',
  UPDATE_ACCT_REQUEST: 'UPDATE_ACCT_REQUEST',
  UPDATE_ACCT_SUCCESS: 'UPDATE_ACCT_SUCCESS',
  UPDATE_ACCT_FAILURE: 'UPDATE_ACCT_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
  CHECK_MC_STATUS_REQUEST: 'CHECK_MC_STATUS_REQUEST',
  CHECK_MC_STATUS_SUCCESS: 'CHECK_MC_STATUS_SUCCESS',
  CHECK_MC_STATUS_FAILURE: 'CHECK_MC_STATUS_FAILURE',
  TOGGLE_MAILING_LIST_REQUEST: 'TOGGLE_MAILING_LIST_REQUEST',
  TOGGLE_MAILING_LIST_SUCCESS: 'TOGGLE_MAILING_LIST_SUCCESS',
  TOGGLE_MAILING_LIST_FAILURE: 'TOGGLE_MAILING_LIST_FAILURE',
  DELETE_FROM_MAILING_LIST_REQUEST: 'DELETE_FROM_MAILING_LIST_REQUEST',
  DELETE_FROM_MAILING_LIST_SUCCESS: 'DELETE_FROM_MAILING_LIST_SUCCESS',
  DELETE_FROM_MAILING_LIST_FAILURE: 'DELETE_FROM_MAILING_LIST_FAILURE'
};
