import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { FormLabel } from '../Shared';
import { dashboardActions } from '../_actions';
import { PlaceholderCard } from '../Shared';
import { NewsItem } from './';

function LatestNews(props) {
  const { news, team = false, rider = false, latestNews } = props;
  useEffect(() => {
    latestNews();
  }, [latestNews]);

  return (
    <Container fluid className="news mb-5 pb-2">
      <Row>
        <Col xs={12}>
          <FormLabel rider={rider} team={team} text="Latest News" />
        </Col>
        <Col className="mt-4">
          {!news && <p>Loading...</p>}
          {isEmpty(news) && <PlaceholderCard message="No News to show." />}
          {news && news.map((n, index) => <NewsItem key={index} news={n} />)}
        </Col>
      </Row>
    </Container>
  );
}
const mapDispatch = {
  latestNews: dashboardActions.latestNews
};
function mapStateToProps(state) {
  const { news = null } = state.dashboard;
  return { news };
}
const connectedLatestNews = connect(
  mapStateToProps,
  mapDispatch
)(LatestNews);
export { connectedLatestNews as LatestNews };
