import React from 'react';
import { wsBase } from '../_helpers';
import * as signalR from '@aspnet/signalr';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';
import { authToken } from '../_helpers';
import { PlaceholderCard } from '../Shared';
import { NotificationCard } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      chatHubConnection: null
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${wsBase()}/notifications`, {
        accessTokenFactory: () => authToken()
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  toggleRead = (e, id) => {
    e.preventDefault();
    this.connection.invoke('ToggleRead', id).catch(err => {
      return console.error(err.toString());
    });
  };

  archive = (e, id) => {
    e.preventDefault();
    this.connection.invoke('ToggleArchived', id).catch(err => {
      return console.error(err.toString());
    });
  };

  componentDidMount = () => {
    this.connection
      .start()
      .then(() => console.log('SignalR started'))
      .catch(err => console.log(`Error connecting SignalR - ${err}`));
    // this is the method called when the connection is closed. Need to gracefully attempt to reconnect.
    this.connection.onclose(() => {
      console.warn('hub disconnected');
    });

    this.connection.on('SendNotification', message => {
      const { notifications } = this.state;
      notifications.unshift(message);
      this.setState({ notifications });
    });

    this.connection.on('SendNotifications', notifications => {
      this.setState({ notifications });
    });

    this.connection.on('ToggleRead', message => {
      const { notifications } = this.state;
      const idx = notifications.findIndex(n => n.id === message.id);
      if (idx === -1) {
        notifications.unshift(message);
      } else {
        notifications[idx] = message;
      }
      this.setState({ notifications });
    });

    this.connection.on('ToggleArchived', message => {
      const { notifications } = this.state;
      const idx = notifications.findIndex(n => n.id === message.id);
      if (idx !== -1) {
        notifications.splice(idx, 1);
      }
      this.setState({ notifications });
    });
  };

  componentWillUnmount = () => {
    this.connection.stop();
  };

  render() {
    const noNotifications = !this.state.notifications.length;
    const { rider = false, team = false } = this.props;
    return (
      <Container className="notifications grey-bg pb-3 mb-5">
        <Row>
          <Col xs={12}>
            <FormLabel team={team} rider={rider} text={<FontAwesomeIcon icon="bell" />} />
          </Col>
          <Col className="notification-wrapper mt-4">
            {noNotifications && <PlaceholderCard message="No Notifications" />}
            {this.state.notifications.map((n, index) => (
              <NotificationCard
                key={index}
                notification={n}
                toggleRead={this.toggleRead}
                archive={this.archive}
              />
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}
