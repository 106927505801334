import { riderPowerConstants } from '../_constants';

export function riderPower(state = {}, action) {
  switch (action.type) {
    case riderPowerConstants.POWER_TYPES_GET_SUCCESS:
      return {
        ...state,
        types: action.data.types
      };
    case riderPowerConstants.POWER_TYPES_GET_REQUEST:
    case riderPowerConstants.POWER_TYPES_GET_FAILURE:
    case riderPowerConstants.POWER_UPDATE_FAILURE:
    case riderPowerConstants.TYPES_AND_LIST_REQUEST:
    case riderPowerConstants.TYPES_AND_LIST_FAILURE:
    case riderPowerConstants.POWER_UPDATE_REQUEST:
      return {
        ...state
      };
    case riderPowerConstants.TYPES_AND_LIST_SUCCESS:
      return {
        ...state,
        types: action.data.types,
        data: action.data.data
      };

    case riderPowerConstants.POWER_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}
