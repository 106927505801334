import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { TeamSearchCard, SearchPagination } from './';
import { KingOfMountainLoader } from '../Loaders';

function TeamSearchResults(props) {
  const perPage = 30;
  const { pageChange, loading, success, page, totalResults, results } = props;
  const startResults = page * perPage + 1;
  const endResults = totalResults <= perPage ? totalResults : (page + 1) * perPage;
  return (
    <Container>
      <Row>
        {loading && (
          <Col>
            <KingOfMountainLoader />
          </Col>
        )}
        {isEmpty(results) && success && (
          <Col>
            <p>No results for these terms</p>
          </Col>
        )}
        {!isEmpty(results) && (
          <Fragment>
            <Row>
              <Col>
                Showing results {startResults} to {endResults}
              </Col>
            </Row>
            <Row>
              {results.map((team, index) => (
                <TeamSearchCard key={index} team={team} />
              ))}
            </Row>
            <SearchPagination
              activePage={page}
              itemsCountPerPage={perPage}
              totalItemsCount={totalResults}
              onChange={pageChange}
            />
          </Fragment>
        )}
      </Row>
    </Container>
  );
}
function mapStateToProps(state) {
  const { loading, success, page, totalPages, totalResults, results } = state.teamSearch;
  return { loading, success, page, totalPages, totalResults, results };
}
const connectedTeamSearchResults = connect(mapStateToProps)(TeamSearchResults);
export { connectedTeamSearchResults as TeamSearchResults };
