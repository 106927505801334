import React from 'react';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';

export const SearchCardProfilePhoto = props => {
  const { user } = props;
  const imageStyles = {
    background: `rgba(0, 0, 0, 0) url('${user.profilePhoto}') no-repeat scroll 50% 50% / cover`
  };
  const profilePhoto = (
    <div style={imageStyles} className="flex-grow-1 d-flex align-items-end">
      <div className="name">{user.displayName || user.teamName}</div>
    </div>
  );
  const withoutPhoto = (
    <div className="d-flex h-100 flex-column align-items-center">
      <FontAwesomeIcon icon="user" size="5x" className="flex-grow-1" />
      <div className="name mt-auto">{user.displayName || user.teamName}</div>
    </div>
  );
  return (
    <Col className="d-flex flex-column">
      {isEmpty(user.profilePhoto) ? withoutPhoto : profilePhoto}
    </Col>
  );
};
