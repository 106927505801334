import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Container, Row, Col, Button } from 'reactstrap';
import { RiderSexSelect, LocationFinder } from '../Inputs/';
import {
  RiderAgeCategory,
  RacingLevelMultiSelect,
  RiderStyleMultiSelect,
  RiderSearchResults
} from './';
import {
  racingLevelsActions,
  riderStylesActions,
  riderAgeCategoriesActions,
  teamProfileActions,
  riderSearchActions,
  riderSexesActions
} from '../_actions';

const defaultState = {
  city: '',
  state: '',
  country: ''
};

class RiderSearchForm extends React.Component {
  state = { ...defaultState };

  componentDidMount = () => {
    this.props.getProfile();
    this.props.list();
    this.props.getRiderStyles();
    this.props.getRiderAgeCategories();
    this.props.getRiderSexes();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  eagerCountryChange = country => {
    this.setState({ country });
  };

  multiSelectChange = e => {
    const { name, value } = e.target;
    const ids = value.map(val => val.value);
    this.setState({ [name]: ids });
  };

  handleSubmit = e => {
    e.preventDefault();
    const terms = { ...this.state };
    this.props.search(terms);
  };

  pageChange = page => {
    const terms = { ...this.state, page: page - 1 };
    this.props.search(terms);
  };

  render() {
    const { styles, levels, ageCategories, sexes } = this.props;
    return (
      <Fragment>
        <Form className="search-form" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col xs={12} sm={6}>
                <LocationFinder
                  handleChange={this.handleChange}
                  eagerCountryChange={this.eagerCountryChange}
                  location="Anywhere"
                />
              </Col>
              {ageCategories && (
                <Col xs={6} sm={3}>
                  <RiderAgeCategory
                    handleChange={this.handleChange}
                    ageCategories={ageCategories}
                  />
                </Col>
              )}
              {sexes && (
                <Col xs={6} sm={3}>
                  <RiderSexSelect sexes={sexes} handleChange={this.handleChange} />
                </Col>
              )}
              {styles && (
                <Col xs={12} sm={5}>
                  <RiderStyleMultiSelect styles={styles} handleChange={this.multiSelectChange} />
                </Col>
              )}
              {levels && (
                <Col xs={12} sm={5}>
                  <RacingLevelMultiSelect levels={levels} handleChange={this.multiSelectChange} />
                </Col>
              )}
              <Col sm={2}>
                <Button outline block color="primary" className="search-submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        <RiderSearchResults pageChange={this.pageChange} />
      </Fragment>
    );
  }
}
const mapDispatch = {
  getProfile: teamProfileActions.getProfile,
  list: racingLevelsActions.list,
  getRiderStyles: riderStylesActions.getRiderStyles,
  getRiderAgeCategories: riderAgeCategoriesActions.getRiderAgeCategories,
  getRiderSexes: riderSexesActions.getRiderSexes,
  search: riderSearchActions.search
};
function mapStateToProps(state) {
  const {
    riderStyles: { styles },
    racingLevels: { levels },
    riderAgeCategories: { ageCategories },
    riderSexes: { sexes }
  } = state;

  return {
    styles,
    levels,
    ageCategories,
    sexes
  };
}

const connectedRiderSearchForm = connect(
  mapStateToProps,
  mapDispatch
)(RiderSearchForm);
export { connectedRiderSearchForm as RiderSearchForm };
