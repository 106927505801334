import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import { dateFormatter } from '../_helpers';

export const NewsCard = props => {
  const { news } = props;
  return (
    <Col xs={12} md={4} className="mb-4">
      <Card className="p-2 shadow">
        <Link to={`/news/${news.slug}`}>
          <CardImg top width="100%" src={news.image} />
        </Link>
        <CardBody className="pl-1 pr-1 pb-1">
          <CardTitle>
            <Link to={`/news/${news.slug}`}>{news.title}</Link>
          </CardTitle>
          <CardText className="text-muted">{dateFormatter(news.datePublished)}</CardText>
        </CardBody>
        {props.children}
      </Card>
    </Col>
  );
};
NewsCard.propTypes = {
  news: PropTypes.object.isRequired
};
