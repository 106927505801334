import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button, UncontrolledTooltip } from 'reactstrap';
import { colours, dateHelper } from '../../_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NoticeCardFooter = props => {
  const { notice, contactToggle, profileView } = props;
  const { team } = notice;
  const { profileSlug } = team;

  const expiryColour = colours.greenToRed(notice.timeConsumed);
  const styles = {
    backgroundColor: expiryColour,
    borderColor: expiryColour
  };
  const profileUrl = `/team/${profileSlug}`;
  return (
    <div>
      <ButtonGroup className="btn-block">
        <Button
          className="col"
          color="primary"
          tag={Link}
          to={profileUrl}
          onClick={() => profileView(notice.id)}
        >
          Profile
        </Button>
        <Button
          className="col"
          color="warning"
          onClick={() => contactToggle(team.teamName, notice.id)}
        >
          Contact
        </Button>

        <Button className="col-sm-2" style={styles} id={`notice-exp-${notice.id}`}>
          <FontAwesomeIcon icon="clock" />
        </Button>
        <UncontrolledTooltip target={`notice-exp-${notice.id}`} placement="top">
          Expires: {dateHelper.toShort(notice.dateExpires)}
        </UncontrolledTooltip>
      </ButtonGroup>
    </div>
  );
};

NoticeCardFooter.propTypes = {
  notice: PropTypes.object.isRequired
};
