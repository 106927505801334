import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { newsActions } from '../_actions';
import { KingOfMountainLoader } from '../Loaders';
import { NewsCard } from './';

class NewsIndex extends React.Component {
  componentDidMount = () => {
    this.props.listNews();
  };

  render() {
    const { list, newsLoading } = this.props.news;
    return (
      <div className="news-index">
        <Helmet title="Latest News - Cycling Connect" />
        <Jumbotron className="mb-0 banner">
          <Container>
            <Row>
              <Col>
                <h2 className="display-4">Latest News</h2>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Jumbotron className="pb-4 mb-0 light-grey-bg">
          <Container>
            <Row>
              {newsLoading && (
                <Col>
                  <KingOfMountainLoader />
                </Col>
              )}

              {!isEmpty(list) && list.map((news, index) => <NewsCard news={news} key={index} />)}
            </Row>
          </Container>
        </Jumbotron>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const connectedNewsIndex = connect(
  mapStateToProps,
  { listNews: newsActions.listNews }
)(NewsIndex);
export { connectedNewsIndex as NewsIndex };
