import { authFetch, headers } from '../_helpers';

export const refereeTypesService = {
  getRefereeTypes
};

async function getRefereeTypes() {
  const response = await authFetch(`/api/Referee/Types`, headers.get());
  return response;
}
