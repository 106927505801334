import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const KingOfMountainLoader = () => (
  <div className="kom-loader d-flex">
    <div className="flex-grow-1 align-self-center text-center">
      <FontAwesomeIcon icon="spinner" spin /> Loading
    </div>
  </div>
);
