import { recruitConstants } from '../_constants';

export function recruit(state = {}, action) {
  switch (action.type) {
    case recruitConstants.LIST_RECRUITS_REQUEST:
    case recruitConstants.ADD_RIDER_TO_RECRUIT_REQUEST:
    case recruitConstants.ADD_RIDER_TO_RECRUIT_FAILURE:
    case recruitConstants.ADD_NOTE_FAILURE:
    case recruitConstants.UPDATE_STAGE_FAILURE:
    case recruitConstants.LIST_RECRUITS_FAILURE:
      return {
        ...state
      };
    case recruitConstants.LIST_RECRUITS_SUCCESS:
      const { list } = action.data;
      return {
        ...state,
        stages: list.stages,
        recruits: list.recruits
      };
    case recruitConstants.ADD_NOTE_REQUEST:
      const existingNotes = state.notes || {};
      return {
        ...state,
        notes: existingNotes
      };
    case recruitConstants.ADD_NOTE_SUCCESS:
      const recruitId = action.data.recruitId;
      const { notes } = state;
      const notesForRecruit = notes[recruitId] || [];

      notesForRecruit.unshift(action.data);
      const updatedNotes = {
        ...notes,
        [recruitId]: notesForRecruit
      };
      return {
        ...state,
        notes: updatedNotes
      };
    case recruitConstants.UPDATE_STAGE_SUCCESS:
      return {
        ...state
      };
    case recruitConstants.ADD_RIDER_TO_RECRUIT_SUCCESS:
      return {
        ...state
      };
    case recruitConstants.UPDATE_RECRUIT_ORDER_REQUEST:
      const newStage = ((d, s) => {
        const { start, draggableId, source, destination } = d;
        const { stages } = s;
        const newRecIdsInStage = Array.from(start.recruitIdsInStage);
        // remove item at the given index
        newRecIdsInStage.splice(source.index, 1);
        // insert at new index
        newRecIdsInStage.splice(destination.index, 0, draggableId);
        // create a new version of the stage we're updating
        const newStage = {
          ...start,
          recruitIdsInStage: newRecIdsInStage
        };
        const something = {
          ...stages,
          [newStage.kebabName]: newStage
        };
        return something;
      })(action.data, state);

      return {
        ...state,
        stages: newStage
      };
    case recruitConstants.UPDATE_STAGE_REQUEST:
      const newStages = ((d, s) => {
        const { start, finish, draggableId, source, destination } = d;
        const { stages } = s;
        const newRecruitIdsInStage = Array.from(start.recruitIdsInStage);
        // remove dragged task id
        newRecruitIdsInStage.splice(source.index, 1);
        // create a new start column with the same props as old but new start tawsk ids array
        const newStart = {
          ...start,
          recruitIdsInStage: newRecruitIdsInStage
        };
        // new array for finish task ids from finish column array
        const finishTaskIds = Array.from(finish.recruitIdsInStage);
        // use splice to insert this item into it at correct index
        finishTaskIds.splice(destination.index, 0, draggableId);

        const newFinish = {
          ...finish,
          recruitIdsInStage: finishTaskIds
        };
        return {
          stages: {
            ...stages,
            [newStart.kebabName]: newStart,
            [newFinish.kebabName]: newFinish
          }
        };
      })(action.data, state);
      return {
        ...state,
        stages: newStages.stages
      };
    case recruitConstants.LIST_NOTES_REQUEST:
      return {
        ...state
      };
    case recruitConstants.LIST_NOTES_SUCCESS:
      const theNotes = state.notes;
      const anotherNewNotes = {
        ...theNotes,
        ...action.data
      };
      return {
        ...state,
        notes: anotherNewNotes
      };
    case recruitConstants.LIST_NOTES_FAILURE:
      return {
        ...state
      };
    case recruitConstants.UPDATE_RECRUIT_ORDER_SUCCESS:
    case recruitConstants.UPDATE_RECRUIT_ORDER_FAILURE:
      return {
        ...state
      };
    case recruitConstants.EMAIL_RECRUIT_REQUEST:
      return {
        ...state,
        sent: false,
        sending: true
      };
    case recruitConstants.EMAIL_RECRUIT_SUCCESS:
      return {
        ...state,
        sent: true,
        sending: false
      };
    case recruitConstants.EMAIL_RECRUIT_FAILURE:
      return {
        ...state,
        sent: false,
        sending: false
      };
    default:
      return state;
  }
}
