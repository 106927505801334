import { newsConstants } from '../_constants';
import { newsService } from '../_services';
import { history } from '../_helpers';
import { toastr } from 'react-redux-toastr';

export const newsActions = {
  createNews,
  addFeaturedImage,
  getNewsBySlug,
  listNews,
  deleteNews,
  createCategory,
  updateCategory,
  listCategories,
  updateNews,
  listForUser
};

function createNews(news, sendTo) {
  return dispatch => {
    dispatch(request(news));

    newsService.createNews(news).then(
      data => {
        dispatch(success(data));
        toastr.success('News created!');
        history.push(sendTo);
      },
      error => {
        dispatch(failure(error));
        toastr.error('Failed to create News');
      }
    );
  };
  function request(news) {
    return { type: newsConstants.CREATE_NEWS_REQUEST, news };
  }
  function success(data) {
    return { type: newsConstants.CREATE_NEWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.CREATE_NEWS_FAILURE, error };
  }
}

function addFeaturedImage(image) {
  return dispatch => {
    dispatch(request(image));

    newsService.addFeaturedImage(image).then(
      data => {
        dispatch(success(data));
        toastr.success('Image Uploaded!');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Image failed to upload.');
      }
    );
  };
  function request(image) {
    return { type: newsConstants.ADD_FEATURED_IMAGE_REQUEST, image };
  }
  function success(data) {
    return { type: newsConstants.ADD_FEATURED_IMAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.ADD_FEATURED_IMAGE_FAILURE, error };
  }
}

function getNewsBySlug(slug) {
  return dispatch => {
    dispatch(request(slug));

    newsService.getNewsBySlug(slug).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(slug) {
    return { type: newsConstants.NEWS_BY_SLUG_REQUEST, slug };
  }
  function success(data) {
    return { type: newsConstants.NEWS_BY_SLUG_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.NEWS_BY_SLUG_FAILURE, error };
  }
}

function deleteNews(newsId) {
  return dispatch => {
    dispatch(request(newsId));

    newsService.deleteNews(newsId).then(
      data => {
        dispatch(success(newsId));
        toastr.success('News successfully deleted.');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to delete News.');
      }
    );
  };
  function request(newsId) {
    return { type: newsConstants.DELETE_NEWS_REQUEST, newsId };
  }
  function success(newsId) {
    return { type: newsConstants.DELETE_NEWS_SUCCESS, newsId };
  }
  function failure(error) {
    return { type: newsConstants.DELETE_NEWS_FAILURE, error };
  }
}

function createCategory(name) {
  return dispatch => {
    dispatch(request(name));

    newsService.createCategory(name).then(
      data => {
        dispatch(success(data));
        toastr.success('Category created!');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to delete category.');
      }
    );
  };
  function request(name) {
    return { type: newsConstants.CREATE_CATEGORY_REQUEST, name };
  }
  function success(data) {
    return { type: newsConstants.CREATE_CATEGORY_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.CREATE_CATEGORY_FAILURE, error };
  }
}

function listNews() {
  return dispatch => {
    dispatch(request());

    newsService.listNews().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: newsConstants.LIST_NEWS_REQUEST, data };
  }
  function success(data) {
    return { type: newsConstants.LIST_NEWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.LIST_NEWS_FAILURE, error };
  }
}

function listCategories() {
  return dispatch => {
    dispatch(request());

    newsService.listCategories().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: newsConstants.LIST_CATEGORY_REQUEST, data };
  }
  function success(data) {
    return { type: newsConstants.LIST_CATEGORY_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.LIST_CATEGORY_FAILURE, error };
  }
}

function updateNews(data) {
  return dispatch => {
    dispatch(request(data));
    newsService.updateNews(data).then(
      data => {
        dispatch(success(data));
        toastr.success('News Updated!');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Failed to update news.');
      }
    );
  };
  function request(data) {
    return { type: newsConstants.UPDATE_NEWS_REQUEST, data };
  }
  function success(data) {
    return { type: newsConstants.UPDATE_NEWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.UPDATE_NEWS_FAILURE, error };
  }
}

function updateCategory(category) {
  return dispatch => {
    dispatch(request(category));

    newsService.updateCategory(category).then(
      data => {
        dispatch(success(data));
        toastr.success('Category updated.');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Category failed to update');
      }
    );
  };
  function request(data) {
    return { type: newsConstants.UPDATE_CATEGORY_REQUEST, data };
  }
  function success(data) {
    return { type: newsConstants.UPDATE_CATEGORY_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.UPDATE_CATEGORY_FAILURE, error };
  }
}

function listForUser() {
  return dispatch => {
    dispatch(request());
    newsService.listForUser().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: newsConstants.LIST_FOR_USER_REQUEST };
  }
  function success(data) {
    return { type: newsConstants.LIST_FOR_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: newsConstants.LIST_FOR_USER_FAILURE, error };
  }
}
