import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';

export const CategorySelect = props => {
  const { currentCategoryId, handleChange, categories = [] } = props;
  const optionItems = categories.map(category => (
    <option key={category.id} value={category.id}>
      {category.name}
    </option>
  ));
  return (
    <FormGroup>
      <Input
        name="categoryId"
        id="categoryId"
        type="select"
        className="custom-select"
        onChange={handleChange}
        defaultValue={currentCategoryId}
      >
        {currentCategoryId !== 'undefined' && <option>Category...</option>}
        {optionItems}
      </Input>
    </FormGroup>
  );
};
CategorySelect.propTypes = {
  categories: PropTypes.array.isRequired,
  currentCategoryId: PropTypes.number,
  handleChange: PropTypes.func.isRequired
};
