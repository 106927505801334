import { connectionsConstants } from '../_constants';

export function connections(state = {}, action) {
  switch (action.type) {
    case connectionsConstants.FOLLOW_REQUEST:
    case connectionsConstants.UNFOLLOW_REQUEST:
      const removableId = action.data;
      const origFollowing = { ...state.following };
      delete origFollowing[removableId];
      return {
        ...state,
        following: origFollowing
      };
    case connectionsConstants.FOLLOW_FAILURE:
    case connectionsConstants.UNFOLLOW_FAILURE:
      return {
        ...state
      };
    case connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_SUCCESS:
    case connectionsConstants.FOLLOW_SUCCESS:
      const newFollower = action.data;
      const newList = {
        ...state.following,
        [newFollower.id]: newFollower
      };
      return {
        ...state,
        following: newList
      };
    case connectionsConstants.UNFOLLOW_SUCCESS:
      return {
        ...state
      };
    case connectionsConstants.CONNECTIONS_CREATE_REQUEST:
      return {
        ...state,
        buttonDisable: true
      };
    case connectionsConstants.CONNECTIONS_CREATE_SUCCESS:
      const newPending = action.data;
      const newPendingList = {
        ...state.pending,
        [newPending.id]: newPending
      };
      return {
        ...state,
        pending: newPendingList,
        buttonDisable: false
      };
    case connectionsConstants.CONNECTIONS_CREATE_FAILURE:
      return {
        ...state,
        buttonDisable: false
      };
    case connectionsConstants.CONNECTIONS_CANCEL_REQUEST:
      const toUserId = action.data;
      const pending = { ...state.pending };
      delete pending[toUserId];
      return {
        ...state,
        pending: pending
      };
    case connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_REQUEST:
    case connectionsConstants.CONNECTIONS_DECLINE_REQUEST:
    case connectionsConstants.CONNECTIONS_APPROVE_REQUEST:
      const requests = { ...state.requests };
      delete requests[action.data];
      return {
        ...state,
        requests: requests
      };
    case connectionsConstants.CONNECTIONS_APPROVE_SUCCESS:
      const newApproved = action.data;
      const updatedApproved = {
        ...state.approved,
        [newApproved.id]: newApproved
      };
      return {
        ...state,
        approved: updatedApproved
      };
    case connectionsConstants.CONNECTIONS_CANCEL_SUCCESS:
    case connectionsConstants.CONNECTIONS_CANCEL_FAILURE:
    case connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_FAILURE:
    case connectionsConstants.CONNECTIONS_APPROVE_FAILURE:
    case connectionsConstants.CONNECTIONS_DECLINE_SUCCESS:
    case connectionsConstants.CONNECTIONS_DECLINE_FAILURE:
    case connectionsConstants.REMOVE_CONNECTION_FAILURE:
    case connectionsConstants.CONNECTIONS_LIST_FAILURE:
      return {
        ...state,
        removing: false,
        removed: false
      };
    case connectionsConstants.REMOVE_CONNECTION_REQUEST:
      return {
        ...state,
        removed: false,
        removing: true
      };
    case connectionsConstants.REMOVE_CONNECTION_SUCCESS:
      const key = state.toRemove.id;
      const { [key]: value, ...withoutSecond } = state.approved;
      return {
        ...state,
        removed: true,
        removing: false,
        approved: withoutSecond
      };
    case connectionsConstants.CONNECTIONS_LIST_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    case connectionsConstants.SET_REMOVING_CONNECTION:
      const toRemove = state.approved[action.userId];
      return {
        ...state,
        toRemove
      };
    case connectionsConstants.CONNECTIONS_LIST_REQUEST:
      return {
        ...state
      };
    default:
      return state;
  }
}
