import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions, authenticationActions as authActions } from '../_actions';
import { Container, Row, Col, Button, Form } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Error } from './Error';
import { FormLabel } from '../Shared';
import { TextInput, PasswordInput, EmailInput, EmailOptIn } from '../HookInputs';
import { useRegistration } from '../_hooks';

export function TeamRegister() {
  const dispatch = useDispatch();
  const accountType = 'Team Manager';
  const {
    firstName,
    lastName,
    newsletterOptIn,
    email,
    emailValid,
    password,
    passwordValid,
    setFirstName,
    setLastName,
    setNewsletterOptIn,
    setEmail,
    validateEmail,
    setPassword,
    validatePassword
  } = useRegistration();

  useEffect(() => {
    dispatch(authActions.logoutNoRedirect());
  }, [dispatch]);

  const { success, failure, registering, error } = useSelector(state => state.register);

  const handleSubmit = e => {
    e.preventDefault();
    if (email && password && !registering) {
      const data = { email, password, accountType, firstName, lastName, newsletterOptIn };
      dispatch(accountActions.register(data));
    }
  };
  return (
    <Container className="account-container registration team" fluid>
      <Helmet title="Create Your Account - Cycling Connect">
        <meta
          name="description"
          content="Create your Team profile and begin scouping for your next Rider"
        />
      </Helmet>
      {!success ? (
        <Row style={{ width: `100%` }}>
          <Col xs={12} md={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel team text="Register" />
            <Form onSubmit={handleSubmit}>
              <TextInput
                id="firstName"
                label="First Name"
                name="firstName"
                className="mt-5"
                value={firstName}
                placeholder="First Name"
                required
                handleChange={setFirstName}
              />
              <TextInput
                id="lastName"
                label="Last Name"
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                required
                handleChange={setLastName}
              />
              <EmailInput
                valid={emailValid}
                handleChange={setEmail}
                value={email}
                validate={validateEmail}
              />
              <PasswordInput
                valid={passwordValid}
                handleChange={setPassword}
                value={password}
                validate={validatePassword}
              />
              <EmailOptIn
                className="border-team br-2"
                checked={newsletterOptIn}
                handleChange={setNewsletterOptIn}
              />
              {failure && <Error className="border-team br-2" error={error} />}
              <Row>
                <Col xs={12} sm={4}>
                  <Button color="team">Register</Button>
                </Col>
                <Col xs={12} sm={8} className="d-flex justify-content-end">
                  <Button tag={Link} to="/forgotpassword">
                    Reset Password
                  </Button>{' '}
                  <Button color="info" tag={Link} to="/login">
                    Already Registered?
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel team text="Thanks for Registering!" />
            <p className="message">
              Thanks for creating an account, please check your email to confirm your account. Once
              confirmed, you can log in and create your profile.
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
}
