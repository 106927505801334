import { refereeTypesConstants } from '../_constants';

export function refereeTypes(state = {}, action) {
  switch (action.type) {
    case refereeTypesConstants.REFEREE_TYPES_GET_REQUEST:
    case refereeTypesConstants.REFEREE_TYPES_GET_FAILURE:
      return {
        ...state
      };
    case refereeTypesConstants.REFEREE_TYPES_GET_SUCCESS:
      return {
        types: action.data.refereeTypes
      };
    default:
      return state;
  }
}
