import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { OpenTeam } from './';
import { PlaceholderCard, FormLabel, LoadingCard } from '../../Shared';
import { dashboardActions } from '../../_actions';
import isEmpty from 'lodash/isEmpty';

function OpenTeams(props) {
  const { teamOpenings, openings, connections, openingsLoading } = props;
  // fetch team openings on mount, and refresh every 45 seconds.
  // remembering to clear the interval when unmounting.
  useEffect(() => {
    teamOpenings();
    const interval = setInterval(teamOpenings, 45000);
    return () => clearInterval(interval);
  }, [teamOpenings]);

  return (
    <Container className="team-openings grey-bg pb-3">
      <Row>
        <Col xs={12}>
          <FormLabel rider text="Team Openings" />
        </Col>
        <Col className="open-team-wrapper mt-4">
          {openingsLoading && <LoadingCard />}
          {isEmpty(openings) && !openingsLoading && (
            <PlaceholderCard message="No Team Openings to show." />
          )}
          {openings &&
            connections &&
            openings.map((team, index) => <OpenTeam key={index} team={team} />)}
        </Col>
      </Row>
    </Container>
  );
}
//

const mapDispatch = {
  teamOpenings: dashboardActions.teamOpenings
};
function mapStateToProps(state) {
  const { openings, openingsLoading } = state.dashboard;
  const { connections } = state;
  return { openings, connections, openingsLoading };
}

const connectedOpenTeams = connect(
  mapStateToProps,
  mapDispatch
)(OpenTeams);
export { connectedOpenTeams as OpenTeams };
