import { authFetch, headers } from '../_helpers';

export const teamRosterService = {
  createRoster,
  updateRoster,
  deleteRoster,
  listRoster,
  listRosterForUser,
  riderLookup
};

async function createRoster(data) {
  const response = await authFetch(`/api/TeamRoster/Create`, headers.post(data));
  return response;
}

async function updateRoster(data) {
  const response = await authFetch(`/api/TeamRoster/Update`, headers.post(data));
  return response;
}

async function deleteRoster(rosterId) {
  const response = await authFetch(`/api/TeamRoster/Delete`, headers.postPrimitive(rosterId));
  return response;
}

async function listRoster(teamId) {
  const response = await authFetch(`/api/TeamRoster/List`, headers.postPrimitive(teamId));
  return response;
}

async function listRosterForUser() {
  const response = await authFetch(`/api/TeamRoster/ListForUser`, headers.post());
  return response;
}

async function riderLookup(terms) {
  const response = await authFetch(`/api/TeamRoster/RiderLookup/`, headers.postPrimitive(terms));
  return response;
}
