import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';

import {
  Biography,
  CareerHighlights,
  CareerHistory,
  ProfileHeader,
  RidingDetails,
  Social,
  PowerData,
  Referees
} from './';
import { colours } from '../_helpers';
import { riderProfileActions } from '../_actions';
import { ConnectBanner } from '../Connections/';
import { KingOfMountainLoader } from '../Loaders';
import isEmpty from 'lodash/isEmpty';
import { Subheader } from './Subheader';

function RiderProfile(props) {
  const profileSlug = props.match.params.profileSlug;
  const { list, user, loading, getRiderProfile } = props;
  let rider;

  if (!isEmpty(list)) {
    rider = list[profileSlug];
  }

  useEffect(() => {
    getRiderProfile(profileSlug);
  }, [getRiderProfile, profileSlug]);

  return (
    <div className="rider-profile">
      {loading && (
        <Col>
          <KingOfMountainLoader />
        </Col>
      )}
      <Helmet title={`${rider ? rider.displayName : 'Rider Profile'} - Cycling Connect`} />
      {!isEmpty(rider) && rider.riderProfile && <ProfileHeader userDetails={rider} user={user} />}
      <Container
        style={{
          height: `65px`,
          background: `#fff`,
          marginTop: `-35px`,
          borderTop: `4px solid ${colours.riderTheme.primary}`,
          boxShadow: `0px -4px 7px 0px rgba(255, 255, 255, 0.75)`
        }}
      >
        {rider && (
          <Subheader
            formattedDateOfBirth={rider.formattedDateOfBirth}
            profile={rider.riderProfile}
          />
        )}
      </Container>

      {rider && rider.riderProfile && (
        <Fragment>
          <Container className="profile-body pt-5">
            <Row>
              <RidingDetails riderProfile={rider.riderProfile} userDetails={rider} />
              <Biography profile={rider.riderProfile} />
            </Row>
            <Row>
              <CareerHighlights careerHighlights={rider.riderProfile.careerHighlights} />
              <CareerHistory riderProfile={rider.riderProfile} />

              {/** show that a team can connect to see more */}
              <ConnectBanner userId={rider.riderProfile.riderId} />
              {!isEmpty(rider.riderProfile.powerData) && (
                <PowerData power={rider.riderProfile.powerData} />
              )}
            </Row>
            <Row>
              {!isEmpty(rider.riderProfile.referees) && (
                <Referees referees={rider.riderProfile.referees} />
              )}
            </Row>
            <Row>
              {rider.riderProfile.hasSocialLinks && <Social riderProfile={rider.riderProfile} />}
            </Row>
          </Container>
        </Fragment>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const {
    riderProfiles: { list = [] }
  } = state;
  const { user } = state.authentication;
  return {
    list,
    user
  };
}

const mapDispatch = {
  getRiderProfile: riderProfileActions.getRiderProfile
};

const connectedRiderProfile = connect(
  mapStateToProps,
  mapDispatch
)(RiderProfile);
export { connectedRiderProfile as RiderProfile };
