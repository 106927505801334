import { handleResponse } from '../_helpers';

export const riderStylesService = {
  getriderStyles
};

async function getriderStyles() {
  const response = await fetch(`/api/RiderStyles/`);
  const riderStyles = await handleResponse(response);
  return riderStyles;
}
