import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavbarToggler } from 'reactstrap';

export function NavToggle({ toggle, isOpen }) {
  return (
    <NavbarToggler onClick={toggle} className={classnames({ open: isOpen })}>
      <span className="icon-bar" />
      <span className="icon-bar" />
      <span className="icon-bar" />
    </NavbarToggler>
  );
}

NavToggle.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
