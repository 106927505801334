import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ForRiders = () => (
  <Container className="info" id="riders">
    <Row>
      <Col xs={12}>
        <span className="label rider">For Riders</span>
        <p className="lead mt-5">
          Cycling Connect is the best place to find your new team. The Cycling Connect platform
          evens the playing field for all riders when it comes to securing a contract.
        </p>
        <ul className="lead">
          <li>Search for teams based on location, language and more</li>
          <li>Apply for open positions</li>
          <li>Contact team directors</li>
          <li>Build your profile and palmarès to attract new teams</li>
        </ul>
      </Col>
      <Col xs={12}>
        <Button color="rider" size="lg" tag={Link} to="/register/rider">
          <FontAwesomeIcon icon="bicycle" /> Rider Sign Up
        </Button>
      </Col>
    </Row>
  </Container>
);
