import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  ConnectionRequests,
  SuggestedConnections,
  RecruitmentOverview,
  AddNotice,
  MyNotices
} from './Team';
import { MyConnections, LatestNews, Notifications } from './';

export const TeamDashboard = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={4} lg={3}>
          <Notifications team />
          <ConnectionRequests />
        </Col>
        <Col>
          <SuggestedConnections />
          <Row>
            <Col sm={6} md={6}>
              <RecruitmentOverview />
            </Col>
            <Col sm={6} md={6}>
              <MyConnections team />
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <AddNotice />
          <MyNotices />
          <LatestNews team />
        </Col>
      </Row>
    </Container>
  );
};
