import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, CardFooter } from 'reactstrap';
import { RiderRosterCard } from '.';
import { FormLabel } from '../Shared';
import isEmpty from 'lodash/isEmpty';

export const Roster = props => {
  const { roster } = props;
  if (isEmpty(roster)) {
    return null;
  }
  const richRider = roster.filter(r => !isEmpty(r.rider));
  const basicRoster = roster.filter(r => isEmpty(r.rider));

  return (
    <Col xs={12}>
      <Col className="team-data mb-5  shadow">
        <FormLabel team text="Team Roster" />
        <Row>
          <Col className="mt-4">
            <Row>
              {richRider.map((r, index) => (
                <RiderRosterCard rider={r.rider} key={index} />
              ))}
            </Row>
            <Row>
              {basicRoster.map((r, index) => (
                <Col key={index} sm={3} md={2} className="text-center d-flex mb-4">
                  <CardFooter
                    style={{
                      color: `#fff`,
                      background: `#404040`,
                      flexGrow: 1,
                      paddingBottom: `10px`
                    }}
                  >
                    {r.riderName}
                  </CardFooter>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

Roster.propTypes = {
  roster: PropTypes.array
};
