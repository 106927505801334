import { teamSearchConstants } from '../_constants';
import { teamSearchService } from '../_services';

export const teamSearchActions = {
  search
};

function search(terms) {
  return dispatch => {
    dispatch(request());

    teamSearchService.search(terms).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: teamSearchConstants.TEAM_SEARCH_REQUEST, data };
  }
  function success(data) {
    return { type: teamSearchConstants.TEAM_SEARCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamSearchConstants.TEAM_SEARCH_FAILURE, error };
  }
}
