import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

export const Social = props => {
  const { profile } = props;
  if (!profile.hasSocialLinks) {
    return null;
  }
  return (
    <Col>
      <Col xs={12} className="team-data pb-4 team-social mt-5 mb-5">
        <FormLabel team text="Social" />
        <Row className="social">
          {profile.facebookUrl && (
            <Col className="text-center">
              <a href={profile.facebookUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'facebook']} />
              </a>
            </Col>
          )}
          {profile.twitterUrl && (
            <Col className="text-center">
              <a href={profile.twitterUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'twitter']} />
              </a>
            </Col>
          )}
          {profile.youTubeUrl && (
            <Col className="text-center">
              <a href={profile.youTubeUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'youtube']} />
              </a>
            </Col>
          )}
          {profile.instagramUrl && (
            <Col className="text-center">
              <a href={profile.instagramUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'instagram']} />
              </a>
            </Col>
          )}
          {profile.websiteUrl && (
            <Col className="text-center">
              <a href={profile.websiteUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon="globe" />
              </a>
            </Col>
          )}
        </Row>
      </Col>
    </Col>
  );
};

Social.propTypes = {
  profile: PropTypes.object.isRequired
};
