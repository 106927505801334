import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { SectionHeader } from '../../Shared';

function Follows(props) {
  const { following } = props;
  return (
    <Container className="follows grey-bg">
      <SectionHeader titleText="Follows" />
      {!following && <p>Loading...</p>}
    </Container>
  );
}

function mapStateToProps(state) {
  const { following } = state.connections;
  return { following };
}

const connectedFollows = connect(mapStateToProps)(Follows);
export { connectedFollows as Follows };
