import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export function TextInput({ label, value, id, name, handleChange, required, placeholder, className = '' }) {
  return (
    <FormGroup>
      <Label className="sr-only" for="lastName">
        {label}
      </Label>
      <Input
        name={name}
        id={id}
        className={className}
        required={required}
        onChange={e => handleChange(e.target.value)}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
}
TextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
};
