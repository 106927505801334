import { headers, authFetch } from '../_helpers';

export const riderProfileService = {
  getProfile,
  getRiderProfile,
  updateProfile,
  uploadProfilePhoto,
  uploadBannerPhoto,
  createUpdateCareerHistory,
  deleteCareerHistory,
  createUpdateCareerHighlight,
  deleteCareerHighlight
};

async function getProfile() {
  const response = await authFetch(`/api/RiderProfile/Index`, headers.get());
  return response;
}

async function createUpdateCareerHighlight(careerHighlight) {
  const response = await authFetch(
    `/api/RiderProfile/CreateUpdateCareerHighlight`,
    headers.post(careerHighlight)
  );
  return response;
}

async function createUpdateCareerHistory(careerHistory) {
  const response = await authFetch(
    `/api/RiderProfile/CreateUpdateCareerHistory`,
    headers.post(careerHistory)
  );
  return response;
}

async function deleteCareerHistory(historyId) {
  const response = await authFetch(
    `/api/RiderProfile/DeleteCareerHistory`,
    headers.postPrimitive(historyId)
  );
  return response;
}

async function deleteCareerHighlight(highlightId) {
  const response = await authFetch(
    `/api/RiderProfile/DeleteCareerHighlight`,
    headers.postPrimitive(highlightId)
  );
  return response;
}

async function getRiderProfile(profileSlug) {
  const response = await authFetch(`/api/RiderProfile/Profile`, headers.postPrimitive(profileSlug));
  return response;
}

async function updateProfile(data) {
  const response = await authFetch(`/api/RiderProfile/UpdateProfile`, headers.post(data));
  return response;
}

async function uploadProfilePhoto(file) {
  const response = await authFetch(`/api/RiderProfile/UpdateProfilePhoto`, headers.postFile(file));
  return response;
}

async function uploadBannerPhoto(file) {
  const response = await authFetch(`/api/RiderProfile/UpdateBannerPhoto`, headers.postFile(file));
  return response;
}
