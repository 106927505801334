import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Label, Input, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class ImageEditor extends React.Component {
  state = {
    rotate: 0,
    scale: 1.0,
    image: null
  };

  handleSave = () => {
    if (this.editor) {
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      const formData = new FormData();
      formData.append('file', img);
      this.props.upload(formData);
    }
  };

  setEditorRef = editor => (this.editor = editor);

  selectImage = e => {
    e.preventDefault();
    this.setState({ image: e.target.files[0] });
  };

  zoomIn = () => {
    const maxScale = 2;
    let { scale } = this.state;
    scale += 0.1;
    if (scale > maxScale) {
      scale = maxScale;
    }
    this.setState({ scale });
  };

  zoomOut = () => {
    const minScale = 0.5;
    let { scale } = this.state;
    scale -= 0.1;
    if (scale < minScale) {
      scale = minScale;
    }
    this.setState({ scale });
  };

  rotateLeft = e => {
    e.preventDefault();
    this.setState({ rotate: this.state.rotate - 90 });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({ rotate: this.state.rotate + 90 });
  };

  render() {
    const { btnColor } = this.props;
    return (
      <div>
        <h5>Upload Profile Photo</h5>
        <AvatarEditor
          ref={this.setEditorRef}
          image={this.state.image}
          width={300}
          height={300}
          border={3}
          scale={this.state.scale}
          rotate={this.state.rotate}
        />
        <div>
          <ButtonGroup size="lg">
            <Input
              id="profilePhoto"
              name="profilePhoto"
              type="file"
              multiple={false}
              accept="image/*"
              className="d-none"
              onChange={this.selectImage}
            />
            <Label className="btn btn-success my-0 mr-1" for="profilePhoto">
              <FontAwesomeIcon icon="plus" />
            </Label>

            <Button color={btnColor} onClick={this.rotateLeft}>
              <FontAwesomeIcon icon="undo" />
            </Button>
            <Button color={btnColor} onClick={this.rotateRight}>
              <FontAwesomeIcon icon="undo" style={{ transform: `rotateY(180deg)` }} />
            </Button>
            <Button color={btnColor} onClick={this.zoomIn}>
              <FontAwesomeIcon icon="search-plus" />
            </Button>
            <Button color={btnColor} onClick={this.zoomOut}>
              <FontAwesomeIcon icon="search-minus" />
            </Button>
            <Button
              color="success"
              className="ml-1"
              onClick={this.handleSave}
              disabled={!this.state.image}
            >
              <FontAwesomeIcon icon={['far', 'save']} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}
