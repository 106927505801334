import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from 'reactstrap';

export function CardFooter(props) {
  const { user, id, notesToggle, contactToggle } = props;
  return (
    <div>
      <ButtonGroup className="btn-block">
        <Button className="col" to={`/rider/${user.profileSlug}`} tag={Link} color="primary">
          Profile
        </Button>
        <Button
          className="col"
          color="warning"
          value={id}
          onClick={() => contactToggle(id, user.displayName)}
        >
          Contact
        </Button>
        <Button
          className="col"
          color="success"
          value={id}
          onClick={() => notesToggle(id, user.displayName)}
        >
          Notes
        </Button>
      </ButtonGroup>
    </div>
  );
}

CardFooter.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};
