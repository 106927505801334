import { myProfileConstants } from '../_constants';

export function myProfile(state = {}, action) {
  switch (action.type) {
    case myProfileConstants.PROFILE_UPDATE_REQUEST:
    case myProfileConstants.PROFILE_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        uploading: false
      };
    case myProfileConstants.PROFILE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data
      };
    case myProfileConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.data
      };
    case myProfileConstants.PROFILE_UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        user: action.data,
        uploading: false
      };
    case myProfileConstants.PROFILE_VIEW_FAILURE:
    case myProfileConstants.PROFILE_UPDATE_FAILURE:
    case myProfileConstants.PROFILE_UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploading: true
      };
    case myProfileConstants.PROFILE_UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: true,
        uploading: false
      };

    default:
      return state;
  }
}
