export const recruitConstants = {
  ADD_RIDER_TO_RECRUIT_REQUEST: 'ADD_RIDER_TO_RECRUIT_REQUEST',
  ADD_RIDER_TO_RECRUIT_SUCCESS: 'ADD_RIDER_TO_RECRUIT_SUCCESS',
  ADD_RIDER_TO_RECRUIT_FAILURE: 'ADD_RIDER_TO_RECRUIT_FAILURE',
  LIST_RECRUITS_REQUEST: 'LIST_RECRUITS_REQUEST',
  LIST_RECRUITS_SUCCESS: 'LIST_RECRUITS_SUCCESS',
  LIST_RECRUITS_FAILURE: 'LIST_RECRUITS_FAILURE',
  ADD_NOTE_REQUEST: 'ADD_NOTE_REQUEST',
  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILURE: 'ADD_NOTE_FAILURE',
  LIST_NOTES_REQUEST: 'LIST_NOTES_REQUEST',
  LIST_NOTES_SUCCESS: 'LIST_NOTES_SUCCESS',
  LIST_NOTES_FAILURE: 'LIST_NOTES_FAILURE',
  UPDATE_STAGE_REQUEST: 'UPDATE_STAGE_REQUEST',
  UPDATE_STAGE_SUCCESS: 'UPDATE_STAGE_SUCCESS',
  UPDATE_STAGE_FAILURE: 'UPDATE_STAGE_FAILURE',
  UPDATE_RECRUIT_ORDER_REQUEST: 'UPDATE_RECRUIT_ORDER_REQUEST',
  UPDATE_RECRUIT_ORDER_SUCCESS: 'UPDATE_RECRUIT_ORDER_SUCCESS',
  UPDATE_RECRUIT_ORDER_FAILURE: 'UPDATE_RECRUIT_ORDER_FAILURE',
  EMAIL_RECRUIT_REQUEST: 'EMAIL_RECRUIT_REQUEST',
  EMAIL_RECRUIT_SUCCESS: 'EMAIL_RECRUIT_SUCCESS',
  EMAIL_RECRUIT_FAILURE: 'EMAIL_RECRUIT_FAILURE'
};
