import React from 'react';
import { Container, Row, Col, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { adminActions } from '../_actions';

class CurrentAdmins extends React.Component {
  componentDidMount = () => {
    this.props.listAdmins();
  };

  revokeAdmin = e => {
    const { value } = e.target;
    this.props.revokeAdmin(value);
  };

  render() {
    const { admins } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <Table hover striped bordered size="sm">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Member Since</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={index}>
                    <td className="align-middle">{admin.firstName}</td>
                    <td className="align-middle">{admin.lastName}</td>
                    <td className="align-middle">{admin.email}</td>
                    <td className="align-middle">{admin.signUpDate}</td>
                    <td>
                      <Button color="warning" value={admin.id} onClick={this.revokeAdmin}>
                        Revoke
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapDispatch = {
  listAdmins: adminActions.listAdmins,
  revokeAdmin: adminActions.revokeAdmin
};
function mapStateToProps(state) {
  const { admins = [] } = state.admin;
  return { admins };
}

const connectedCurrentAdmins = connect(
  mapStateToProps,
  mapDispatch
)(CurrentAdmins);
export { connectedCurrentAdmins as CurrentAdmins };
