import React from 'react';
import { FormLabel } from '../Shared';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

export const TeamMemberEdit = props => {
  const { profile, handleChange } = props;
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team text="Team Members" />
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="teamManager">Team Manager</Label>
            <Input
              name="teamManager"
              id="teamManager"
              type="text"
              defaultValue={profile.teamManager || ''}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="directorSportif">Directeur Sportif</Label>
            <Input
              name="directorSportif"
              id="directorSportif"
              defaultValue={profile.directorSportif}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="teamMechanic">Team Mechanic</Label>
            <Input
              name="teamMechanic"
              id="teamMechanic"
              defaultValue={profile.teamMechanic}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="soignuer">Soignuer</Label>
            <Input
              id="soignuer"
              name="soignuer"
              defaultValue={profile.soignuer}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="team" onClick={props.handleSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

TeamMemberEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};
