import { teamSearchConstants } from '../_constants';

export function teamSearch(state = {}, action) {
  switch (action.type) {
    case teamSearchConstants.TEAM_SEARCH_REQUEST:
      return {
        loading: true,
        success: false,
        results: []
      };
    case teamSearchConstants.TEAM_SEARCH_SUCCESS:
      return {
        loading: false,
        success: true,
        ...action.data
      };
    case teamSearchConstants.TEAM_SEARCH_FAILURE:
      return {
        loading: false,
        success: false,
        results: []
      };
    default:
      return state;
  }
}
