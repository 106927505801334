import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { DragDropContext } from 'react-beautiful-dnd';
import { RecruitManagerHeader, RiderColumn, NotesModal, ContactModal } from './';
import { Container, Row, Col } from 'reactstrap';
import { recruitActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';

class RecruitManager extends React.Component {
  state = { contactModal: false, notesModal: false };

  contactToggle = (recruitId, contactName) => {
    this.setState({
      contactModal: !this.state.contactModal,
      recruitId,
      contactName
    });
  };

  notesToggle = (recruitId, recruitName) => {
    if (!this.state.notesModal) {
      this.props.listNotesForRecruit(recruitId);
    }

    this.setState({ notesModal: !this.state.notesModal, recruitId, recruitName });
  };

  onDragEnd = result => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const { stages } = this.props;
    const start = stages[source.droppableId];
    const finish = stages[destination.droppableId];
    // moving item within original list.
    if (start === finish) {
      this.props.updateOrder({ start, draggableId, source, destination });
    } else {
      this.props.updateStage({ start, finish, draggableId, source, destination });
    }
  };

  componentDidMount = () => {
    this.props.list();
  };

  render() {
    const { stages = {}, recruits = {}, stageOrder } = this.props;
    return (
      <div className="recruit-manager">
        <Helmet title="Recruit Manager - Cycling Connect" />
        <RecruitManagerHeader />
        {isEmpty(recruits) && (
          <Container className="my-4">
            <Row>
              <Col>Connect with Riders to start utilising the Recruitment Manager</Col>
            </Row>
          </Container>
        )}

        <Container className="recruit-columns" fluid>
          <Row>
            <DragDropContext onDragEnd={this.onDragEnd}>
              {stageOrder.map(stageId => {
                const stage = stages[stageId];
                const recruitList = stage.recruitIdsInStage.map(riderId => recruits[riderId]);
                return (
                  <RiderColumn
                    key={stageId}
                    stage={stage}
                    riders={recruitList}
                    contactToggle={this.contactToggle}
                    notesToggle={this.notesToggle}
                  />
                );
              })}
            </DragDropContext>
          </Row>
        </Container>
        <ContactModal
          isOpen={this.state.contactModal}
          toggle={this.contactToggle}
          recruitId={this.state.recruitId}
          contactName={this.state.contactName}
        />
        <NotesModal
          toggle={this.notesToggle}
          isOpen={this.state.notesModal}
          recruitId={this.state.recruitId}
          recruitName={this.state.recruitName}
        />
      </div>
    );
  }
}
const mapDispatch = {
  listNotesForRecruit: recruitActions.listNotesForRecruit,
  updateOrder: recruitActions.updateOrder,
  updateStage: recruitActions.updateStage,
  list: recruitActions.list
};
function mapStateToProps(state) {
  const {
    recruit: { stages = {}, recruits }
  } = state;
  const stageOrder = Object.keys(stages) || [];
  return { stages, recruits, stageOrder };
}
const connectedRecruitManager = connect(
  mapStateToProps,
  mapDispatch
)(RecruitManager);
export { connectedRecruitManager as RecruitManager };
