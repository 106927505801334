import { headers, authFetch } from '../_helpers';

export const announcementService = {
  create,
  remove,
  listAll,
  listForUser,
  hide
};

async function create(ann) {
  const response = await authFetch(`/api/Announcements/Create`, headers.post(ann));
  return response;
}

async function remove(announcementId) {
  const response = await authFetch(
    `/api/Announcements/Remove`,
    headers.postPrimitive(announcementId)
  );
  return response;
}

async function listAll() {
  const response = await authFetch(`/api/Announcements/ListAll`, headers.get());
  return response;
}

async function listForUser() {
  const response = await authFetch(`/api/Announcements/ListForUser`, headers.get());
  return response;
}

async function hide(announcementId) {
  const response = await authFetch(
    `/api/Announcements/Hide`,
    headers.postPrimitive(announcementId)
  );
  return response;
}
