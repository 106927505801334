import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { List } from './RacingLevels/List';
import { Create } from './RacingLevels/Create';
import { Delete } from './RacingLevels/Delete';
import { AdminHeader } from './';
import { racingLevelsActions } from '../_actions';

class RacingLevels extends React.Component {
  componentDidMount = () => {
    this.props.listLevels();
  };

  render() {
    const { levels } = this.props;
    return (
      <Fragment>
        <Helmet title="Manage Racing Levels - Cycling Connect" />
        <AdminHeader title="Manage Racing Levels" />
        <Container>
          <Row>
            <Col>
              <List levels={levels} />
            </Col>
            <Col>
              <Row>
                <Col>
                  <Create />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Delete />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  listLevels: racingLevelsActions.list
};
function mapStateToProps(state) {
  const { levels = [] } = state.racingLevels;
  return { levels };
}

const connectedRacingLevels = connect(
  mapStateToProps,
  mapDispatch
)(RacingLevels);
export { connectedRacingLevels as RacingLevels };
