export const wsBase = () => '/hubs';

export const headers = {
  get,
  post,
  postPrimitive
};

function authToken() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return user.token;
  }
}

function refreshToken() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.refreshToken) {
    return user.refreshToken;
  }
}

function getTokens() {
  return {
    token: authToken(),
    refreshToken: refreshToken()
  };
}

function setTokens(data) {
  localStorage.setItem('user', JSON.stringify(data));
}

async function refresh() {
  const tokens = getTokens();
  return await fetch(`/api/Auth/Refresh`, post(tokens));
}

export async function authFetch(url, options = {}) {
  const token = authToken();
  options.headers = options.headers || {};
  options.headers['Authorization'] = `Bearer ${token}`;
  const response = await fetch(url, options);
  if (response.ok) {
    return await handleResponse(response);
  }
  if (response.status === 401 && response.headers.has('Token-Expired')) {
    const refreshResponse = await refresh();
    if (!refreshResponse.ok) {
      return await handleResponse(response); //failed to refresh so return original 401 response
    }
    const jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens
    setTokens(jsonRefreshResponse);
    return await authFetch(url, options); //repeat the original request
  } else {
    //status is not 401 and/or there's no Token-Expired header
    return await handleResponse(response); //return the original 401 response
  }
}

export function handleResponse(response) {
  return new Promise((resolve, reject) => {
    const contentType = response.headers.get('content-type');
    if (response.ok) {
      // return json if it was returned in the response
      if (contentType && contentType.includes('application/json')) {
        response.json().then(json => resolve(json));
      } else if (contentType && contentType.includes('text/plain')) {
        response.text().then(text => resolve(text));
      } else {
        resolve();
      }
    } else {
      if (contentType && contentType.includes('application/json')) {
        response.json().then(json => reject(json));
      } else if (contentType && contentType.includes('text/plain')) {
        response.text().then(text => reject(text));
      } else {
        reject();
      }
    }
  });
}

function get() {
  return {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };
}

function post(data) {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data })
  };
}

function postPrimitive(data) {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
}
