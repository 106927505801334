import React from 'react';

export const MailChimpSignup = () => {
  return (
    <div>
      <div id="mc_embed_signup">
        <form
          action="https://cyclingconnect.us19.list-manage.com/subscribe/post?u=2d3ee1f8b1a5b219d32b09cb6&amp;id=ca735d07aa"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate form-inline"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="email form-control"
              id="mce-EMAIL"
              placeholder="Email Address"
              required
            />

            <div style={{ position: `absolute`, left: `-5000px` }} aria-hidden="true">
              <input
                type="text"
                name="b_2d3ee1f8b1a5b219d32b09cb6_ca735d07aa"
                tabIndex="-1"
                value=""
                readOnly
              />
            </div>
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button btn btn-info"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
