import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { racingLevelsActions } from '../../_actions';

const defaultState = { racingLevelName: '' };

class Create extends React.Component {
  state = { ...defaultState };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createLevel = e => {
    e.preventDefault();
    const { racingLevelName } = this.state;
    if (racingLevelName.length) {
      this.props.create({ racingLevelName });
    }
  };

  render() {
    return (
      <div>
        <h4>Create</h4>
        <Form onSubmit={this.createLevel}>
          <InputGroup>
            <Input name="racingLevelName" onChange={this.handleChange} />
            <InputGroupAddon addonType="append">
              <Button color="success">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>
    );
  }
}

const mapDispatch = {
  create: racingLevelsActions.create
};

function mapStateToProps(state) {
  const { racingLevels } = state;
  return { racingLevels };
}

const connectedCreate = connect(
  mapStateToProps,
  mapDispatch
)(Create);
export { connectedCreate as Create };
