import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { RacingLevelSelect } from '../../Inputs';
import { racingLevelsActions } from '../../_actions';
import isEmpty from 'lodash/isEmpty';
class Delete extends React.Component {
  state = {};

  handleChange = e => {
    const { value } = e.target;
    this.setState({ toDeleteId: value });
    if (value) {
      this.props.getUse(value);
    }
  };

  handleMoveToChange = e => {
    const { value } = e.target;
    this.setState({ moveToId: value });
  };

  handleDelete = () => {
    this.props.remove({ ...this.state });
  };

  render() {
    const { levels, use } = this.props.racingLevels;
    return (
      <div>
        <hr />
        <h4>Delete</h4>
        <Row>
          {!isEmpty(use) && (
            <Col xs={12} className="mb-2">
              <div>
                There are currently {use.teams} Teams and {use.riders} Riders using this racing
                level. Please choose the new racing level for these users to be moved to.
              </div>
            </Col>
          )}
          <Col>
            {levels && (
              <RacingLevelSelect
                levels={levels}
                handleChange={this.handleChange}
                labelText="To Delete"
              />
            )}
          </Col>
          {!isEmpty(this.state.toDeleteId) && (
            <Col>
              <RacingLevelSelect
                levels={levels}
                handleChange={this.handleMoveToChange}
                labelText="Move to"
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
const mapDispatch = {
  getUse: racingLevelsActions.getUse,
  remove: racingLevelsActions.remove
};
function mapStateToProps(state) {
  const { racingLevels } = state;
  return { racingLevels };
}

const connectedDelete = connect(
  mapStateToProps,
  mapDispatch
)(Delete);
export { connectedDelete as Delete };
