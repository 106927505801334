import React from 'react';

export const RaceResultCard = props => {
  const { result } = props;
  return (
    <div className="race-result-card d-flex">
      <div
        style={{ color: `white`, background: '#404040', padding: `8px`, width: '3.75em' }}
        className="place text-center"
      >
        {result.placeOrdinal}
      </div>
      <div style={{ padding: `8px` }} className="rider">
        {result.riderName}
      </div>
    </div>
  );
};
