import React from 'react';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../_actions';

/**
 * Dropdown menu logout item, emphasis on strictly to be used in
 * a dropdown menu.
 */
export function Logout({ navigated }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigated();
    dispatch(authenticationActions.logout());
  };

  return (
    <DropdownItem tag={Link} to="/" onClick={handleLogout}>
      Logout
    </DropdownItem>
  );
}
