import React from 'react';
export function NoteCard(props) {
  const { note } = props;
  return (
    <div className="note-card grey-bg mb-2 p-2">
      <div className="note-body">{note.note}</div>
      <div className="note-meta">{note.timeAgo}</div>
    </div>
  );
}
