import { raceConstants } from '../_constants';

export function races(state = {}, action) {
  switch (action.type) {
    case raceConstants.UPDATE_RESULT_REQUEST:
    case raceConstants.UPDATE_RESULT_FAILURE:
    case raceConstants.DELETE_RESULT_REQUEST:
    case raceConstants.DELETE_RESULT_FAILURE:
    case raceConstants.LIST_RESULTS_REQUEST:
    case raceConstants.LIST_RESULTS_FAILURE:
    case raceConstants.LIST_RACES_REQUEST:
    case raceConstants.LIST_RACES_FAILURE:
    case raceConstants.CREATE_RACE_REQUEST:
    case raceConstants.UPDATE_RACE_REQUEST:
    case raceConstants.CREATE_RESULT_REQUEST:
    case raceConstants.CREATE_RACE_FAILURE:
    case raceConstants.UPDATE_RACE_FAILURE:
    case raceConstants.CREATE_RESULT_FAILURE:
      return {
        ...state
      };
    case raceConstants.LIST_RACES_SUCCESS: {
      return {
        ...state,
        races: [...action.data]
      };
    }
    case raceConstants.UPDATE_RESULT_SUCCESS:
    case raceConstants.LIST_RESULTS_SUCCESS:
      return {
        ...state,
        results: {
          ...(state.results || []),
          ...action.data
        }
      };
    case raceConstants.DELETE_RESULT_SUCCESS:
      return {
        ...state,
        results: action.data
      };
    case raceConstants.CREATE_RESULT_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          ...action.data
        }
      };
    case raceConstants.UPDATE_RACE_SUCCESS:
      const oldRaces = state.races;
      const raceIndex = oldRaces.findIndex(r => r.id === action.data.id);
      oldRaces[raceIndex].name = action.data.name;
      return {
        ...state,
        races: [...oldRaces]
      };
    case raceConstants.CREATE_RACE_SUCCESS:
      const races = [...state.races, action.data];
      return {
        ...state,
        races
      };
    default:
      return state;
  }
}
