import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { announcementActions } from '../_actions';
import { Container, Row, Col, Form, FormGroup, Label, CustomInput, Button } from 'reactstrap';
import { AdminHeader, AllAnnouncements } from './';
import { RichEditor } from '../Inputs';
import DatePicker from 'react-date-picker';
const defaultState = { content: '', expires: null, riders: false, teams: false };

class Announcements extends React.Component {
  state = { ...defaultState };

  componentDidMount = () => {
    this.props.listAll();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.created !== this.props.created) {
      if (this.props.created) {
        this.setState({ ...defaultState });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { content, expires, riders, teams } = this.state;
    if ((riders || teams) && expires && content) {
      this.props.create({ content, expires, riders, teams });
    }
  };

  handleChange = e => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  handleDelete = id => {
    this.props.remove(id);
  };

  render() {
    const { all, creating } = this.props;
    return (
      <Fragment>
        <AdminHeader title="Announcements" />
        <Container>
          <Row>
            <Col>
              <h4>Create Announcement</h4>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label>Content</Label>
                  <RichEditor
                    name="content"
                    initialState={this.state.content}
                    handleChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>Expires</Label>
                    <DatePicker
                      className="form-control"
                      name="expires"
                      onChange={value =>
                        this.handleChange({ target: { name: 'expires', value: value } })
                      }
                      value={this.state.expires}
                      showLeadingZeros={true}
                      clearIcon={null}
                      locale="en-AU"
                    />
                  </Col>
                  <Col>
                    <Label className="d-block">Display To</Label>
                    <FormGroup check inline>
                      <CustomInput
                        type="checkbox"
                        name="riders"
                        id="riders"
                        label="Riders"
                        checked={this.state.riders}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup check inline>
                      <CustomInput
                        type="checkbox"
                        name="teams"
                        id="teams"
                        label="Teams"
                        checked={this.state.teams}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label className="d-block">Create</Label>
                      {creating ? (
                        <Button color="info" outline>
                          Sit tight
                        </Button>
                      ) : (
                        <Button color="primary">Create</Button>
                      )}
                    </FormGroup>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        {all && (
          <Container>
            <Row>
              <Col>
                <h4>All Announcements</h4>
                <AllAnnouncements all={all} handleDelete={this.handleDelete} />
              </Col>
            </Row>
          </Container>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { all, creating, created = false } = state.announcement;
  return { all, creating, created };
}

const mapDispatch = {
  ...announcementActions
};

const connectedAnnouncements = connect(
  mapStateToProps,
  mapDispatch
)(Announcements);
export { connectedAnnouncements as Announcements };
