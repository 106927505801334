import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RiderCard = props => {
  const { rider } = props;
  return (
    <div className="rider-card shadow">
      <div className="rider">
        <Row>
          <Col sm={3}>
            {rider.profilePhoto ? (
              <img src={rider.profilePhoto} alt="placeholder" className="img-fluid" />
            ) : (
              <div className="align-middle text-center mx-auto placeholder">
                <FontAwesomeIcon icon="user" className="img-fluid" size="5x" />
              </div>
            )}
          </Col>
          <Col>{rider.displayName}</Col>
        </Row>
      </div>

      {props.children}
    </div>
  );
};
