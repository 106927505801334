import { noticeConstants } from '../_constants';

export function notice(state = {}, action) {
  switch (action.type) {
    case noticeConstants.EXTEND_NOTICE_REQUEST:
    case noticeConstants.EXTEND_NOTICE_FAILURE:
    case noticeConstants.CANCEL_NOTICE_REQUEST:
    case noticeConstants.CANCEL_NOTICE_FAILURE:
    case noticeConstants.LIST_TEAM_NOTICES_REQUEST:
    case noticeConstants.CREATE_NOTICE_REQUEST:
    case noticeConstants.CREATE_NOTICE_FAILURE:
    case noticeConstants.LIST_NOTICES_REQUEST:
    case noticeConstants.LIST_NOTICES_FAILURE:
    case noticeConstants.LIST_EXPIRED_TEAM_NOTICES_REQUEST:
    case noticeConstants.LIST_EXPIRED_TEAM_NOTICES_FAILURE:
      return {
        ...state
      };
    case noticeConstants.LIST_EXPIRED_TEAM_NOTICES_SUCCESS:
      return {
        ...state,
        expired: [...action.data]
      };
    case noticeConstants.CREATE_NOTICE_SUCCESS:
      return {
        ...state,
        current: [action.data, ...state.current]
      };
    case noticeConstants.LIST_NOTICES_SUCCESS:
      return {
        ...state,
        notices: action.data
      };
    case noticeConstants.CONTACT_NOTICE_REQUEST:
      return {
        ...state,
        sent: false,
        sending: true
      };
    case noticeConstants.CONTACT_NOTICE_SUCCESS:
      return {
        ...state,
        sent: true,
        sending: false
      };
    case noticeConstants.CONTACT_NOTICE_FAILURE:
      return {
        ...state,
        sent: false,
        sending: false
      };
    case noticeConstants.LIST_TEAM_NOTICES_FAILURE:
      return {
        ...state
      };
    case noticeConstants.LIST_TEAM_NOTICES_SUCCESS:
      return {
        ...state,
        current: [...action.data]
      };
    case noticeConstants.EXTEND_NOTICE_SUCCESS:
      const ext = action.data;
      const current = state.current.filter(c => c.id !== ext.id);
      return {
        ...state,
        current: [...current, ext]
      };
    case noticeConstants.CANCEL_NOTICE_SUCCESS:
      const cancelled = action.data;
      const curr = state.current.filter(c => c.id !== cancelled.id);
      const exp = state.expired || [];
      return {
        ...state,
        current: curr,
        expired: [...exp, cancelled]
      };
    default:
      return state;
  }
}
