import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function PendingButton(props) {
  const { size, block = false, color } = props;
  return (
    <div className="connect pending">
      <Button color={color} size={size} block={block}>
        <FontAwesomeIcon icon="clock" /> Pending
      </Button>
    </div>
  );
}
