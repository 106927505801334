import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { RiderCard, RequestFooter, PendingFooter, FollowFooter, ApprovedFooter } from './';
import { PlaceholderCard, FormLabel } from '../Shared';
import isEmpty from 'lodash/isEmpty';

export const TeamConnections = props => {
  const { pending, requests, following, approved } = props;
  const {
    connect,
    cancel,
    unfollow,
    follow,
    decline,
    approve,
    declineAndFollow,
    removeModalToggle
  } = props;
  const style = { marginBottom: `2.25rem` };
  return (
    <Row className="mt-5">
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel team text="Follows" />
            </Col>
          </Row>
          {isEmpty(following) && (
            <PlaceholderCard message="Riders you follow will be listed here" />
          )}
          {following &&
            Object.keys(following).map((rider, index) => (
              <RiderCard key={index} rider={following[rider]}>
                <FollowFooter
                  user={following[rider]}
                  unfollow={unfollow}
                  connect={connect}
                  disableConnect={!!requests[rider] || !!pending[rider] || !!approved[rider]}
                  isRider
                />
              </RiderCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel team text="Outbound Requests" />
            </Col>
          </Row>
          {isEmpty(pending) && (
            <PlaceholderCard message="Your outbound requests will display here" />
          )}
          {pending &&
            Object.keys(pending).map((rider, index) => (
              <RiderCard key={index} rider={pending[rider]}>
                <PendingFooter
                  user={pending[rider]}
                  cancel={cancel}
                  follow={follow}
                  disableFollow={!!following[rider]}
                  isRider
                />
              </RiderCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel team text="Inbound Requests" />
            </Col>
          </Row>

          {isEmpty(requests) && (
            <PlaceholderCard message="Your inbound requests will display here" />
          )}
          {requests &&
            Object.keys(requests).map((rider, index) => (
              <RiderCard key={index} rider={requests[rider]}>
                <RequestFooter
                  user={requests[rider]}
                  decline={decline}
                  approve={approve}
                  declineAndFollow={declineAndFollow}
                  hideDeclineAndFollow={!!following[rider]}
                  isRider
                />
              </RiderCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel team text="Connections" />
            </Col>
          </Row>
          {isEmpty(approved) && (
            <PlaceholderCard message="Your approved connections will display here" />
          )}
          {approved &&
            Object.keys(approved).map((rider, index) => (
              <RiderCard key={index} rider={approved[rider]}>
                <ApprovedFooter
                  user={approved[rider]}
                  isRider
                  removeModalToggle={removeModalToggle}
                />
              </RiderCard>
            ))}
        </div>
      </Col>
    </Row>
  );
};

TeamConnections.propTypes = {
  connect: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  unfollow: PropTypes.func.isRequired,
  follow: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  declineAndFollow: PropTypes.func.isRequired
};
