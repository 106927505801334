import { riderSearchConstants } from '../_constants';
import { riderSearchService } from '../_services';

export const riderSearchActions = {
  search,
  pageChange
};

function search(terms) {
  return dispatch => {
    dispatch(request());

    riderSearchService.search(terms).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderSearchConstants.RIDER_SEARCH_REQUEST, data };
  }
  function success(data) {
    return { type: riderSearchConstants.RIDER_SEARCH_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderSearchConstants.RIDER_SEARCH_FAILURE, error };
  }
}

function pageChange(pageNumber) {
  return dispatch => {
    dispatch(request(pageNumber));
  };
  function request(data) {
    return { type: riderSearchConstants.RIDER_SEARCH_PAGE_CHANGE, data };
  }
}
