import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

export const RequestFooter = props => (
  <ButtonGroup className="btn-block">
    <Button
      className="col"
      to={`/${props.isRider ? 'rider' : 'team'}/${props.user.profileSlug}`}
      tag={Link}
      color="primary"
    >
      Profile
    </Button>
    <Button value={props.user.id} onClick={props.approve} className="col" color="success">
      Approve
    </Button>
    <UncontrolledButtonDropdown>
      <DropdownToggle color="danger" caret>
        Decline
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem value={props.user.id} onClick={props.decline}>
          Decline
        </DropdownItem>
        {!props.hideDeclineAndFollow && (
          <DropdownItem value={props.user.id} onClick={props.declineAndFollow}>
            Decline & Follow
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  </ButtonGroup>
);

RequestFooter.propTypes = {
  user: PropTypes.object.isRequired,
  approve: PropTypes.func.isRequired,
  decline: PropTypes.func.isRequired,
  declineAndFollow: PropTypes.func.isRequired,
  hideDeclineAndFollow: PropTypes.bool.isRequired,
  isRider: PropTypes.bool
};
