import { headers, handleResponse, authFetch } from '../_helpers';

export const newsService = {
  createNews,
  addFeaturedImage,
  getNewsBySlug,
  deleteNews,
  listNews,
  createCategory,
  updateCategory,
  listCategories,
  updateNews,
  listForUser
};

const base = `/api/News`;

async function createNews(data) {
  const res = await authFetch(`${base}/Create`, headers.post(data));
  return res;
}

async function addFeaturedImage(image) {
  const res = await authFetch(`${base}/AddImage`, headers.postFile(image));
  return res;
}

async function getNewsBySlug(slug) {
  const res = await authFetch(`${base}/GetNewsBySlug`, headers.postPrimitive(slug));
  return res;
}

async function deleteNews(newsId) {
  const res = await authFetch(`${base}/Delete`, headers.postPrimitive(newsId));
  return res;
}

async function listNews() {
  const res = await fetch(`${base}/List`);
  const response = await handleResponse(res);
  return response;
}

async function createCategory(name) {
  const res = await authFetch(`${base}/CreateCategory`, headers.postPrimitive(name));
  return res;
}

async function updateCategory(data) {
  const res = await authFetch(`${base}/UpdateCategory`, headers.post(data));
  return res;
}

async function listCategories() {
  const res = await fetch(`${base}/ListCategories`);
  const response = await handleResponse(res);
  return response;
}

async function updateNews(data) {
  const res = await authFetch(`${base}/Update`, headers.post(data));
  return res;
}

async function listForUser() {
  const res = await authFetch(`${base}/ListForUser`, headers.get());
  return res;
}
