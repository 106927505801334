export const loadState = () => {
  try {
    const serialisedState = localStorage.getItem('state');
    if (serialisedState === null) {
      return undefined;
    }
    return JSON.parse(serialisedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  const { myProfile, authentication, connections } = state;
  const save = { myProfile, authentication, connections };
  try {
    const serialisedState = JSON.stringify(save);
    localStorage.setItem('state', serialisedState);
  } catch (err) {
    // fail silently.
  }
};
export const removeState = () => {
  try {
    localStorage.removeItem('state');
  } catch (err) {
    // fail silently
  }
};
