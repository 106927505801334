import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Layout } from './Layout';
import { Home, About, NotFound404, Unauthorized } from './Pages/';
import { Register, RiderRegister, TeamRegister } from './Register/';
import { Login } from './Login';
import { ForgotPassword } from './Account/ForgotPassword';
import { ConfirmEmail } from './Account/ConfirmEmail';
import { Connections } from './Connections/';
import { ResetPassword } from './Account/ResetPassword';
import { RiderProfile } from './RiderProfile/';
import { TeamProfile } from './TeamProfile/';
import { RiderProfileEdit } from './RiderProfileEdit/';
import { TeamProfileEdit } from './TeamProfileEdit/';
import { RiderSearch, TeamSearch } from './Search/';
import { Admin } from './Admin';
import {
  ManageAdmins,
  ManageContributors,
  NewsAdmin,
  SiteSettings,
  RacingLevels,
  Announcements
} from './Admin/';
import { NewsCreate, NewsIndex, NewsSingle, NewsEdit } from './News';
import { Dashboard } from './Dashboard';
import { RoleRoute } from './RoleRoute';
import { Notices } from './Notices';
import { RecruitManager } from './Recruit';
import { PrivateRoute } from './PrivateRoute';
import { AuthComplete } from './RiderProfileEdit/AuthComplete';
import { MyArticles } from './Articles/';

function App() {
  return (
    <Layout>
      <Route
        render={({ location }) => (
          <Fragment>
            <ReduxToastr />

            <TransitionGroup>
              <CSSTransition timeout={300} classNames="fade" exit={false} key={location.key}>
                <Switch location={location}>
                  {/* Pages */}
                  <Route exact path="/" component={Home} />
                  <Route path="/about/" component={About} />
                  {/* News */}
                  <Route exact path="/news" component={NewsIndex} />
                  <Route path="/news/:slug" component={NewsSingle} />
                  {/* Registration */}
                  <Route exact path="/register/" component={Register} />
                  <Route exact path="/register/rider" component={RiderRegister} />
                  <Route exact path="/register/team" component={TeamRegister} />
                  {/* Auth */}
                  <Route path="/login/" component={Login} />
                  <Route path="/forgotpassword/" component={ForgotPassword} />
                  <Route path="/resetpassword/:code" component={ResetPassword} />
                  <Route path="/confirmemail/:userId/:code" component={ConfirmEmail} />
                  {/* Rider Profile */}
                  <Route path="/rider/edit/" component={RiderProfileEdit} />
                  <Route path="/rider/:profileSlug" component={RiderProfile} />
                  {/* Team Profile */}
                  <Route path="/team/edit" component={TeamProfileEdit} />
                  <Route path="/team/:profileSlug" component={TeamProfile} />
                  {/* Contributors go to a different article index than Admins.
                   *  However they do  get the same component (at a different
                   *  path) for creating news.
                   */}
                  <RoleRoute contributorRoute exact path="/articles" component={MyArticles} />
                  <RoleRoute contributorRoute exact path="/articles/add" component={NewsCreate} />
                  <RoleRoute
                    contributorRoute
                    exact
                    path="/articles/edit/:slug"
                    component={NewsEdit}
                  />
                  {/* Search */}
                  <RoleRoute path="/ridersearch/" teamRoute component={RiderSearch} />
                  <RoleRoute path="/teamsearch" riderRoute component={TeamSearch} />
                  <RoleRoute path="/connections" teamRoute riderRoute component={Connections} />
                  <RoleRoute path="/notices" teamRoute component={Notices} />
                  {/* Admin */}
                  <RoleRoute exact path="/admin" adminRoute component={Admin} />
                  <RoleRoute exact path="/admin/manageadmins" adminRoute component={ManageAdmins} />
                  <RoleRoute
                    exact
                    path="/admin/managecontributors"
                    adminRoute
                    component={ManageContributors}
                  />
                  <RoleRoute exact path="/admin/news/" adminRoute component={NewsAdmin} />
                  <RoleRoute
                    exact
                    path="/admin/sitesettings/"
                    adminRoute
                    component={SiteSettings}
                  />
                  <RoleRoute
                    exact
                    path="/admin/racinglevels/"
                    adminRoute
                    component={RacingLevels}
                  />
                  <RoleRoute
                    exact
                    path="/admin/announcements/"
                    adminRoute
                    component={Announcements}
                  />
                  <RoleRoute path="/admin/news/create" adminRoute component={NewsCreate} />
                  <RoleRoute path="/admin/news/edit/:slug" adminRoute component={NewsEdit} />
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <RoleRoute path="/recruit" teamRoute component={RecruitManager} />
                  <PrivateRoute path="/todaysplan/auth" component={AuthComplete} />
                  <Route path="/unauthorized" component={Unauthorized} />
                  <Route component={NotFound404} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </Fragment>
        )}
      />
    </Layout>
  );
}
export default App;
