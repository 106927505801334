import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'reactstrap';
import { FormLabel } from '../Shared';

export const Staff = props => {
  const { profile } = props;
  if (!profile.staffSet) {
    return null;
  }
  return (
    <Col sm={6}>
      <Col xs={12} sm={12}>
        <FormLabel team text="Team Staff" />
        <Row className="team-data text-center">
          <Col xs={12} className="shadow p-4">
            <Row>
              {profile.directorSportif && (
                <Col>
                  <Card>
                    <div className="label">Directeur Sportif</div>
                    <div className="data">{profile.directorSportif}</div>
                  </Card>
                </Col>
              )}
              {profile.teamManager && (
                <Col>
                  <Card>
                    <div className="label">Team Manager</div>
                    <div className="data">{profile.teamManager}</div>
                  </Card>
                </Col>
              )}
              {profile.teamMechanic && (
                <Col>
                  <Card>
                    <div className="label">Mechanic</div>
                    <div className="data">{profile.teamMechanic}</div>
                  </Card>
                </Col>
              )}
              {profile.soignuer && (
                <Col>
                  <Card>
                    <div className="label">Soigneur</div>
                    <div className="data">{profile.soignuer}</div>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

Staff.propTypes = {
  profile: PropTypes.object.isRequired
};
