import { riderProfileService } from '../_services';
import { riderProfileConstants, myProfileConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const riderProfileActions = {
  getProfile,
  getRiderProfile,
  updateProfile,
  uploadProfilePhoto,
  uploadBannerPhoto
};

function getProfile() {
  return dispatch => {
    dispatch(request());

    riderProfileService.getProfile().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: myProfileConstants.PROFILE_VIEW_REQUEST, data };
  }
  function success(data) {
    return { type: myProfileConstants.PROFILE_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: myProfileConstants.PROFILE_VIEW_FAILURE, error };
  }
}

function getRiderProfile(profileSlug) {
  return dispatch => {
    dispatch(request());

    riderProfileService.getRiderProfile(profileSlug).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderProfileConstants.RIDER_PROFILE_VIEW_REQUEST, data };
  }
  function success(data) {
    return { type: riderProfileConstants.RIDER_PROFILE_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderProfileConstants.RIDER_PROFILE_VIEW_FAILURE, error };
  }
}

function updateProfile(firstName, lastName, dateOfBirth, riderProfile) {
  return dispatch => {
    dispatch(request(firstName, lastName, dateOfBirth, riderProfile));
    riderProfileService.updateProfile({ firstName, lastName, dateOfBirth, riderProfile }).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile changes saved!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: myProfileConstants.PROFILE_UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: myProfileConstants.PROFILE_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: myProfileConstants.PROFILE_UPDATE_FAILURE, error };
  }
}

function uploadProfilePhoto(data) {
  return dispatch => {
    dispatch(request(data));
    riderProfileService.uploadProfilePhoto(data).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile photo uploaded!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_FAILURE,
      error
    };
  }
}

function uploadBannerPhoto(data) {
  return dispatch => {
    dispatch(request(data));
    riderProfileService.uploadBannerPhoto(data).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile banner photo uploaded!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return {
      type: riderProfileConstants.RIDER_PROFILE_UPLOAD_PROFILE_PHOTO_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: riderProfileConstants.RIDER_PROFILE_UPLOAD_PROFILE_PHOTO_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: riderProfileConstants.RIDER_PROFILE_UPLOAD_PROFILE_PHOTO_FAILURE,
      error
    };
  }
}
