import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { authenticationActions, accountActions } from '../_actions';
import { FormLabel } from '../Shared';

export function ConfirmEmail(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    code: ''
  });
  useEffect(() => {
    dispatch(authenticationActions.logoutNoRedirect());
  }, [dispatch]);

  const { userId, code } = props.match.params;

  const handleSubmit = e => {
    e.preventDefault();
    if (code && userId) {
      dispatch(accountActions.confirmEmail({ code, userId }));
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const { success = false, error = '' } = useSelector(state => state.account);
  return (
    <Container className="account-container registration" fluid>
      <Helmet title="Confirm Email - Cycling Connect" />
      {!success ? (
        <Row style={{ width: `100%` }}>
          <Col sm={12} lg={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel text="Confirm Your Email" />
            <div className="">
              {!success && error.length > 0 && (
                <p style={{ display: 'block', color: 'red' }}>{error}</p>
              )}
              <Form onSubmit={handleSubmit} className="mt-5">
                <FormGroup>
                  <Label for="code" className="sr-only">
                    Code
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    id="code"
                    value={code}
                    required
                    onChange={handleChange}
                  />
                </FormGroup>
                <Button color="primary">Confirm</Button>
              </Form>
            </div>
          </Col>
        </Row>
      ) : (
        <Row style={{ width: `100%` }}>
          <Col sm={12} lg={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel text="Email Confirmed" />
            <p className="message">Thanks for confirming your email, you can now login.</p>
            <div className="float-right">
              <Button tag={Link} to="/forgotpassword">
                Reset Password
              </Button>{' '}
              <Button color="info" tag={Link} to="/login">
                Login to your account
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}
