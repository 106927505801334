import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Row, Col, FormGroup, Label, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

export const CareerHighlightsCreator = props => {
  return (
    <Container className="account-form">
      <Form onSubmit={props.handleCreate}>
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel rider text="Add New Career Highlight" />
          </Col>
          <Col xs={12} md={4}>
            <FormGroup>
              <Label for="place">Race Result</Label>
              <Input
                type="number"
                name="place"
                id="place"
                placeholder="Race Result"
                value={props.place}
                onChange={props.handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Label>Event</Label>
              <Input
                name="event"
                id="event"
                value={props.event}
                onChange={props.handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={2}>
            <FormGroup>
              <Label>Add</Label>
              <Button block outline color="primary">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

CareerHighlightsCreator.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  event: PropTypes.string,
  place: PropTypes.string
};
