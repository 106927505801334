import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { TeamCard, RequestFooter } from '../../Connections';
import { PlaceholderCard, FormLabel } from '../../Shared';
import isEmpty from 'lodash/isEmpty';
import { connectionsActions } from '../../_actions';

function Connections(props) {
  const { list, decline, declineAndFollow, approve } = props;

  useEffect(() => {
    list();
    const interval = setInterval(list, 30000);
    return () => clearInterval(interval);
  }, [list]);

  const declineConn = e => {
    const { value } = e.target;
    decline(value);
  };

  const declineAndFollowConn = e => {
    declineAndFollow(e.target.value);
  };

  const approveConn = e => {
    const { value } = e.target;
    approve(value);
  };

  const { requests, following } = props.connections;
  return (
    <Container className="connections grey-bg pb-2 mb-5">
      <Row>
        <Col>
          <FormLabel rider text="Connection Requests" />
        </Col>
      </Row>
      <Row className="mt-4">
        {!requests && <p>Loading...</p>}
        {isEmpty(requests) && (
          <Col>
            <PlaceholderCard message="No pending connections to show" />
          </Col>
        )}
        <Col xs={12}>
          {requests &&
            Object.keys(requests).map((item, index) => (
              <TeamCard team={requests[item]} key={index}>
                <RequestFooter
                  user={requests[item]}
                  decline={declineConn}
                  approve={approveConn}
                  declineAndFollow={declineAndFollowConn}
                  hideDeclineAndFollow={!!following[item]}
                />
              </TeamCard>
            ))}
        </Col>
      </Row>
    </Container>
  );
}

const mapDispatch = {
  list: connectionsActions.list,
  decline: connectionsActions.decline,
  declineAndFollow: connectionsActions.declineAndFollow,
  approve: connectionsActions.approve
};

function mapStateToProps(state) {
  const { connections } = state;
  return { connections };
}

const connectedConnections = connect(
  mapStateToProps,
  mapDispatch
)(Connections);
export { connectedConnections as Connections };
