import React from 'react';

export const WorldChampLoader = () => (
  <div className="stripes">
    <div className="stripe blue" />
    <div className="stripe red" />
    <div className="stripe black" />
    <div className="stripe yellow" />
    <div className="stripe green" />
  </div>
);
