function greenToRed(val) {
  //value from 0 to 1
  const hue = (val * 120).toString(10);
  return `hsl(${hue}, 100%, 50%)`;
}

function greenToRedHover(val) {
  //value from 0 to 1
  const hue = (val * 120).toString(10);
  return `hsl(${hue}, 100%, 30%)`;
}
const riderTheme = {
  primary: `#2878ce`,
  opaque: `rgba(40, 120, 206, 0.5)`,
  hover: `#205a98`
};

const teamTheme = {
  primary: `#ffcc00`,
  opaque: `rgba(226, 88, 89, 0.5)`,
  hover: `#e4b700`
};

export function getTheme(team, rider) {
  if (team) {
    return { ...teamTheme };
  }
  if (rider) {
    return { ...riderTheme };
  }
}
export const colours = {
  greenToRed,
  greenToRedHover,
  riderTheme,
  teamTheme
};
