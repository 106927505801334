import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

export const Social = props => {
  const { riderProfile } = props;
  return (
    <Col>
      <Col xs={12} className="rider-data rider-social mb-5 pb-2 shadow">
        <FormLabel rider text="Social" />
        <Row className="social">
          {riderProfile.stravaUrl && (
            <Col className="text-center">
              <a href={riderProfile.stravaUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'strava']} />
              </a>
            </Col>
          )}
          {riderProfile.garminUrl && (
            <Col className="text-center">
              <a href={riderProfile.garminUrl} target="_blank" rel="noopener noreferrer">
                <img
                  src="/img/icon/garmin-connect.png"
                  alt="Garmin Connect"
                  className="garmin-connect"
                />
              </a>
            </Col>
          )}
          {riderProfile.youTubeUrl && (
            <Col className="text-center">
              <a href={riderProfile.youTubeUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'youtube']} />
              </a>
            </Col>
          )}
          {riderProfile.twitterUrl && (
            <Col className="text-center">
              <a href={riderProfile.twitterUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'twitter']} />
              </a>
            </Col>
          )}
          {riderProfile.instagramUrl && (
            <Col className="text-center">
              <a href={riderProfile.instagramUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'instagram']} />
              </a>
            </Col>
          )}
          {riderProfile.facebookUrl && (
            <Col className="text-center">
              <a href={riderProfile.facebookUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon={['fab', 'facebook']} />
              </a>
            </Col>
          )}

          {riderProfile.websiteUrl && (
            <Col className="text-center">
              <a href={riderProfile.websiteUrl} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="3x" icon="globe" />
              </a>
            </Col>
          )}
        </Row>
      </Col>
    </Col>
  );
};

Social.propTypes = {
  riderProfile: PropTypes.object.isRequired
};
