import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Button, Form, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FormLabel } from '../Shared';
import { newsActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CategoryEdit extends React.Component {
  state = {};

  componentDidMount = () => {
    this.props.listCategories();
  };

  handleChange = (e, id) => {
    const { name, value } = e.target;
    this.setState({ [id]: { [name]: value } });
  };

  handleSubmit = (e, id) => {
    e.preventDefault();
    const oldCategory = this.props.news.categories.find(c => c.id === id);
    const category = { ...oldCategory, ...this.state[id] };
    this.props.updateCategory(category);
  };

  render() {
    const {
      news: { categories }
    } = this.props;
    return (
      <Container className="account-form category-edit">
        <Row>
          <Col className="mb-4">
            <FormLabel text="Manage Categories" />
          </Col>
        </Row>

        <Row>
          {categories &&
            categories.map((cat, index) => (
              <Col xs={12} key={index}>
                <Form onSubmit={e => this.handleSubmit(e, cat.id)} className="mb-3">
                  <InputGroup>
                    <Input
                      defaultValue={cat.name}
                      type="text"
                      name="name"
                      onChange={e => this.handleChange(e, cat.id)}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="success">
                        <FontAwesomeIcon icon="check" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
              </Col>
            ))}
        </Row>
      </Container>
    );
  }
}
const mapDispatch = {
  listCategories: newsActions.listCategories,
  updateCategory: newsActions.updateCategory
};
function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const connectedCategoryEdit = connect(
  mapStateToProps,
  mapDispatch
)(CategoryEdit);
export { connectedCategoryEdit as CategoryEdit };
