import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { LoadingCard } from '..//Shared';
import { todaysPlanActions } from '../_actions';

function AuthComplete(props) {
  const { tokenGrant, loading, success, error } = props;

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    if (code) {
      tokenGrant(code);
    }
  }, [tokenGrant]);

  return (
    <Container className="mt-4">
      <Helmet title="Today's Plan - Cycling Connect" />
      <Row>
        {loading && (
          <Fragment>
            <Col xs={12}>
              <h4>Finalising connection with Today's Plan</h4>
            </Col>
            <LoadingCard />
          </Fragment>
        )}
        {error && (
          <Col>
            <h4>Connection Failed</h4>
            <p>The connection to Today's Plan could not be finalised, please try again later.</p>
          </Col>
        )}
        {success && (
          <Col>
            <h4>Connection Complete</h4>
            <p>Your connection to Today's Plan has been established.</p>
          </Col>
        )}
      </Row>
    </Container>
  );
}

const mapDispatch = {
  tokenGrant: todaysPlanActions.tokenGrant
};
function mapState(state) {
  const { loading, success, error } = state.todaysPlan;
  return { loading, success, error };
}
const connectedAuthComplete = connect(
  mapState,
  mapDispatch
)(AuthComplete);
export { connectedAuthComplete as AuthComplete };
