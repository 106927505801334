import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import {
  RiderNavMenu,
  TeamNavMenu,
  LoggedOutNavItems,
  AdminNavMenu,
  NavBrand,
  NavToggle
} from './Navigation';

function getAdminNavItems() {
  return [{ name: 'News', href: '/news' }, { name: 'Admin', href: '/admin' }];
}
function getNavTheme(user) {
  if (!user) {
    return 'primary';
  }
  if (user && user.isTeamManager) {
    return 'team';
  }
  if (user && user.isRider) {
    return 'rider';
  }
  return 'primary';
}
/**
 * Generate and return an array of navigation items.
 * @param {*} user
 */
function getPrimaryNavItems(user) {
  if (!user) {
    return [];
  }
  const { isAdmin, isTeamManager, isRider, isContributor } = user;
  const justAdmin = isAdmin && !isRider && !isTeamManager;
  const navItems = [
    { name: 'Dashboard', href: '/dashboard' },
    { name: 'Connections', href: '/connections' }
  ];

  if (justAdmin) {
    return getAdminNavItems();
  }

  // in the very unlikely case that the user is an Admin and Rider/TM
  // we need to return the correct menu accordingly.
  if (isAdmin) {
    navItems.push(...getAdminNavItems());
  }

  if (isContributor) {
    navItems.push({ name: 'Articles', href: '/articles' });
  }

  if (isRider) {
    return [...navItems, { name: 'Search', href: '/teamsearch' }];
  }

  if (isTeamManager) {
    return [
      ...navItems,
      { name: 'Recruit', href: '/recruit' },
      { name: 'Search', href: '/ridersearch' }
    ];
  }
  // if the admin is just an admin, not a TM or rider, this
  // final return will do the job.
  return navItems;
}
function NavMenu(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const navigated = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // common navigation items.
  const { user } = props.authentication;
  const { myProfile } = props;
  const navItems = getPrimaryNavItems(user);
  const navColor = getNavTheme(user);

  return (
    <Fragment>
      <Navbar color={navColor} dark expand="md">
        <NavBrand navigated={navigated} />
        <NavToggle toggle={toggle} isOpen={isOpen} />
        <Collapse isOpen={isOpen} navbar>
          {user ? (
            <Nav className="ml-auto" navbar>
              {navItems &&
                navItems.map((nav, idx) => (
                  <NavItem key={idx}>
                    <NavLink tag={Link} to={nav.href} onClick={navigated}>
                      {nav.name}
                    </NavLink>
                  </NavItem>
                ))}
              {user.isAdmin && myProfile.user && (
                <AdminNavMenu admin={myProfile.user} navigated={navigated} />
              )}
              {user.isRider && myProfile.user && (
                <RiderNavMenu rider={myProfile.user} navigated={navigated} />
              )}
              {user.isTeamManager && myProfile.user && (
                <TeamNavMenu team={myProfile.user} navigated={navigated} />
              )}
            </Nav>
          ) : (
            <LoggedOutNavItems navigated={navigated} />
          )}
        </Collapse>
      </Navbar>
    </Fragment>
  );
}
function mapStateToProps(state) {
  const { authentication, myProfile } = state;
  return { authentication, myProfile };
}

const connectedNavigationBar = connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(NavMenu);
export { connectedNavigationBar as NavMenu };
