import { contributorConstants } from '../_constants';
const initialState = {
  loading: false,
  list: [],
  adding: false,
  added: false
};
export function contributors(state = initialState, action) {
  switch (action.type) {
    case contributorConstants.GET_CONTRIBUTORS_SUCCESS:
      return {
        ...state,
        list: action.data
      };

    case contributorConstants.REMOVE_CONTRIBUTOR_SUCCESS:
      const list = state.list.filter(x => x.id !== action.data);
      return {
        ...state,
        list
      };
    case contributorConstants.GET_CONTRIBUTORS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contributorConstants.GET_CONTRIBUTORS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case contributorConstants.ADD_CONTRIBUTOR_REQUEST:
      return {
        ...state,
        adding: true,
        added: false
      };
    case contributorConstants.ADD_CONTRIBUTOR_SUCCESS:
      return {
        ...state,
        list: [action.data, ...state.list],
        adding: false,
        added: true
      };
    case contributorConstants.ADD_CONTRIBUTOR_FAILURE:
      return {
        ...state,
        adding: false,
        added: false
      };
    case contributorConstants.REMOVE_CONTRIBUTOR_REQUEST:
    case contributorConstants.REMOVE_CONTRIBUTOR_FAILURE:
    default:
      return state;
  }
}
