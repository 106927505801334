export const articleConstants = {
  LIST_ARTICLES_REQUEST: 'LIST_ARTICLES_REQUEST',
  LIST_ARTICLES_SUCCESS: 'LIST_ARTICLES_SUCCESS',
  LIST_ARTICLES_FAILURE: 'LIST_ARTICLES_FAILURE',
  CREATE_ARTICLE_REQUEST: 'CREATE_ARTICLE_REQUEST',
  CREATE_ARTICLE_SUCCESS: 'CREATE_ARTICLE_SUCCESS',
  CREATE_ARTICLE_FAILURE: 'CREATE_ARTICLE_FAILURE',
  EDIT_ARTICLE_REQUEST: 'EDIT_ARTICLE_REQUEST',
  EDIT_ARTICLE_SUCCESS: 'EDIT_ARTICLE_SUCCESS',
  EDIT_ARTICLE_FAILURE: 'EDIT_ARTICLE_FAILURE',
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE'
};
