import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ForTeams = () => (
  <Container className="info" id="teams">
    <Row>
      <Col xs={12}>
        <span className="label team">For Teams</span>
        <p className="lead mt-5">
          Cycling Connect is your comprehensive tool to build the perfect team. Finally, directors
          are able to tap into the vast pool of unsigned riders, looking to make their next move.
        </p>
        <ul className="lead">
          <li>Search for riders based on gender, location, age and more</li>
          <li>Post position openings to relevant riders</li>
          <li>Mange your entire roster on a single page</li>
          <li>Build your profile to attract new riders</li>
        </ul>
      </Col>
      <Col xs={12}>
        <Button color="team" size="lg" tag={Link} to="/register/team">
          <FontAwesomeIcon icon="users" /> Team Sign Up
        </Button>
      </Col>
    </Row>
  </Container>
);
