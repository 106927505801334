import React from 'react';

export const PrivateDataNotice = () => (
  <div>
    <hr />
    <p>
      <strong>Reminder:</strong> your referees and power data are private, and are only visible to
      Teams you have an approved connection with.
    </p>
    <hr />
  </div>
);
