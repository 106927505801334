import { handleResponse } from '../_helpers';

export const riderAgeCategoriesService = {
  getRiderAgeCategories
};

async function getRiderAgeCategories() {
  const response = await fetch(`/api/RiderAgeCategory/`);
  const ageCategories = await handleResponse(response);
  return ageCategories;
}
