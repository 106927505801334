import { authFetch, headers } from '../_helpers';

export const riderRefereeService = {
  createReferee,
  updateReferee,
  deleteReferee,
  listReferees
};

async function createReferee(data) {
  const response = await authFetch(`/api/Referee/Create`, headers.post(data));
  return response;
}

async function updateReferee(data) {
  const response = await authFetch(`/api/Referee/Update`, headers.post(data));
  return response;
}

async function deleteReferee(refereeId) {
  const response = await authFetch(`/api/Referee/Delete`, headers.postPrimitive(refereeId));
  return response;
}

async function listReferees() {
  const response = await authFetch(`/api/Referee/List`, headers.post());
  return response;
}
