import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Createable from 'react-select/lib/Creatable';

const reactSelectStyles = {
  option: (base, state) => {
    const isNew = state.data.__isNew__ || false;
    return {
      ...base,
      fontWeight: isNew ? `bold` : 'inherit'
    };
  },
  container: () => ({
    border: 'none'
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    background: `#fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5rem center`,
    backgroundSize: `8px 10px`,
    boxShadow: 'none',
    border: `1px solid rgba(226, 88, 89, ${isFocused ? 1 : 0.5})`,
    borderRadius: 2,
    ':hover': {
      borderColor: 'rgb(226, 88 ,89)'
    }
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: '22px'
  }),
  input: base => ({
    ...base,
    color: `#7b8a8b`
  }),
  menu: () => ({
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: 1,
    marginBottom: 0,
    marginTop: 5,
    width: '100%',
    maxWidth: '250px',
    border: '1px solid rgb(226, 88 ,89)',
    borderRadius: 2
  })
};

export const RiderLookup = props => {
  const { riders, riderLookupChange, riderLookupSelect, riderName, riderId } = props;
  let riderOptions = [];
  if (riders) {
    riderOptions = riders.map(r => ({
      value: r.id,
      label: r.displayName
    }));
  }
  return (
    <FormGroup>
      <Label>Rider</Label>
      <Createable
        cacheOptions
        allowCreateWhileLoading
        options={riderOptions}
        styles={reactSelectStyles}
        onInputChange={riderLookupChange}
        value={{ label: riderName, value: riderId }}
        isClearable
        onChange={val => {
          riderLookupSelect(val);
        }}
      />
    </FormGroup>
  );
};

RiderLookup.propTypes = {
  riders: PropTypes.array,
  riderLookupChange: PropTypes.func.isRequired
};
