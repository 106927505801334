export const raceConstants = {
  LIST_RACES_REQUEST: 'LIST_RACES_REQUEST',
  LIST_RACES_SUCCESS: 'LIST_RACES_SUCCESS',
  LIST_RACES_FAILURE: 'LIST_RACES_FAILURE',
  LIST_RESULTS_REQUEST: 'LIST_RESULTS_REQUEST',
  LIST_RESULTS_SUCCESS: 'LIST_RESULTS_SUCCESS',
  LIST_RESULTS_FAILURE: 'LIST_RESULTS_FAILURE',
  UPDATE_RESULT_REQUEST: 'UPDATE_RESULT_REQUEST',
  UPDATE_RESULT_SUCCESS: 'UPDATE_RESULT_SUCCESS',
  UPDATE_RESULT_FAILURE: 'UPDATE_RESULT_FAILURE',
  DELETE_RESULT_REQUEST: 'DELETE_RESULT_REQUEST',
  DELETE_RESULT_SUCCESS: 'DELETE_RESULT_SUCCESS',
  DELETE_RESULT_FAILURE: 'DELETE_RESULT_FAILURE',
  CREATE_RACE_REQUEST: 'CREATE_RACE_REQUEST',
  CREATE_RACE_SUCCESS: 'CREATE_RACE_SUCCESS',
  CREATE_RACE_FAILURE: 'CREATE_RACE_FAILURE',
  UPDATE_RACE_REQUEST: 'UPDATE_RACE_REQUEST',
  UPDATE_RACE_SUCCESS: 'UPDATE_RACE_SUCCESS',
  UPDATE_RACE_FAILURE: 'UPDATE_RACE_FAILURE',
  CREATE_RESULT_REQUEST: 'CREATE_RESULT_REQUEST',
  CREATE_RESULT_SUCCESS: 'CREATE_RESULT_SUCCESS',
  CREATE_RESULT_FAILURE: 'CREATE_RESULT_FAILURE'
};
