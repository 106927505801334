import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { FormLabel } from '../Shared';

export const TeamContactEdit = props => {
  const { handleChange, contact } = props;
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team text="Team Contact" />
        </Col>

        <Col xs={12} sm={4}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              name="name"
              id="name"
              defaultValue={contact ? contact.name : ''}
              onChange={handleChange}
              // required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              name="email"
              id="email"
              defaultValue={contact ? contact.email : ''}
              onChange={handleChange}
              // required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label for="phone">Phone</Label>
            <Input
              name="phone"
              id="phone"
              defaultValue={contact ? contact.phone : ''}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

TeamContactEdit.propTypes = {
  handleChange: PropTypes.func.isRequired,
  contact: PropTypes.object
};
