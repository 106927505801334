import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Jumbotron, Container, Row, Col, Button } from 'reactstrap';
import { newsActions } from '../_actions';
import { CategoryCreate, CategoryEdit, NewsCard } from '../News';
import isEmpty from 'lodash/isEmpty';

class NewsAdmin extends React.Component {
  componentDidMount = () => {
    this.props.listNews();
  };

  handleDelete = e => {
    e.preventDefault();
    const newsId = e.target.value;
    this.props.deleteNews(newsId);
  };

  render() {
    const { list, newsLoading } = this.props;
    return (
      <div className="news-admin">
        <Helmet title="Manage News - Cycling Connect" />
        <Jumbotron className="smaller">
          <Container>
            <Row>
              <Col>
                <h2 className="display-4">Manage News</h2>
              </Col>
              <Col>
                <Button tag={Link} color="success" to="/admin/news/create">
                  Create News
                </Button>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container>
          {newsLoading && (
            <Row>
              <Col>Loading</Col>
            </Row>
          )}

          {isEmpty(list) && (
            <Row>
              <Col>No news items</Col>
            </Row>
          )}
          <Row>
            <Col sm={8}>
              <Row>
                {list &&
                  list.map((news, index) => (
                    <NewsCard news={news} key={index}>
                      <div className="actions d-flex">
                        <Button
                          className="flex-fill"
                          color="primary"
                          tag={Link}
                          to={`edit/${news.slug}`}
                        >
                          Edit
                        </Button>
                        <Button
                          className="flex-fill"
                          color="danger"
                          value={news.id}
                          onClick={this.handleDelete}
                        >
                          Delete
                        </Button>
                      </div>
                    </NewsCard>
                  ))}
              </Row>
            </Col>
            <Col sm={4}>
              <CategoryCreate />
              <CategoryEdit />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapDispatch = {
  listNews: newsActions.listNews,
  deleteNews: newsActions.deleteNews
};
function mapStateToProps(state) {
  const { list, newsLoading } = state.news;
  return { list, newsLoading };
}

const connectedNewsAdmin = connect(
  mapStateToProps,
  mapDispatch
)(NewsAdmin);
export { connectedNewsAdmin as NewsAdmin };
