import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  ButtonGroup,
  UncontrolledTooltip
} from 'reactstrap';
import { FormLabel } from '../Shared';
import { RacingLevelSelect, RiderStyleSelect } from '../Inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RidingDetailsEdit = props => {
  const {
    profile,
    stateProfile,
    ridingStyles,
    racingLevels,
    handleProfileChange,
    radioButtonChange
  } = props;

  return (
    <Container className="account-form">
      {profile && (
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel rider text="Riding Details" />
          </Col>
          <Col xs={12} sm={6} md={3} xl={2}>
            <FormGroup>
              <Label for="height">Height</Label>
              <InputGroup>
                <Input
                  name="height"
                  id="height"
                  defaultValue={profile.height}
                  type="number"
                  onChange={handleProfileChange}
                />
                <InputGroupAddon addonType="append">cm</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={3} xl={2}>
            <FormGroup>
              <Label for="weight">Weight</Label>
              <InputGroup>
                <Input
                  name="weight"
                  id="weight"
                  defaultValue={profile.weight}
                  type="number"
                  onChange={handleProfileChange}
                />
                <InputGroupAddon addonType="append">kg</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={6} xl={2}>
            {ridingStyles && (
              <RiderStyleSelect
                handleProfileChange={handleProfileChange}
                styles={ridingStyles}
                currentStyleId={profile.riderStyleId}
              />
            )}
          </Col>
          <Col xs={12} sm={6} md={6} xl={2}>
            {racingLevels && (
              <RacingLevelSelect
                showTooltip
                handleChange={handleProfileChange}
                levels={racingLevels}
                currentLevelId={profile.racingLevelId}
              />
            )}
          </Col>
          <Col xs={6} sm={6} md={3} xl={2} className="yes-no-switch">
            <FormGroup>
              <Label>
                Relocate{' '}
                <span href="#" id="relocate-tooltip">
                  <FontAwesomeIcon icon="question-circle" />
                </span>
              </Label>
              <UncontrolledTooltip placement="top" target="relocate-tooltip">
                Prepared to relocate for a Team
              </UncontrolledTooltip>
              <InputGroup>
                <ButtonGroup>
                  <Button
                    color="rider"
                    onClick={() => radioButtonChange(true, 'relocate')}
                    active={
                      typeof stateProfile.relocate !== 'undefined'
                        ? stateProfile.relocate
                        : profile.relocate
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    color="rider"
                    onClick={() => radioButtonChange(false, 'relocate')}
                    active={
                      typeof stateProfile.relocate !== 'undefined'
                        ? !stateProfile.relocate
                        : !profile.relocate
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={6} sm={6} md={3} xl={2} className="yes-no-switch">
            <FormGroup>
              <Label>Out of Contract</Label>
              <InputGroup>
                <ButtonGroup>
                  <Button
                    color="rider"
                    onClick={() => radioButtonChange(true, 'outOfContract')}
                    active={
                      typeof stateProfile.outOfContract !== 'undefined'
                        ? stateProfile.outOfContract
                        : profile.outOfContract
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    color="rider"
                    onClick={() => radioButtonChange(false, 'outOfContract')}
                    active={
                      typeof stateProfile.outOfContract !== 'undefined'
                        ? !stateProfile.outOfContract
                        : !profile.outOfContract
                    }
                  >
                    No
                  </Button>
                </ButtonGroup>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      )}
    </Container>
  );
};

RidingDetailsEdit.propTypes = {
  profile: PropTypes.object,
  stateProfile: PropTypes.object,
  ridingStyles: PropTypes.array,
  racingLevels: PropTypes.array,
  handleProfileChange: PropTypes.func,
  radioButtonChange: PropTypes.func
};
