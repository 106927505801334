import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { TeamDashboard, RiderDashboard } from './';

function Dashboard(props) {
  const { user } = props;
  return (
    <div className="dashboard pt-5">
      <Helmet title="Dashboard - Cycling Connect" />
      {user.isTeamManager && <TeamDashboard />}
      {user.isRider && <RiderDashboard />}
    </div>
  );
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
