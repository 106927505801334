import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { raceActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-date-picker';
import { LocationFinder } from '../Inputs';
const defaultState = { name: '', date: new Date(), city: '', state: '', country: '' };

class AddRace extends React.Component {
  state = { ...defaultState };

  componentDidMount = () => {
    this.props.listRaces();
    this.locationFinder = React.createRef();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, date, city, state, country } = this.state;
    if (name.length && date && country.length) {
      this.props.createRace({ name, date, city, state, country });
      this.setState({ ...defaultState });
      this.locationFinder.current.clearInput();
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Fragment>
        <h5>Add Race</h5>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={6} lg={4}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input name="name" id="name" value={this.state.name} onChange={this.handleChange} />
              </FormGroup>
            </Col>
            <Col md={6} lg={3}>
              <FormGroup>
                <Label for="date">Date</Label>
                <DatePicker
                  className="form-control team"
                  onChange={value => this.handleChange({ target: { name: 'date', value: value } })}
                  value={this.state.date}
                  showLeadingZeros={true}
                  locale="en-AU" // how about navigator.language(s)?
                />
              </FormGroup>
            </Col>

            <Col md={8} lg={4}>
              <LocationFinder ref={this.locationFinder} handleChange={this.handleChange} />
            </Col>
            <Col md={4} lg={1}>
              <FormGroup>
                <Label>Add</Label>
                <Button outline block color="primary">
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Fragment>
    );
  }
}
const mapDispatch = {
  listRaces: raceActions.listRaces,
  createRace: raceActions.createRace
};
function mapStateToProps(state) {
  const { races } = state;
  return { races };
}

const connectedAddRace = connect(
  mapStateToProps,
  mapDispatch
)(AddRace);
export { connectedAddRace as AddRace };
