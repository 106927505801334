import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions, authenticationActions as authActions } from '../_actions';
import { Container, Row, Col, Button, Form } from 'reactstrap';
import { FormLabel } from '../Shared';
import { Helmet } from 'react-helmet';
import { EmailInput } from '../HookInputs';
import { validation } from '../_helpers';

export function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);

  const { success = false, error } = useSelector(state => state.account);

  useEffect(() => {
    dispatch(authActions.logoutNoRedirect());
  }, [dispatch]);

  const validateEmail = value => {
    const valid = validation.email(value);
    setEmailValid(valid);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      dispatch(accountActions.forgotPassword(email));
    }
  };

  return (
    <Container className="account-container registration" fluid>
      <Helmet title="Recover Your Password - Cycling Connect" />
      {!success ? (
        <Row style={{ width: `100%` }}>
          <Col sm={12} md={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel text="Reset Password" />
            <div className="mt-5">
              {error && <p style={{ display: 'block', color: 'red' }}>{error}</p>}
              <Form onSubmit={handleSubmit}>
                <EmailInput
                  handleChange={setEmail}
                  value={email}
                  valid={emailValid}
                  validate={validateEmail}
                />
                <Button color="primary">Submit</Button>
                <div className="float-right">
                  <Button tag={Link} to="/register">
                    Register
                  </Button>{' '}
                  <Button color="info" tag={Link} to="/login">
                    Login to your account
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      ) : (
        <Row style={{ width: `100%` }}>
          <Col sm={12} lg={{ size: 6, offset: 3 }} className="account-form rounded">
            <FormLabel text="Password Reset" />
            <p className="message">Check your email for further instructions.</p>
          </Col>
        </Row>
      )}
    </Container>
  );
}
