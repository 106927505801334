import { handleResponse } from '../_helpers';

export const riderSexesService = {
  getRiderSexes
};

async function getRiderSexes() {
  const response = await fetch(`/api/RiderSexes/`);
  const racingLevels = await handleResponse(response);
  return racingLevels;
}
