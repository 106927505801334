import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { RichEditor } from '../Inputs';
import { FormLabel } from '../Shared';

export const TeamSummaryEdit = props => {
  const { profile, handleChange } = props;
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-5">
          <FormLabel team text="Team Summary" />
        </Col>
        <Col>
          <RichEditor className="team" initialState={profile.summary} handleChange={handleChange} />
        </Col>
      </Row>
    </Container>
  );
};

TeamSummaryEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};
