import { adminProfileService } from '../_services';
import { myProfileConstants } from '../_constants';

export const adminProfileActions = {
  getProfile
};

function getProfile() {
  return dispatch => {
    dispatch(request());

    adminProfileService.getProfile().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: myProfileConstants.PROFILE_VIEW_REQUEST, data };
  }
  function success(data) {
    return { type: myProfileConstants.PROFILE_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: myProfileConstants.PROFILE_VIEW_FAILURE, error };
  }
}
