import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FollowNoActionsButton } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connectionsActions } from '../_actions';

class FollowButton extends React.Component {
  follow = e => {
    const { value } = e.target;
    this.props.follow(value);
  };

  unfollow = e => {
    const { value } = e.target;
    this.props.unfollow(value);
  };

  render() {
    const { userId, following, approved, size, color, block } = this.props;
    if (approved && approved[userId]) {
      return <FollowNoActionsButton color={color} block={block} size={size} />;
    }
    if (following && following[userId]) {
      return (
        <Following
          unfollow={this.unfollow}
          block={block}
          userId={userId}
          color={color}
          size={size}
        />
      );
    }

    return (
      <div className="follow">
        <Button
          color={color || 'primary'}
          block={block}
          size={size}
          value={userId}
          onClick={this.follow}
        >
          <FontAwesomeIcon icon="plus-circle" /> Follow
        </Button>
      </div>
    );
  }
}

function Following(props) {
  const { userId, unfollow, size, color, block = false } = props;
  return (
    <div className="follow following">
      <UncontrolledButtonDropdown className={block ? 'btn-block' : ''} size={size}>
        <DropdownToggle block={block} color={color || 'primary'} caret>
          <FontAwesomeIcon icon="check-circle" /> Following
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={unfollow} value={userId}>
            Unfollow
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
}
const mapDispatch = {
  follow: connectionsActions.follow,
  unfollow: connectionsActions.unfollow
};
function mapStateToProps(state) {
  const { following, approved } = state.connections;
  return { following, approved };
}

const connectedFollowButton = connect(
  mapStateToProps,
  mapDispatch
)(FollowButton);
export { connectedFollowButton as FollowButton };
