import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { EmailInput } from './Inputs/';
import { FormLabel } from './Shared';
import { authenticationActions } from './_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login(props) {
  const [state, setState] = useState({
    email: '',
    username: '',
    submitted: false,
    error: false
  });
  const { logoutNoRedirect } = props;
  useEffect(() => {
    logoutNoRedirect();
  }, [logoutNoRedirect]);

  const { from } = props.location.state || { from: { pathname: '/' } };

  const handleChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, submitted: true });
    const { email, password } = state;
    if (email && password) {
      props.login(email, password, from);
    }
  };

  const { message, error, loggingIn = false } = props;
  return (
    <Container className="account-container login registration" fluid>
      <Helmet title="Login to Your Account - Cycling Connect">
        <meta name="description" content="Login to your account" />
      </Helmet>
      <Row style={{ width: `100%` }}>
        <Col sm={12} md={{ size: 6, offset: 3 }} className="account-form">
          <Row>
            <FormLabel text="Login" />
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <EmailInput handleChange={handleChange} errors={[]} className="mt-5" />
                <FormGroup>
                  <Label className="sr-only" for="password">
                    Password
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    required
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                </FormGroup>
                {error && (
                  <FormGroup>
                    <p style={{ display: 'block', color: 'red' }}>{message}</p>
                  </FormGroup>
                )}
                {!loggingIn ? (
                  <Button color="primary">Log In</Button>
                ) : (
                  <Button color="info">
                    <FontAwesomeIcon icon="spinner" spin /> Logging in
                  </Button>
                )}

                <div className="float-right">
                  <Button tag={Link} to="/forgotpassword">
                    Reset Password
                  </Button>{' '}
                  <Button tag={Link} color="info" to="/register">
                    Register
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
const mapDispatch = {
  logoutNoRedirect: authenticationActions.logoutNoRedirect,
  login: authenticationActions.login
};
function mapStateToProps(state) {
  const { loggingIn, message, error } = state.authentication;
  return {
    loggingIn,
    error,
    message
  };
}

const connectedLogin = connect(
  mapStateToProps,
  mapDispatch
)(Login);
export { connectedLogin as Login };
