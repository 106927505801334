import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Button, Label } from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import { reactSelectStyles } from '../Inputs/ReactSelectStyles';
import { adminActions } from '../_actions';
import debounce from 'lodash/debounce';

class UserLookup extends React.Component {
  handleLookup = debounce((terms, callback) => {
    this.props.userLookup(terms);
    setTimeout(() => {
      callback(
        this.props.lookup.map(l => ({
          value: l.id,
          label: l.displayName,
          id: l.id
        }))
      );
    }, 250);
  }, 350);

  handleSelect = value => {
    this.setState({ userId: value.id });
  };

  handleMakeAdmin = () => {
    const { userId } = this.state;
    if (userId) {
      this.props.makeUserAdmin(this.state.userId);
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>User Lookup</Label>
              <AsyncSelect
                cacheOptions
                styles={reactSelectStyles}
                loadOptions={this.handleLookup}
                onChange={val => this.handleSelect(val)}
                name="terms"
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>Apply</Label>
              <Button color="primary" block onClick={this.handleMakeAdmin}>
                Make Admin
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapDispatch = {
  userLookup: adminActions.userLookup,
  makeUserAdmin: adminActions.makeUserAdmin
};
function mapStateToProps(state) {
  const { lookup = null } = state.admin;
  return { lookup };
}

const connectedUserLookup = connect(
  mapStateToProps,
  mapDispatch
)(UserLookup);
export { connectedUserLookup as UserLookup };
