export const teamRosterConstants = {
  TEAM_ROSTER_CREATE_REQUEST: 'TEAM_ROSTER_CREATE_REQUEST',
  TEAM_ROSTER_CREATE_SUCCESS: 'TEAM_ROSTER_CREATE_SUCCESS',
  TEAM_ROSTER_CREATE_FAILURE: 'TEAM_ROSTER_CREATE_FAILURE',
  TEAM_ROSTER_UPDATE_REQUEST: 'TEAM_ROSTER_UPDATE_REQUEST',
  TEAM_ROSTER_UPDATE_SUCCESS: 'TEAM_ROSTER_UPDATE_SUCCESS',
  TEAM_ROSTER_UPDATE_FAILURE: 'TEAM_ROSTER_UPDATE_FAILURE',
  TEAM_ROSTER_DELETE_REQUEST: 'TEAM_ROSTER_DELETE_REQUEST',
  TEAM_ROSTER_DELETE_SUCCESS: 'TEAM_ROSTER_DELETE_SUCCESS',
  TEAM_ROSTER_DELETE_FAILURE: 'TEAM_ROSTER_DELETE_FAILURE',
  TEAM_ROSTER_LIST_REQUEST: 'TEAM_ROSTER_LIST_REQUEST',
  TEAM_ROSTER_LIST_SUCCESS: 'TEAM_ROSTER_LIST_SUCCESS',
  TEAM_ROSTER_LIST_FAILURE: 'TEAM_ROSTER_LIST_FAILURE',
  TEAM_ROSTER_RIDER_LOOKUP_REQUEST: 'TEAM_ROSTER_RIDER_LOOKUP_REQUEST',
  TEAM_ROSTER_RIDER_LOOKUP_SUCCESS: 'TEAM_ROSTER_RIDER_LOOKUP_SUCCESS',
  TEAM_ROSTER_RIDER_LOOKUP_FAILURE: 'TEAM_ROSTER_RIDER_LOOKUP_FAILURE'
};
