export const noticeConstants = {
  CREATE_NOTICE_REQUEST: 'CREATE_NOTICE_REQUEST',
  CREATE_NOTICE_SUCCESS: 'CREATE_NOTICE_SUCCESS',
  CREATE_NOTICE_FAILURE: 'CREATE_NOTICE_FAILURE',
  LIST_NOTICES_REQUEST: 'LIST_NOTICES_REQUEST',
  LIST_NOTICES_SUCCESS: 'LIST_NOTICES_SUCCESS',
  LIST_NOTICES_FAILURE: 'LIST_NOTICES_FAILURE',
  CONTACT_NOTICE_REQUEST: 'CONTACT_NOTICE_REQUEST',
  CONTACT_NOTICE_SUCCESS: 'CONTACT_NOTICE_SUCCESS',
  CONTACT_NOTICE_FAILURE: 'CONTACT_NOTICE_FAILURE',
  LIST_TEAM_NOTICES_REQUEST: 'LIST_TEAM_NOTICES_REQUEST',
  LIST_TEAM_NOTICES_SUCCESS: 'LIST_TEAM_NOTICES_SUCCESS',
  LIST_TEAM_NOTICES_FAILURE: 'LIST_TEAM_NOTICES_FAILURE',
  LIST_EXPIRED_TEAM_NOTICES_REQUEST: 'LIST_EXPIRED_TEAM_NOTICES_REQUEST',
  LIST_EXPIRED_TEAM_NOTICES_SUCCESS: 'LIST_EXPIRED_TEAM_NOTICES_SUCCESS',
  LIST_EXPIRED_TEAM_NOTICES_FAILURE: 'LIST_EXPIRED_TEAM_NOTICES_FAILURE',
  EXTEND_NOTICE_REQUEST: 'EXTEND_NOTICE_REQUEST',
  EXTEND_NOTICE_SUCCESS: 'EXTEND_NOTICE_SUCCESS',
  EXTEND_NOTICE_FAILURE: 'EXTEND_NOTICE_FAILURE',
  CANCEL_NOTICE_REQUEST: 'CANCEL_NOTICE_REQUEST',
  CANCEL_NOTICE_SUCCESS: 'CANCEL_NOTICE_SUCCESS',
  CANCEL_NOTICE_FAILURE: 'CANCEL_NOTICE_FAILURE',
  NOTICE_PROFILE_VIEW_REQUEST: 'NOTICE_PROFILE_VIEW_REQUEST'
};
