import { headers, authFetch } from '../_helpers';

export const contributorService = {
  getContributors,
  addContributor,
  removeContributor
};
const base = `/api/UserManagement`;
async function getContributors() {
  const res = await authFetch(`${base}/GetContributors`, headers.get());
  return res;
}

async function addContributor(userId) {
  const res = await authFetch(`${base}/AddContributor`, headers.postPrimitive(userId));
  return res;
}

async function removeContributor(userId) {
  const res = await authFetch(`${base}/RemoveContributor`, headers.postPrimitive(userId));
  return res;
}
