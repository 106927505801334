import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Container, Row, Col, Button } from 'reactstrap';
import { FormLabel } from '../Shared';
import { teamRosterActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TeamRosterEditor extends React.Component {
  state = {};

  componentDidMount = () => {
    this.props.listRosterForUser();
  };

  handleChange = (e, id) => {
    const { name, value } = e.target;
    this.setState({ [id]: { [name]: value } });
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    this.props.deleteRoster(id);
  };

  handleSubmit = (e, id) => {
    e.preventDefault();
    const rosterItem = this.props.teamRoster.roster.find(i => i.id === id);
    const updatedRoster = { ...rosterItem, ...this.state[id] };
    this.props.updateRoster(updatedRoster);
  };

  render() {
    const { roster } = this.props.teamRoster;
    return (
      <Container className="account-form">
        <Row>
          <Col className="pb-4">
            <FormLabel team text="Edit Team Roster" />
          </Col>
        </Row>

        {!roster && <Col>Loading...</Col>}
        {roster && !roster.length && <Col>No Riders in Roster</Col>}
        {roster && roster.length >= 1 && (
          <Fragment>
            <Row>
              <Col sm={3} md={2} className="d-none d-sm-block">
                <Label>Season</Label>
              </Col>
              <Col sm={5} md={5} lg={6} className="d-none d-sm-block">
                <Label>Rider</Label>
              </Col>
              <Col xs={6} sm={2} className="d-none d-sm-block">
                <Label>Update</Label>
              </Col>
              <Col xs={6} sm={2} className="d-none d-sm-block">
                <Label>Delete</Label>
              </Col>
            </Row>
            {roster.map((r, index) => (
              <Form key={index} onSubmit={e => this.handleSubmit(e, r.id)}>
                <Row>
                  <Col sm={3} md={2}>
                    <FormGroup>
                      <Label className="sr-only">Season</Label>
                      <Input
                        type="number"
                        name="season"
                        defaultValue={r.season}
                        onChange={e => this.handleChange(e, r.id)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={5} md={5} lg={6}>
                    <FormGroup>
                      <Label className="sr-only">Rider</Label>
                      <Input
                        name="riderName"
                        type="text"
                        defaultValue={r.riderName}
                        onChange={e => this.handleChange(e, r.id)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={2}>
                    <FormGroup>
                      <Label className="sr-only">Update</Label>
                      <Button outline block color="primary">
                        <FontAwesomeIcon icon="check" />
                      </Button>
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={2}>
                    <FormGroup>
                      <Label className="sr-only">Delete</Label>
                      <Button
                        outline
                        block
                        color="danger"
                        value={r.id}
                        onClick={e => this.handleDelete(e, r.id)}
                      >
                        <FontAwesomeIcon icon="times" />
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            ))}
          </Fragment>
        )}
      </Container>
    );
  }
}
const mapDispatch = {
  listRosterForUser: teamRosterActions.listRosterForUser,
  deleteRoster: teamRosterActions.deleteRoster,
  updateRoster: teamRosterActions.updateRoster
};
function mapStateToProps(state) {
  const { teamRoster } = state;
  return { teamRoster };
}

const connectedTeamRosterEditor = connect(
  mapStateToProps,
  mapDispatch
)(TeamRosterEditor);
export { connectedTeamRosterEditor as TeamRosterEditor };
