import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { Logout } from './';

export const AdminNavMenu = ({ admin, navigated }) => {
  return (
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Hi, {admin.firstName}
        </DropdownToggle>
        <DropdownMenu right>
          <Logout navigated={navigated} />
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

AdminNavMenu.propTypes = {
  admin: PropTypes.object.isRequired,
  navigated: PropTypes.func.isRequired
};
