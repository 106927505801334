import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { ConnectedButton, PendingButton } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connectionsActions } from '../_actions';

class ConnectionsButton extends React.Component {
  state = { sending: false };
  handleConnect = (e, userName) => {
    const userId = e.target.value;
    if (userId) {
      // TODO make this nice
      // this.setState({ sending: true });
      this.props.connect(userId, userName);
    }
  };

  render() {
    const {
      userId,
      userName,
      disable,
      pending,
      approved,
      requests,
      size,
      color = 'primary',
      block = false
    } = this.props;
    const { sending } = this.state;
    if ((pending && pending[userId]) || (requests && requests[userId])) {
      return <PendingButton color={color} block={block} size={size} />;
    } else if (approved && approved[userId]) {
      return (
        <ConnectedButton
          size={size}
          color={color}
          block={block}
          userId={userId}
          handleDisconnect={this.handleDisconnect}
        />
      );
    }

    return (
      <div className="connect">
        {sending ? (
          <Button color={color} size={size} disabled={true}>
            <FontAwesomeIcon icon="plus-circle" spin /> Sending
          </Button>
        ) : (
          <Button
            color={color}
            size={size}
            block={block}
            value={userId}
            onClick={e => this.handleConnect(e, userName)}
            disabled={disable}
          >
            <FontAwesomeIcon icon="plus-circle" /> Connect
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pending, approved, requests } = state.connections;
  return { pending, approved, requests };
}
const connectedConnectionsButton = connect(
  mapStateToProps,
  { connect: connectionsActions.connect }
)(ConnectionsButton);
export { connectedConnectionsButton as ConnectionsButton };
