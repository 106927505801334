import { riderCareerHistoryService } from '../_services';
import { riderCareerHistoryConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const riderCareerHistoryActions = {
  listCareerHistory,
  deleteCareerHistory,
  createUpdateCareerHistory
};

function listCareerHistory() {
  return dispatch => {
    dispatch(request());
    riderCareerHistoryService.listCareerHistory().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderCareerHistoryConstants.LIST_HISTORY_REQUEST, data };
  }
  function success(data) {
    return { type: riderCareerHistoryConstants.LIST_HISTORY_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderCareerHistoryConstants.LIST_HISTORY_FAILURE, error };
  }
}

function deleteCareerHistory(historyId) {
  return dispatch => {
    dispatch(request(historyId));
    riderCareerHistoryService.deleteCareerHistory(historyId).then(
      data => {
        dispatch(success(data));
        toastr.success('Career history removed');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(historyId) {
    return { type: riderCareerHistoryConstants.DELETE_CAREER_REQUEST, historyId };
  }
  function success(data) {
    return { type: riderCareerHistoryConstants.DELETE_CAREER_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderCareerHistoryConstants.DELETE_CAREER_FAILURE, error };
  }
}

function createUpdateCareerHistory(careerHistory) {
  return dispatch => {
    dispatch(request(careerHistory));

    riderCareerHistoryService.createUpdateCareerHistory(careerHistory).then(
      data => {
        dispatch(success(data));
        toastr.success('Career history recorded!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderCareerHistoryConstants.CREATE_UPDATE_CAREER_REQUEST, data };
  }
  function success(data) {
    return { type: riderCareerHistoryConstants.CREATE_UPDATE_CAREER_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderCareerHistoryConstants.CREATE_UPDATE_CAREER_FAILURE, error };
  }
}
