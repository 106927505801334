import React from 'react';
import { Form, Col, Container, Row, FormGroup, CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import { newsActions } from '../_actions';
import { FormLabel } from '../Shared';
import { UploadButton, Uploading } from '../Inputs';

class AddFeaturedImage extends React.Component {
  state = {};

  handleChange = e => {
    const { value } = e.target;
    const file = e.target.files[0];
    this.setState({ file, label: value.split('\\').pop() });
  };

  handleUpload = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', this.state.file);
    this.props.addFeaturedImage(formData);
  };

  render() {
    const { news, existingImage, imgUploading, createNews } = this.props;
    return (
      <Container className="account-form">
        <Row>
          <Col className="mb-4">
            <FormLabel text="Featured Image" />
          </Col>
        </Row>
        <Row>
          <Col>
            {news && (
              <img
                src={createNews ? '' : existingImage}
                className="img-fluid mb-2"
                alt="Featured"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="photo-upload">
            <Form onSubmit={this.handleUpload}>
              <div className="form-row">
                <Col className="p-0">
                  <FormGroup>
                    <CustomInput
                      type="file"
                      id="file"
                      name="image"
                      label={this.state.label || 'Select File'}
                      multiple={false}
                      accept="image/*"
                      onChange={this.handleChange}
                      style={{ zIndex: 0 }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3} className="p-0">
                  {imgUploading ? <Uploading /> : <UploadButton />}
                </Col>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapDispatch = {
  addFeaturedImage: newsActions.addFeaturedImage
};
function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const conectedAddFeaturedImage = connect(
  mapStateToProps,
  mapDispatch
)(AddFeaturedImage);
export { conectedAddFeaturedImage as AddFeaturedImage };
