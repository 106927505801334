import { riderSexesConstants } from '../_constants';

export function riderSexes(state = {}, action) {
  switch (action.type) {
    case riderSexesConstants.RIDER_SEXES_GET_REQUEST:
      return {
        ...state
      };
    case riderSexesConstants.RIDER_SEXES_GET_SUCCESS:
      return {
        success: true,
        sexes: action.data
      };
    case riderSexesConstants.RIDER_SEXES_GET_FAILURE:
      return {
        ...state,
        success: false
      };
    default:
      return state;
  }
}
