import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { googleAnalytics } from './tracker';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadState } from './localStorage';

const loggerMiddleware = createLogger();
const persistedState = loadState();

const production = process.env.NODE_ENV === 'production';

export function configureStore() {
  const middlewares = [thunk, googleAnalytics];
  if (!production) {
    middlewares.push(loggerMiddleware);
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = production
    ? compose(middlewareEnhancer)
    : composeWithDevTools(middlewareEnhancer);

  const store = createStore(rootReducer, persistedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../_reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}
