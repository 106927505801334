import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';
import {
  FacebookInput,
  TwitterInput,
  YouTubeInput,
  InstagramInput,
  StravaInput,
  WebsiteInput
} from '../Inputs';

export const RiderSocialEdit = ({ profile, handleProfileChange }) => {
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel rider text="Social Media Links" />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <StravaInput stravaUrl={profile.stravaUrl} handleChange={handleProfileChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <YouTubeInput youTubeUrl={profile.youTubeUrl} handleChange={handleProfileChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <TwitterInput twitterUrl={profile.twitterUrl} handleChange={handleProfileChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <InstagramInput instagramUrl={profile.instagramUrl} handleChange={handleProfileChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <FacebookInput facebookUrl={profile.facebookUrl} handleChange={handleProfileChange} />
        </Col>
        <Col xs={12} sm={6} md={6} lg={4}>
          <WebsiteInput
            label="Personal Website"
            websiteUrl={profile.websiteUrl}
            handleChange={handleProfileChange}
          />
        </Col>
      </Row>
    </Container>
  );
};

RiderSocialEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  handleProfileChange: PropTypes.func.isRequired
};
