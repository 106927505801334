import { riderAgeCategoriesConstants } from '../_constants';

export function riderAgeCategories(state = {}, action) {
  switch (action.type) {
    case riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_REQUEST:
      return {
        ageCategories: state.ageCategories
      };
    case riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_SUCCESS:
      return {
        success: true,
        ageCategories: action.data.riderAgeCategories
      };
    case riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
