import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Just a LoadingCard, wrapped in a Col.
 */
export function LoadingCard() {
  return (
    <Col>
      <Card className="shadow h-100">
        <CardBody className="text-center">
          <FontAwesomeIcon icon="spinner" spin /> Loading
        </CardBody>
      </Card>
    </Col>
  );
}
