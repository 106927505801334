import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../../Shared';
import { connectionsActions } from '../../_actions';
import { RiderCard, RequestFooter } from '../../Connections';
import { PlaceholderCard } from '../../Shared';
import isEmpty from 'lodash/isEmpty';

function ConnectionRequests(props) {
  const { list } = props;
  const { requests, following } = props.connections;

  useEffect(() => {
    list();
    const interval = setInterval(list, 29000);
    return () => clearInterval(interval);
  }, [list]);

  const decline = e => {
    const { value } = e.target;
    props.decline(value);
  };

  const declineAndFollow = e => {
    props.declineAndFollow(e.target.value);
  };

  const approve = e => {
    const { value } = e.target;
    props.approve(value);
  };

  return (
    <Container className="connection-requests pb-2 mb-5">
      <Row>
        <Col>
          <FormLabel team text="Connection Requests" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          {!requests && <p>Loading...</p>}
          {isEmpty(requests) && <PlaceholderCard message="No outstanding requests" />}
          {requests &&
            Object.keys(requests).map((item, index) => (
              <RiderCard rider={requests[item]} key={index}>
                <RequestFooter
                  user={requests[item]}
                  decline={decline}
                  approve={approve}
                  declineAndFollow={declineAndFollow}
                  hideDeclineAndFollow={!!following[item]}
                  isRider
                />
              </RiderCard>
            ))}
        </Col>
      </Row>
    </Container>
  );
}
const mapDispatch = {
  list: connectionsActions.list,
  decline: connectionsActions.decline,
  declineAndFollow: connectionsActions.declineAndFollow,
  approve: connectionsActions.approve
};
function mapStateToProps(state) {
  const { connections } = state;
  return { connections };
}

const connectedConnectionRequests = connect(
  mapStateToProps,
  mapDispatch
)(ConnectionRequests);
export { connectedConnectionRequests as ConnectionRequests };
