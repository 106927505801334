import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card } from 'reactstrap';
import { MetaRow, MetaLabel, SearchCardProfilePhoto } from './';
import { dateHelper } from '../_helpers';

export const RiderSearchCard = props => {
  const { rider } = props;
  const profileUrl = `/rider/${rider.profileSlug}`;
  const dateOfBirth = rider.dateOfBirth ? dateHelper.birthdate(rider.dateOfBirth) : 'Unknown';
  return (
    <Col md={4} className="rider-search-card mb-4 ">
      <Card tag={Link} to={profileUrl} className="shadow">
        <Row className="no-gutters">
          <SearchCardProfilePhoto user={rider} />
          <Col md={7} className="rider-meta">
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="clock" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Age: ${rider.age}`}>Age: {rider.age || 'Unknown'}</span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="birthday-cake" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Born: ${dateOfBirth}`}>Born: {dateOfBirth}</span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="passport" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Nationality: ${rider.nationality || 'Unknown'}`}>
                  Nationality: {rider.nationality || 'Unknown'}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="signal" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Level: ${rider.racingLevelName || 'Unknown'}`}>
                  Level: {rider.racingLevelName || 'Unknown'}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="bicycle" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Style: ${rider.ridingStyleName || 'Unknown'}`}>
                  Style: {rider.ridingStyleName || 'Unknown'}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="trophy" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Highlights: ${rider.careerHighlights.length}`}>
                  Highlights: {rider.careerHighlights.length}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="eye" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={!!rider.outOfContract ? 'Out of Contract' : 'In Contract'}>
                  {!!rider.outOfContract ? 'Out of Contract' : 'In Contract'}
                </span>
              </Col>
            </MetaRow>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
