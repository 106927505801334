import React from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import { dateFormatter } from '../_helpers';

export const NewsSingleHeader = props => {
  const { single } = props;
  const styles = {
    background: ` linear-gradient(0deg, rgba(37, 27, 33, 0.25), rgba(37, 27, 33, 0.25)),
      url('${single.image}')`,
    backgroundSize: `cover`,
    backgroundRepeat: `none`,
    backgroundPosition: `60% 80%`,
    color: `#ddd`
  };
  return (
    <Jumbotron className="" style={styles}>
      <Container>
        <Row>
          <Col>
            <h2 className="display-4">{single.title}</h2>
            <div>
              <strong>By: </strong>
              {single.author.displayName}
              <strong> In: </strong>
              {single.category.name}
              <strong> On: </strong>
              {dateFormatter(single.datePublished)}
            </div>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
};
