export const riderPowerConstants = {
  POWER_TYPES_GET_REQUEST: 'POWER_TYPES_GET_REQUEST',
  POWER_TYPES_GET_SUCCESS: 'POWER_TYPES_GET_SUCCESS',
  POWER_TYPES_GET_FAILURE: 'POWER_TYPES_GET_FAILURE',
  TYPES_AND_LIST_REQUEST: 'TYPES_AND_LIST_REQUEST',
  TYPES_AND_LIST_SUCCESS: 'TYPES_AND_LIST_SUCCESS',
  TYPES_AND_LIST_FAILURE: 'TYPES_AND_LIST_FAILURE',
  POWER_UPDATE_REQUEST: 'POWER_UPDATE_REQUEST',
  POWER_UPDATE_SUCCESS: 'POWER_UPDATE_SUCCESS',
  POWER_UPDATE_FAILURE: 'POWER_UPDATE_FAILURE'
};
