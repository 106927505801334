export const riderCareerHighlightsConstants = {
  LIST_HIGHLGHTS_REQUEST: 'LIST_HIGHLGHTS_REQUEST',
  LIST_HIGHLGHTS_SUCCESS: 'LIST_HIGHLGHTS_SUCCESS',
  LIST_HIGHLGHTS_FAILURE: 'LIST_HIGHLGHTS_FAILURE',
  CREATE_UPDATE_CAREER_HIGHLIGHT_REQUEST: 'CREATE_UPDATE_CAREER_HIGHLIGHT_REQUEST',
  CREATE_UPDATE_CAREER_HIGHLIGHT_SUCCESS: 'CREATE_UPDATE_CAREER_HIGHLIGHT_SUCCESS',
  CREATE_UPDATE_CAREER_HIGHLIGHT_FAILURE: 'CREATE_UPDATE_CAREER_HIGHLIGHT_FAILURE',
  DELETE_CAREER_HIGHLIGHT_REQUEST: 'DELETE_CAREER_HIGHLIGHT_REQUEST',
  DELETE_CAREER_HIGHLIGHT_SUCCESS: 'DELETE_CAREER_HIGHLIGHT_SUCCESS',
  DELETE_CAREER_HIGHLIGHT_FAILURE: 'DELETE_CAREER_HIGHLIGHT_FAILURE'
};
