import React from 'react';

export const NoticeCard = props => {
  const { notice } = props;
  const { team } = notice;
  return (
    <div className="notice-card shadow">
      <div className="notice-detail">
        <strong>{team.teamName} »</strong> <em>{notice.text}</em>
      </div>
      {props.children}
    </div>
  );
};
