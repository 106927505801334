import React from 'react';
import { Col, Row, Card } from 'reactstrap';
import { FormLabel } from '../Shared';

export const PowerData = props => {
  const { power } = props;
  return (
    <Col xs={12}>
      <Col className="rider-data riding-details shadow mb-5 pb-4">
        <FormLabel rider text="Power Data" />
        <Row className="power">
          {power &&
            power.map((pow, index) => (
              <Col key={index}>
                <Card key={index}>
                  <div className="label">
                    {pow.interval} ({pow.source})
                  </div>
                  <div className="data">{pow.watts}W</div>
                </Card>
              </Col>
            ))}
        </Row>
      </Col>
    </Col>
  );
};
