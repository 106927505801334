import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card } from 'reactstrap';
import { MetaRow, MetaLabel, SearchCardProfilePhoto } from './';

export const TeamSearchCard = props => {
  const { team } = props;
  const profileUrl = `/team/${team.profileSlug}`;
  return (
    <Col md={4} className="rider-search-card mb-4 ">
      <Card tag={Link} to={profileUrl} className="shadow">
        <Row className="no-gutters">
          <SearchCardProfilePhoto user={team} />
          <Col md={7} className="rider-meta">
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="language" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Language: ${team.language || 'Unknown'}`}>
                  Language: {team.language || 'Unknown'}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="globe" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Location: ${team.country || 'Unknown'}`}>
                  Location: {team.country || 'Unknown'}
                </span>
              </Col>
            </MetaRow>

            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="signal" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Level: ${team.racingLevelName}`}>
                  Level: {team.racingLevelName}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="eye" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`Seeking Riders: ${team.seekingRiders ? 'Yes' : 'No'}`}>
                  Seeking Riders: {team.seekingRiders ? 'Yes' : 'No'}
                </span>
              </Col>
            </MetaRow>
            <MetaRow>
              <MetaLabel>
                <FontAwesomeIcon icon="bicycle" />
              </MetaLabel>
              <Col sm={10} className="value">
                <span data-hover={`On Roster: ${team.rosterCount}`}>
                  On Roster: {team.rosterCount}
                </span>
              </Col>
            </MetaRow>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
