import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';
import { ConnectionsButton } from '../Connections/';
function ConnectBanner(props) {
  const { requests, pending, user, approved, userId } = props;
  if (!user) {
    return null;
  }
  if (
    !user.isTeamManager ||
    (pending && pending[userId]) ||
    (requests && requests[userId]) ||
    (approved && approved[userId])
  ) {
    return null;
  }
  const overlay = {
    width: `100%`,
    height: `100%`,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: `15px`,
    textAlign: 'center',
    background: `rgba(255,255,255,0.4)`,
    fontWeight: 700
  };
  return (
    <Col>
      <Container className="rider-data shadow mb-5 pb-4">
        <Row>
          <Col xs={12} className="mb-4">
            <FormLabel rider text="Power Data & More" />
          </Col>

          <Col>
            <div className="card-deck" style={{ filter: `blur(10px)`, userSelect: 'none' }}>
              <div className="card">
                <div className="text-center card-body">2550W</div>
                <div className="card-footer">Five Seconds via Strava</div>
              </div>
              <div className="card">
                <div className="text-center card-body">1550W</div>
                <div className="card-footer">One Minute via Today's Plan</div>
              </div>
              <div className="card">
                <div className="text-center card-body">1050W</div>
                <div className="card-footer">Five Minutes via Strava</div>
              </div>
              <div className="card">
                <div className="text-center card-body">480W</div>
                <div className="card-footer">Threshold</div>
              </div>
            </div>
            <div className="overlay" style={overlay}>
              <div>All this and more when you are Connected with Riders.</div>
              <div>
                <ConnectionsButton color="rider" userId={userId} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

function mapStateToProps(state) {
  const {
    connections: { requests, pending, approved },
    authentication: { user }
  } = state;
  return { requests, pending, user, approved };
}

const connectedConnectBanner = connect(mapStateToProps)(ConnectBanner);
export { connectedConnectBanner as ConnectBanner };
