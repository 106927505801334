import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { FormLabel } from '../Shared';

export const Referees = props => {
  const { referees } = props;
  return (
    <Col>
      <Col xs={12} className="rider-data riding-details shadow mb-5 pb-4">
        <FormLabel rider text="Referees" />
        <Row className="referees">
          <Col xs={12}>
            {referees &&
              referees.map((ref, index) => (
                <Fragment key={index}>
                  <Row>
                    <Col sm={3}>
                      <strong>From: </strong> {ref.name}
                      <br />
                      <strong>Relationship: </strong> {ref.refereeTypeName}
                      <br />
                      <strong>Contact: </strong> {ref.contact}
                      <br />
                    </Col>
                    <Col>{ref.text}</Col>
                  </Row>
                  <hr />
                </Fragment>
              ))}
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
