export const newsConstants = {
  CREATE_NEWS_REQUEST: 'CREATE_NEWS_REQUEST',
  CREATE_NEWS_SUCCESS: 'CREATE_NEWS_SUCCESS',
  CREATE_NEWS_FAILURE: 'CREATE_NEWS_FAILURE',
  LIST_NEWS_REQUEST: 'LIST_NEWS_REQUEST',
  LIST_NEWS_SUCCESS: 'LIST_NEWS_SUCCESS',
  LIST_NEWS_FAILURE: 'LIST_NEWS_FAILURE',
  UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
  UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',
  UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
  DELETE_NEWS_REQUEST: 'DELETE_NEWS_REQUEST',
  DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',
  DELETE_NEWS_FAILURE: 'DELETE_NEWS_FAILURE',
  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',
  EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_FAILURE: 'EDIT_CATEGORY_FAILURE',
  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',
  LIST_CATEGORY_REQUEST: 'LIST_CATEGORY_REQUEST',
  LIST_CATEGORY_SUCCESS: 'LIST_CATEGORY_SUCCESS',
  LIST_CATEGORY_FAILURE: 'LIST_CATEGORY_FAILURE',
  ADD_FEATURED_IMAGE_REQUEST: 'ADD_FEATURED_IMAGE_REQUEST',
  ADD_FEATURED_IMAGE_SUCCESS: 'ADD_FEATURED_IMAGE_SUCCESS',
  ADD_FEATURED_IMAGE_FAILURE: 'ADD_FEATURED_IMAGE_FAILURE',
  NEWS_BY_SLUG_REQUEST: 'NEWS_BY_SLUG_REQUEST',
  NEWS_BY_SLUG_SUCCESS: 'NEWS_BY_SLUG_SUCCESS',
  NEWS_BY_SLUG_FAILURE: 'NEWS_BY_SLUG_FAILURE',
  LIST_FOR_USER_REQUEST: 'LIST_FOR_USER_REQUEST',
  LIST_FOR_USER_SUCCESS: 'LIST_FOR_USER_SUCCESS',
  LIST_FOR_USER_FAILURE: 'LIST_FOR_USER_FAILURE'
};
