import { authFetch, headers } from '../_helpers';

export const teamProfileService = {
  checkProfileExists,
  getProfile,
  updateProfile,
  getTeamProfile,
  uploadBannerPhoto,
  uploadProfilePhoto
};

async function checkProfileExists() {
  const response = await authFetch(`/api/TeamProfile/Profile`, headers.post());
  return response;
}

async function getTeamProfile(profileSlug) {
  const response = await authFetch(`/api/TeamProfile/Profile`, headers.postPrimitive(profileSlug));
  return response;
}

async function getProfile() {
  const response = await authFetch(`/api/TeamProfile/Index`, headers.get());
  return response;
}

async function updateProfile(data) {
  const response = await authFetch(`/api/TeamProfile/UpdateProfile`, headers.post(data));
  return response;
}

async function uploadProfilePhoto(file) {
  const response = await authFetch(`/api/TeamProfile/UpdateProfilePhoto`, headers.postFile(file));
  return response;
}

async function uploadBannerPhoto(file) {
  const response = await authFetch(`/api/TeamProfile/UpdateBannerPhoto`, headers.postFile(file));
  return response;
}
