import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function UploadButton() {
  return (
    <Button outline block color="primary" className="ml-2">
      Upload
    </Button>
  );
}

export function Uploading() {
  return (
    <Button block color="info" className="ml-2" disabled={true}>
      <FontAwesomeIcon icon="spinner" spin /> Uploading
    </Button>
  );
}
