import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Trophy = props => {
  const { highlight } = props;
  let { place } = highlight;
  let color;
  if (place === 1) {
    color = `gold`;
  } else if (place === 2) {
    color = `silver`;
  } else if (place === 3) {
    color = `#cd7f32`;
  } else {
    color = 'rgb(84, 124, 144)';
  }
  return (
    <div className="position-relative">
      <FontAwesomeIcon icon="trophy" size="3x" style={{ color }} />
      <span className="place">{place}</span>
    </div>
  );
};
