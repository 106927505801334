import { recruitConstants } from '../_constants';
import { recruitService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const recruitActions = {
  list,
  addRecruit,
  updateOrder,
  updateStage,
  emailRecruit,
  addNote,
  listNotesForRecruit
};

function list() {
  return dispatch => {
    dispatch(request());

    recruitService.list().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: recruitConstants.LIST_RECRUITS_REQUEST, data };
  }
  function success(data) {
    return { type: recruitConstants.LIST_RECRUITS_SUCCESS, data };
  }
  function failure(error) {
    return { type: recruitConstants.LIST_RECRUITS_FAILURE, error };
  }
}

function addRecruit(connectionId) {
  return dispatch => {
    dispatch(request(connectionId));
    recruitService.addRecruit(connectionId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(connectionId) {
    return { type: recruitConstants.ADD_RIDER_TO_RECRUIT_REQUEST, connectionId };
  }
  function success(data) {
    return { type: recruitConstants.ADD_RIDER_TO_RECRUIT_SUCCESS, data };
  }
  function failure(error) {
    return { type: recruitConstants.ADD_RIDER_TO_RECRUIT_FAILURE, error };
  }
}

function updateOrder(data) {
  return dispatch => {
    dispatch(request(data));
    const { draggableId, destination } = data;
    recruitService.updateOrder({ recruitId: draggableId, index: destination.index }).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: recruitConstants.UPDATE_RECRUIT_ORDER_REQUEST, data };
  }

  function success(data) {
    return { type: recruitConstants.UPDATE_RECRUIT_ORDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: recruitConstants.UPDATE_RECRUIT_ORDER_FAILURE, error };
  }
}

function updateStage(data) {
  return dispatch => {
    dispatch(request(data));
    const { finish, destination, draggableId } = data;
    recruitService
      .updateStage({ recruitId: draggableId, index: destination.index, stageId: finish.id })
      .then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
  function request(data) {
    return { type: recruitConstants.UPDATE_STAGE_REQUEST, data };
  }

  function success(data) {
    return { type: recruitConstants.UPDATE_STAGE_SUCCESS, data };
  }

  function failure(error) {
    return { type: recruitConstants.UPDATE_STAGE_FAILURE, error };
  }
}

function emailRecruit(data) {
  return dispatch => {
    dispatch(request(data));
    recruitService.emailRecruit(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Message sent!');
      },
      error => {
        dispatch(failure(error));
        toastr.error(
          'Message failed to send.',
          'Have you set a valid email for your Team Contact?'
        );
      }
    );
  };
  function request(data) {
    return { type: recruitConstants.EMAIL_RECRUIT_REQUEST, data };
  }

  function success(data) {
    return { type: recruitConstants.EMAIL_RECRUIT_SUCCESS, data };
  }

  function failure(error) {
    return { type: recruitConstants.EMAIL_RECRUIT_FAILURE, error };
  }
}

function addNote(note) {
  return dispatch => {
    dispatch(request(note));
    recruitService.addNote(note).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: recruitConstants.ADD_NOTE_REQUEST, data };
  }

  function success(data) {
    return { type: recruitConstants.ADD_NOTE_SUCCESS, data };
  }

  function failure(error) {
    return { type: recruitConstants.ADD_NOTE_FAILURE, error };
  }
}

function listNotesForRecruit(recruitId) {
  return dispatch => {
    dispatch(request(recruitId));
    recruitService.listNotesForRecruit(recruitId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: recruitConstants.LIST_NOTES_REQUEST, data };
  }

  function success(data) {
    return { type: recruitConstants.LIST_NOTES_SUCCESS, data };
  }

  function failure(error) {
    return { type: recruitConstants.LIST_NOTES_FAILURE, error };
  }
}
