import React from 'react';
import PropTypes from 'prop-types';

export const AccountFormHeader = props => (
  <div className="form-title text-center d-flex justify-content-center">
    <h5 className="shadow-lg rounded">{props.headerText}</h5>
  </div>
);
AccountFormHeader.propTypes = {
  headerText: PropTypes.string.isRequired
};
