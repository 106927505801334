import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';
import { riderPowerActions } from '../_actions';

class RiderPowerEditor extends React.Component {
  state = {};

  componentDidMount = () => {
    this.props.getTypesAndList();
  };

  handleChange = (e, id) => {
    const { name, value } = e.target;
    const item = { ...this.state[id] };
    item[name] = value;
    this.setState({ [id]: item });
  };

  handleSubmit = (e, id) => {
    e.preventDefault();
    const power = this.props.riderPower.data.find(i => i.id === id);
    const updatedPower = { ...power, ...this.state[id], powerTypeId: id };
    this.props.update(updatedPower);
  };

  render() {
    const { riderPower } = this.props;
    let powerEntry;
    if (riderPower && riderPower.types) {
      powerEntry = riderPower.types.map((type, index) => {
        const existing = riderPower.data.find(i => i.powerTypeId === type.id);
        return (
          <Form key={index} onSubmit={e => this.handleSubmit(e, type.id)} className="mb-3">
            <Row>
              <Col xs={12} md={3}>
                <div>{type.interval}</div>
              </Col>
              <Col xs={4} md={3}>
                <Input
                  name="watts"
                  type="number"
                  onChange={e => this.handleChange(e, type.id)}
                  defaultValue={existing ? existing.watts : ''}
                  required
                />
              </Col>
              <Col xs={5} md={4} lg={5}>
                <Input
                  name="source"
                  onChange={e => this.handleChange(e, type.id)}
                  defaultValue={existing ? existing.source : ''}
                />
              </Col>
              <Col xs={3} md={2} lg={1}>
                <Button outline color="primary">
                  <FontAwesomeIcon icon="check" />
                </Button>
              </Col>
            </Row>
          </Form>
        );
      });
    }

    return (
      <Container className="account-form">
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel rider text="Power Data" />
          </Col>
          <Col xs={3}>
            <FormGroup>
              <Label>Interval</Label>
            </FormGroup>
          </Col>
          <Col xs={3}>
            <FormGroup>
              <Label>Watts</Label>
            </FormGroup>
          </Col>
          <Col xs={3} md={4} lg={5}>
            <FormGroup>
              <Label>Source</Label>
            </FormGroup>
          </Col>
          <Col xs={3} md={2} lg={1}>
            <FormGroup>
              <Label>Save</Label>
            </FormGroup>
          </Col>
        </Row>
        {powerEntry}
      </Container>
    );
  }
}
const mapDispatch = {
  getTypesAndList: riderPowerActions.getTypesAndList,
  update: riderPowerActions.update
};
function mapStateToProps(state) {
  const { riderPower } = state;
  return { riderPower };
}

const connectedRiderPowerEditor = connect(
  mapStateToProps,
  mapDispatch
)(RiderPowerEditor);
export { connectedRiderPowerEditor as RiderPowerEditor };
