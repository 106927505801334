import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { SectionHeader } from '../Shared';
export const Unauthorized = () => {
  return (
    <Container fluid>
      <Row className="account-container">
        <Col className="account-form" xs={12} sm={{ size: 6, offset: 3 }}>
          <SectionHeader titleText="Not Authorised" />
          <h4>Not Authorised</h4>
          <p className="lead">You do not have permission to view this page.</p>
        </Col>
      </Row>
    </Container>
  );
};
