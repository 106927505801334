import {
  faMapMarkerAlt,
  faCogs,
  faBicycle,
  faClock,
  faCheckCircle,
  faLanguage,
  faBirthdayCake,
  faGlobe,
  faRulerVertical,
  faWeight,
  faUsers,
  faMale,
  faFemale,
  faEye,
  faEyeSlash,
  faHandsHelping,
  faQuestionCircle,
  faSignal,
  faTrophy,
  faPassport,
  faPlusCircle,
  faPenAlt,
  faFolderOpen,
  faEnvelope,
  faUser,
  faSpinner,
  faCircle,
  faTimesCircle,
  faBell,
  faChalkboardTeacher,
  faPlus,
  faMinus,
  faCheck,
  faTimes,
  faBullhorn,
  faUndo,
  faSearchMinus,
  faSearchPlus,
  faChevronDown,
  faHome,
  faMicrophone
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faNewspaper, faSave } from '@fortawesome/free-regular-svg-icons';
import {
  faStrava,
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

export const iconSet = [
  faMapMarkerAlt,
  faBicycle,
  faSearchMinus,
  faSearchPlus,
  faChevronDown,
  faHome,
  faMicrophone,
  faSave,
  faBullhorn,
  faUndo,
  faNewspaper,
  faBell,
  faTimes,
  faClock,
  faCheckCircle,
  faStrava,
  faCogs,
  faCheck,
  faLanguage,
  faBirthdayCake,
  faGlobe,
  faRulerVertical,
  faWeight,
  faMale,
  faFemale,
  faEye,
  faEyeSlash,
  faFacebook,
  faYoutube,
  faInstagram,
  faTwitter,
  faHandsHelping,
  faQuestionCircle,
  faSignal,
  faTrophy,
  faPassport,
  faPlusCircle,
  faPlus,
  faPenAlt,
  faUsers,
  faFolderOpen,
  faEnvelope,
  faUser,
  faSpinner,
  faCircle,
  farCircle,
  faTimesCircle,
  faMinus,
  faChalkboardTeacher
];
