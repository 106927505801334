import { teamProfileService } from '../_services';
import { teamProfileConstants, myProfileConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const teamProfileActions = {
  getProfile,
  updateProfile,
  getTeamProfile,
  uploadBannerPhoto,
  uploadProfilePhoto
};

function getProfile() {
  return dispatch => {
    dispatch(request());

    teamProfileService.getProfile().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: myProfileConstants.PROFILE_VIEW_REQUEST, data };
  }
  function success(data) {
    return { type: myProfileConstants.PROFILE_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: myProfileConstants.PROFILE_VIEW_FAILURE, error };
  }
}

function getTeamProfile(profileSlug) {
  return dispatch => {
    dispatch(request());

    teamProfileService.getTeamProfile(profileSlug).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: teamProfileConstants.TEAM_PROFILE_VIEW_REQUEST, data };
  }
  function success(data) {
    return { type: teamProfileConstants.TEAM_PROFILE_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamProfileConstants.TEAM_PROFILE_VIEW_FAILURE, error };
  }
}

function updateProfile(data) {
  return dispatch => {
    dispatch(request(data));
    teamProfileService.updateProfile(data).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile changes saved!');
      },
      error => {
        toastr.error('There was an error updating your profile.');
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: myProfileConstants.PROFILE_UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: myProfileConstants.PROFILE_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: myProfileConstants.PROFILE_UPDATE_FAILURE, error };
  }
}

function uploadProfilePhoto(data) {
  return dispatch => {
    dispatch(request(data));
    teamProfileService.uploadProfilePhoto(data).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile changes saved!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: myProfileConstants.PROFILE_UPLOAD_IMAGE_FAILURE,
      error
    };
  }
}

function uploadBannerPhoto(data) {
  return dispatch => {
    dispatch(request(data));
    teamProfileService.uploadBannerPhoto(data).then(
      profile => {
        dispatch(success(profile));
        toastr.success('Profile changes saved!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return {
      type: teamProfileConstants.TEAM_PROFILE_UPLOAD_PHOTO_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: teamProfileConstants.TEAM_PROFILE_UPLOAD_PHOTO_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: teamProfileConstants.TEAM_PROFILE_UPLOAD_PHOTO_FAILURE,
      error
    };
  }
}
