export const riderRefereeConstants = {
  CREATE_REFEREE_REQUEST: ' CREATE_REFEREE_REQUEST',
  CREATE_REFEREE_SUCCESS: ' CREATE_REFEREE_SUCCESS',
  CREATE_REFEREE_FAILURE: ' CREATE_REFEREE_FAILURE',
  LIST_REFEREES_REQUEST: 'LIST_REFEREES_REQUEST',
  LIST_REFEREES_SUCCESS: 'LIST_REFEREES_SUCCESS',
  LIST_REFEREES_FAILURE: 'LIST_REFEREES_FAILURE',
  UPDATE_REFEREE_REQUEST: 'UPDATE_REFEREE_REQUEST',
  UPDATE_REFEREE_FAILURE: 'UPDATE_REFEREE_FAILURE',
  UPDATE_REFEREE_SUCCESS: 'UPDATE_REFEREE_SUCCESS',
  DELETE_REFEREE_REQUEST: 'DELETE_REFEREE_REQUEST',
  DELETE_REFEREE_SUCCESS: 'DELETE_REFEREE_SUCCESS',
  DELETE_REFEREE_FAILURE: 'DELETE_REFEREE_FAILURE'
};
