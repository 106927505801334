import React from 'react';
import { Form, Row, Col, FormGroup, Input, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BlankRaceResultForm = () => {
  return (
    <Col style={{ opacity: 0.5 }}>
      <h5>Select a Race to Manage Results </h5>
      <Form onSubmit={e => e.preventDefault()}>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Label for="place">Place</Label>
              <Input />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="stage">Stage</Label>
              <Input />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <Label for="riderName">Rider</Label>
              <Input />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="add">Add</Label>
              <Button outline block color="primary">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};
