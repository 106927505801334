import React from 'react';
import { Alert, Button } from 'reactstrap';

export function UnsavedChanges(props) {
  return (
    <Alert color="warning">
      It looks like you have unsaved profile changes
      <Button color="primary" onClick={props.handleSubmit}>
        Save Now
      </Button>
    </Alert>
  );
}
