import { useState } from 'react';
import { validation } from '../_helpers';

export function useRegistration() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [newsletterOptIn, setNewsletterOptIn] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(null);

  const validatePassword = value => {
    const valid = validation.password(value);
    setPasswordValid(valid);
  };

  const validateEmail = value => {
    const valid = validation.email(value);
    setEmailValid(valid);
  };

  return {
    firstName,
    lastName,
    newsletterOptIn,
    email,
    emailValid,
    password,
    passwordValid,
    setFirstName,
    setLastName,
    setNewsletterOptIn,
    setEmail,
    validateEmail,
    setPassword,
    validatePassword
  };
}
