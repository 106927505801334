import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const InstagramInput = props => (
  <FormGroup>
    <Label for="instagramUrl">Instagram</Label>
    <Input
      name="instagramUrl"
      id="instagramUrl"
      defaultValue={props.instagramUrl}
      onChange={props.handleChange}
    />
  </FormGroup>
);
InstagramInput.propTypes = {
  instagramUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
