import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormGroup,
  Button,
  ButtonGroup,
  Label
} from 'reactstrap';
import { RacingLevelMultiSelect, TeamSearchResults } from './';
import { LocationFinder } from '../Inputs';
import { racingLevelsActions, teamSearchActions } from '../_actions';

class TeamSearchForm extends React.Component {
  state = {};
  componentDidMount = () => {
    this.props.list();
  };

  radioButtonChange = seekingRiders => {
    this.setState({ seekingRiders });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  multiSelectChange = e => {
    const { name, value } = e.target;
    const ids = value.map(val => val.value);
    this.setState({ [name]: ids });
  };

  handleSubmit = e => {
    e.preventDefault();
    const terms = { ...this.state };
    this.props.search(terms);
  };

  pageChange = page => {
    const terms = { ...this.state, page: page - 1 };
    this.props.search(terms);
  };

  render() {
    const { levels } = this.props;
    return (
      <Fragment>
        <Form className="search-form" onSubmit={this.handleSubmit}>
          <Container>
            <Row>
              <Col xs={12} sm={10}>
                <FormGroup>
                  <LocationFinder handleChange={this.handleChange} location="Anywhere" />
                </FormGroup>
              </Col>
              {levels && (
                <Col xs={12} sm={5}>
                  <RacingLevelMultiSelect levels={levels} handleChange={this.multiSelectChange} />
                </Col>
              )}
              <Col sm={3}>
                <FormGroup>
                  <Label>Recruiting</Label>
                  <InputGroup>
                    <ButtonGroup>
                      <Button
                        color="primary"
                        onClick={() => this.radioButtonChange(true)}
                        outline
                        active={this.state.seekingRiders || false}
                      >
                        Yes
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => this.radioButtonChange(false)}
                        outline
                        active={!this.state.seekingRiders || false}
                      >
                        Don't Care
                      </Button>
                    </ButtonGroup>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button outline block color="primary" className="search-submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        <TeamSearchResults pageChange={this.pageChange} />
      </Fragment>
    );
  }
}
const mapDispatch = {
  list: racingLevelsActions.list,
  search: teamSearchActions.search
};
function mapStateToProps(state) {
  const {
    racingLevels: { levels = null }
  } = state;
  return { levels };
}

const connectedTeamSearchForm = connect(
  mapStateToProps,
  mapDispatch
)(TeamSearchForm);
export { connectedTeamSearchForm as TeamSearchForm };
