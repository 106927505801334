import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Geosuggest from 'react-geosuggest';

export class LocationFinder extends React.Component {
  clearInput = () => {
    this._geoSuggest.clear();
  };

  handleLocationSelect = data => {
    try {
      const componentForm = {
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'long_name'
      };
      const stateLabel = {
        locality: 'city',
        administrative_area_level_1: 'state',
        country: 'country'
      };
      for (let i = 0; i < data.gmaps.address_components.length; i++) {
        let addressType = data.gmaps.address_components[i].types[0];
        let addressComponent = stateLabel[addressType];
        if (componentForm[addressType]) {
          let addressValue = data.gmaps.address_components[i][componentForm[addressType]];
          this.props.handleChange({ target: { name: addressComponent, value: addressValue } });
        }
      }
    } catch (e) {
      // fail silently
    }
  };

  render() {
    return (
      <FormGroup>
        <Label for="location">Location</Label>
        <Geosuggest
          ref={el => (this._geoSuggest = el)}
          className="form-group"
          inputClassName="form-control"
          types={['(regions)']}
          minLength={3}
          placeholder={this.props.location}
          id="location"
          name="location"
          onChange={this.props.eagerCountryChange}
          onSuggestSelect={this.handleLocationSelect}
        />
      </FormGroup>
    );
  }
}
LocationFinder.propTypes = {
  location: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
