import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { multiSelectStyles } from './MultiSelectStyles';

export const RacingLevelMultiSelect = props => {
  const { levels = [], handleChange } = props;

  const levelsOptions = levels.map(level => {
    return { value: level.racingLevelId, label: level.racingLevelName };
  });

  return (
    <FormGroup>
      <Label>Racing Level</Label>
      <Select
        isMulti
        styles={multiSelectStyles}
        options={levelsOptions}
        name="racingLevelId"
        onChange={val => {
          handleChange({
            target: { name: 'racingLevelId', value: val }
          });
        }}
      />
    </FormGroup>
  );
};
RacingLevelMultiSelect.propTypes = {
  levels: PropTypes.array.isRequired
};
