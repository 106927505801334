import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class RiderCard extends React.Component {
  render() {
    const { rider, dragId } = this.props;
    return (
      // the dragId passed down is the PK of the Recruit DB record.
      <Draggable draggableId={dragId.toString()} index={this.props.index}>
        {(provided, snapshot) => (
          <div
            className={`recruit-container shadow mb-3 ${snapshot.isDragging ? 'is-dragging' : ''}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="recruit">
              <Row>
                <Col sm={3}>
                  {rider.profilePhoto ? (
                    <img src={rider.profilePhoto} alt="placeholder" className="img-fluid" />
                  ) : (
                    <div className="align-middle text-center mx-auto placeholder">
                      <FontAwesomeIcon icon="user" className="img-fluid" size="5x" />
                    </div>
                  )}
                </Col>
                <Col>
                  {rider.firstName} {rider.lastName}
                </Col>
              </Row>
            </div>
            {this.props.children}
          </div>
        )}
      </Draggable>
    );
  }
}
