export const connectionsConstants = {
  FOLLOW_REQUEST: 'FOLLOW_REQUEST',
  FOLLOW_SUCCESS: 'FOLLOW_SUCCESS',
  FOLLOW_FAILURE: 'FOLLOW_FAILURE',
  UNFOLLOW_REQUEST: 'UNFOLLOW_REQUEST',
  UNFOLLOW_SUCCESS: 'UNFOLLOW_SUCCESS',
  UNFOLLOW_FAILURE: 'UNFOLLOW_FAILURE',
  FOLLOW_GET_LIST_REQUEST: 'FOLLOW_GET_LIST_REQUEST',
  FOLLOW_GET_LIST_SUCCESS: 'FOLLOW_GET_LIST_SUCCESS',
  FOLLOW_GET_LIST_FAILURE: 'FOLLOW_GET_LIST_FAILURE',
  CONNECTIONS_CREATE_REQUEST: 'CONNECTIONS_CREATE_REQUEST',
  CONNECTIONS_CREATE_SUCCESS: 'CONNECTIONS_CREATE_SUCCESS',
  CONNECTIONS_CREATE_FAILURE: 'CONNECTIONS_CREATE_FAILURE',
  CONNECTIONS_CANCEL_REQUEST: 'CONNECTIONS_CANCEL_REQUEST',
  CONNECTIONS_CANCEL_SUCCESS: 'CONNECTIONS_CANCEL_SUCCESS',
  CONNECTIONS_CANCEL_FAILURE: 'CONNECTIONS_CANCEL_FAILURE',
  CONNECTIONS_APPROVE_REQUEST: 'CONNECTIONS_APPROVE_REQUEST',
  CONNECTIONS_APPROVE_SUCCESS: 'CONNECTIONS_APPROVE_SUCCESS',
  CONNECTIONS_APPROVE_FAILURE: 'CONNECTIONS_APPROVE_FAILURE',
  CONNECTIONS_DECLINE_REQUEST: 'CONNECTIONS_DECLINE_REQUEST',
  CONNECTIONS_DECLINE_SUCCESS: 'CONNECTIONS_DECLINE_SUCCESS',
  CONNECTIONS_DECLINE_FAILURE: 'CONNECTIONS_DECLINE_FAILURE',
  CONNECTIONS_DECLINE_FOLLOW_REQUEST: 'CONNECTIONS_DECLINE_FOLLOW_REQUEST',
  CONNECTIONS_DECLINE_FOLLOW_SUCCESS: 'CONNECTIONS_DECLINE_FOLLOW_SUCCESS',
  CONNECTIONS_DECLINE_FOLLOW_FAILURE: 'CONNECTIONS_DECLINE_FOLLOW_FAILURE',
  CONNECTIONS_LIST_REQUEST: 'CONNECTIONS_LIST_REQUEST',
  CONNECTIONS_LIST_SUCCESS: 'CONNECTIONS_LIST_SUCCESS',
  CONNECTIONS_LIST_FAILURE: 'CONNECTIONS_LIST_FAILURE',
  REMOVE_CONNECTION_REQUEST: 'REMOVE_CONNECTION_REQUEST',
  REMOVE_CONNECTION_SUCCESS: 'REMOVE_CONNECTION_SUCCESS',
  REMOVE_CONNECTION_FAILURE: 'REMOVE_CONNECTION_FAILURE',
  SET_REMOVING_CONNECTION: 'SET_REMOVING_CONNECTION'
};
