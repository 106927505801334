import { registerConstants } from '../_constants';

const initialState = {
  registering: false,
  success: false,
  failure: false,
  error: ''
};

export function register(state = initialState, action) {
  switch (action.type) {
    case registerConstants.REGISTER_REQUEST:
      return {
        ...state,
        registering: true,
        failure: false
      };
    case registerConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        success: true
      };
    case registerConstants.REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
        success: false,
        failure: true,
        errors: action.error
      };
    default:
      return state;
  }
}
