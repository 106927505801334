import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { NoteCard } from '.';
import { recruitActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';

class NotesModal extends React.Component {
  state = { note: '' };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSave = recruitId => {
    const { note } = this.state;
    if (note && note.length) {
      this.props.addNote({ recruitId, note });
    }
    this.setState({ note: '' });
  };

  handleCancel = () => {
    this.setState({ note: '' });
    this.props.toggle();
  };

  render() {
    const { notes } = this.props.recruit;
    const { isOpen, toggle, recruitName, recruitId } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Notes for {recruitName}</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="note"
                    onChange={this.handleChange}
                    value={this.state.note}
                    placeholder="Your note"
                  />
                </FormGroup>
                <Button color="primary" onClick={() => this.handleSave(recruitId)}>
                  Save
                </Button>{' '}
                <Button color="secondary" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <hr />
          </Container>
          <Container>
            <Row>
              <Col>
                {!isEmpty(notes) && !isEmpty(notes[recruitId]) ? (
                  notes[recruitId].map((note, index) => <NoteCard key={index} note={note} />)
                ) : (
                  <p>No notes</p>
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  const { recruit } = state;
  return { recruit };
}

const connectedNotesModal = connect(
  mapStateToProps,
  { addNote: recruitActions.addNote }
)(NotesModal);
export { connectedNotesModal as NotesModal };
