import { adminConstants } from '../_constants';
import { adminService } from '../_services';

export const adminActions = {
  listAdmins,
  userLookup,
  makeUserAdmin,
  revokeAdmin,
  getSettings,
  updateSettings
};

function listAdmins() {
  return dispatch => {
    dispatch(request());
    adminService.listAdmins().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: adminConstants.LIST_ADMINS_REQUEST, data };
  }
  function success(data) {
    return { type: adminConstants.LIST_ADMINS_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.LIST_ADMINS_FAILURE, error };
  }
}

function userLookup(terms) {
  return dispatch => {
    dispatch(request(terms));
    adminService.userLookup(terms).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: adminConstants.USER_LOOKUP_REQUEST, data };
  }
  function success(data) {
    return { type: adminConstants.USER_LOOKUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.USER_LOOKUP_FAILURE, error };
  }
}

function makeUserAdmin(userId) {
  return dispatch => {
    dispatch(request(userId));
    adminService.makeUserAdmin(userId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(userId) {
    return { type: adminConstants.MAKE_USER_ADMIN_REQUEST, userId };
  }
  function success(data) {
    return { type: adminConstants.MAKE_USER_ADMIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.MAKE_USER_ADMIN_FAILURE, error };
  }
}

function revokeAdmin(userId) {
  return dispatch => {
    dispatch(request(userId));
    adminService.revokeAdmin(userId).then(
      data => {
        dispatch(success(userId));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(userId) {
    return { type: adminConstants.REVOKE_USER_ADMIN_REQUEST, userId };
  }
  function success(data) {
    return { type: adminConstants.REVOKE_USER_ADMIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.REVOKE_USER_ADMIN_FAILURE, error };
  }
}

function getSettings() {
  return dispatch => {
    dispatch(request());
    adminService.getSettings().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: adminConstants.GET_SITE_SETTINGS_REQUEST, data };
  }
  function success(data) {
    return { type: adminConstants.GET_SITE_SETTINGS_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.GET_SITE_SETTINGS_FAILURE, error };
  }
}

function updateSettings(settings) {
  return dispatch => {
    dispatch(request(settings));
    adminService.updateSettings(settings).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: adminConstants.UPDATE_SITE_SETTINGS_REQUEST, data };
  }
  function success(data) {
    return { type: adminConstants.UPDATE_SITE_SETTINGS_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.UPDATE_SITE_SETTINGS_FAILURE, error };
  }
}
