import { refereeTypesConstants } from '../_constants';
import { refereeTypesService } from '../_services';

export const refereeTypesActions = {
  getRefereeTypes
};

function getRefereeTypes() {
  return dispatch => {
    dispatch(request());

    refereeTypesService.getRefereeTypes().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: refereeTypesConstants.REFEREE_TYPES_GET_REQUEST, data };
  }
  function success(data) {
    return { type: refereeTypesConstants.REFEREE_TYPES_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: refereeTypesConstants.REFEREE_TYPES_GET_FAILURE, error };
  }
}
