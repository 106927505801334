import { riderCareerHighlightsService } from '../_services';
import { riderCareerHighlightsConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const riderCareerHighlightsActions = {
  listCareerHighlights,
  deleteCareerHighlight,
  createUpdateCareerHighlight
};

function listCareerHighlights() {
  return dispatch => {
    dispatch(request());
    riderCareerHighlightsService.listCareerHighlights().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderCareerHighlightsConstants.LIST_HIGHLGHTS_REQUEST, data };
  }
  function success(data) {
    return { type: riderCareerHighlightsConstants.LIST_HIGHLGHTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderCareerHighlightsConstants.LIST_HIGHLGHTS_FAILURE, error };
  }
}

function deleteCareerHighlight(highlightId) {
  return dispatch => {
    dispatch(request(highlightId));
    riderCareerHighlightsService.deleteCareerHighlight(highlightId).then(
      data => {
        dispatch(success(data));
        toastr.success('Career highlight removed');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(highlightId) {
    return { type: riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_REQUEST, highlightId };
  }
  function success(data) {
    return { type: riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_FAILURE, error };
  }
}

function createUpdateCareerHighlight(careerHighlight) {
  return dispatch => {
    dispatch(request(careerHighlight));

    riderCareerHighlightsService.createUpdateCareerHighlight(careerHighlight).then(
      data => {
        dispatch(success(data));
        toastr.success('Career highlight recorded!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return {
      type: riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_FAILURE,
      error
    };
  }
}
