import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-scroll';
import { Link as RRLink } from 'react-router-dom';
import { Jumbotron, Col, Container, Row, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { ForRiders, ForTeams, SignUpBanner } from './';

export const Home = () => {
  const { user } = useSelector(state => state.authentication);
  return (
    <Fragment>
      <Helmet title="Welcome to Cycling Connect" />
      <Jumbotron fluid className="header-banner">
        <Container>
          <Row>
            <Col md={9}>
              <h1 className="display-4">Cycling Connect</h1>
              <p className="lead">
                Closing the gap between Riders and Teams one connection at a time.
              </p>
            </Col>
            {!user && (
              <Col className="cta">
                <Button tag={RRLink} to="/register" color="success" size="lg">
                  Join Now
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Jumbotron>
      <div className="two-sides">
        <Link to="riders" smooth spy duration={350}>
          <div className="for-riders">
            <span>
              For
              <br />
              Riders
            </span>
          </div>
        </Link>
        <Link to="teams" smooth spy duration={350}>
          <div className="for-teams">
            <span>
              For
              <br />
              Teams
            </span>
          </div>
        </Link>
      </div>
      <ForRiders />
      <hr />
      <ForTeams />
      {!user && <SignUpBanner />}
    </Fragment>
  );
};
