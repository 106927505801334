import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { SuggestedRider } from './';
import { dashboardActions } from '../../_actions';
import { PlaceholderCard, FormLabel } from '../../Shared';

function SuggestedConnections(props) {
  const { suggestedConnections, suggested } = props;
  useEffect(() => {
    suggestedConnections();
    const interval = setInterval(suggestedConnections, 37000);
    return () => clearInterval(interval);
  }, [suggestedConnections]);

  return (
    <Container className="suggested-connections">
      <Row>
        <Col>
          <FormLabel team text="Suggested Riders" />
        </Col>
      </Row>

      {!suggested && (
        <PlaceholderCard>
          <p>
            <FontAwesomeIcon icon="spinner" spin /> Loading
          </p>
        </PlaceholderCard>
      )}
      {suggested && isEmpty(suggested) && (
        <PlaceholderCard message="No Suggested Connections to Show" />
      )}
      {suggested && (
        <Row className="pb-3 mt-4">
          <Col xs={12} className="list">
            {suggested.map((item, index) => (
              <SuggestedRider key={index} rider={item} />
            ))}
          </Col>
        </Row>
      )}
    </Container>
  );
}

const mapDispatch = {
  suggestedConnections: dashboardActions.suggestedConnections
};
function mapStateToProps(state) {
  const { suggested } = state.dashboard;
  return { suggested };
}

const connectedSuggestedConnections = connect(
  mapStateToProps,
  mapDispatch
)(SuggestedConnections);
export { connectedSuggestedConnections as SuggestedConnections };
