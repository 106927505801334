import { articleConstants } from '../_constants';
const initialState = {
  loading: false,
  addedCategory: false,
  list: [],
  categories: []
};
export function article(state = initialState, action) {
  switch (action.type) {
    case articleConstants.LIST_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case articleConstants.LIST_ARTICLES_SUCCESS:
      return {
        ...state,
        list: action.data,
        loading: false
      };
    case articleConstants.LIST_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case articleConstants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.data
      };
    case articleConstants.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.data],
        addedCategory: true
      };
    case articleConstants.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        addedCategory: false
      };
    case articleConstants.CREATE_CATEGORY_FAILURE:
    case articleConstants.GET_CATEGORIES_REQUEST:
    case articleConstants.GET_CATEGORIES_FAILURE:
    case articleConstants.CREATE_ARTICLE_REQUEST:
    case articleConstants.CREATE_ARTICLE_SUCCESS:
    case articleConstants.CREATE_ARTICLE_FAILURE:
    case articleConstants.EDIT_ARTICLE_REQUEST:
    case articleConstants.EDIT_ARTICLE_SUCCESS:
    case articleConstants.EDIT_ARTICLE_FAILURE:
    default:
      return state;
  }
}
