import { headers, authFetch } from '../_helpers';

export const teamSearchService = {
  search
};

async function search(terms) {
  const response = await authFetch(`/api/TeamSearch`, headers.post(terms));
  return response;
}
