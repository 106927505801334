import { headers, authFetch } from '../_helpers';

export const riderCareerHistoryService = {
  listCareerHistory,
  createUpdateCareerHistory,
  deleteCareerHistory
};

async function listCareerHistory() {
  const response = await authFetch(`/api/RiderProfile/ListCareerHistory`, headers.post());
  return response;
}

async function createUpdateCareerHistory(careerHistory) {
  const response = await authFetch(
    `/api/RiderProfile/CreateUpdateCareerHistory`,
    headers.post(careerHistory)
  );
  return response;
}

async function deleteCareerHistory(historyId) {
  const response = await authFetch(
    `/api/RiderProfile/DeleteCareerHistory`,
    headers.postPrimitive(historyId)
  );
  return response;
}
