import { riderAgeCategoriesConstants } from '../_constants';
import { riderAgeCategoriesService } from '../_services';

export const riderAgeCategoriesActions = {
  getRiderAgeCategories
};

function getRiderAgeCategories() {
  return dispatch => {
    dispatch(request());

    riderAgeCategoriesService.getRiderAgeCategories().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return {
      type: riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_REQUEST,
      data
    };
  }
  function success(data) {
    return {
      type: riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_SUCCESS,
      data
    };
  }
  function failure(error) {
    return {
      type: riderAgeCategoriesConstants.RIDER_AGE_CATEGORIES_FAILURE,
      error
    };
  }
}
