export const myProfileConstants = {
  PROFILE_VIEW_REQUEST: 'PROFILE_VIEW_REQUEST',
  PROFILE_VIEW_SUCCESS: 'PROFILE_VIEW_SUCCESS',
  PROFILE_VIEW_FAILURE: 'PROFILE_VIEW_FAILURE',
  PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',
  PROFILE_UPLOAD_IMAGE_REQUEST: 'PROFILE_UPLOAD_IMAGE_REQUEST',
  PROFILE_UPLOAD_IMAGE_SUCCESS: 'PROFILE_UPLOAD_IMAGE_SUCCESS',
  PROFILE_UPLOAD_IMAGE_FAILURE: 'PROFILE_UPLOAD_IMAGE_FAILURE'
};
