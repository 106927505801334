import React from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';

const styles = {
  background: `linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)), url('/img/banners/notices.jpg')`,
  backgroundSize: 'cover',
  backgroundPosition: '60% 30%',
  color: '#dfdfdf'
};

export const NoticeHeader = () => (
  <Jumbotron style={styles} className="mb-5">
    <Container>
      <Row>
        <Col>
          <h2 className="display-4">Notices</h2>
        </Col>
      </Row>
    </Container>
  </Jumbotron>
);
