import { headers, authFetch, handleResponse } from '../_helpers';

export const accountService = {
  get,
  save,
  register,
  forgotPassword,
  resetPassword,
  confirmEmail,
  mailingListStatus,
  toggleMailingList,
  deleteFromMailingList
};

const base = `/api/Account`;

async function get() {
  const res = await authFetch(`${base}/GetAccount`, headers.get());
  return res;
}

async function save(data) {
  const res = await authFetch(`${base}/Update`, headers.post(data));
  return res;
}

async function register(model) {
  const response = await fetch(`${base}/Register`, headers.post(model));
  const data = await handleResponse(response);
  return data;
}

async function forgotPassword(email) {
  const res = await fetch(`${base}/ForgotPassword`, headers.post({ email }));
  const response = await handleResponse(res);
  return response;
}

async function resetPassword(data) {
  const response = await fetch(`${base}/ResetPassword`, headers.post(data));
  const d = await handleResponse(response);
  return d;
}

async function confirmEmail(model) {
  const response = await fetch(`${base}/ValidateEmail`, headers.post(model));
  const res = await handleResponse(response);
  return res;
}

async function mailingListStatus() {
  const response = await authFetch(`${base}/MailingListStatus`, headers.get());
  return response;
}

async function toggleMailingList() {
  const response = await authFetch(`${base}/ToggleMailingListStatus`, headers.post());
  return response;
}

async function deleteFromMailingList() {
  const response = await authFetch(`${base}/DeletetFromMailingList`, headers.post());
  return response;
}
