import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../../Shared';
import { recruitActions } from '../../_actions';
import isEmpty from 'lodash/isEmpty';

function RecruitmentOverview(props) {
  const { list, stages } = props;
  useEffect(() => {
    list();
    const interval = setInterval(list, 47000);
    return () => clearInterval(interval);
  }, [list]);

  return (
    <Container className="recruitment-overview mt-5">
      <Row>
        <Col xs={12}>
          <FormLabel team text="Recruitment Overview" />
        </Col>
      </Row>
      <Row className="mt-4">
        {!isEmpty(stages) &&
          Object.keys(stages)
            .filter(stage => stages[stage].name !== 'Connections')
            .map((stage, index) => (
              <Col tag={Link} to="/recruit" sm={12} key={index}>
                <div className="stage-card shadow d-flex">
                  <span className="stage-name flex-grow-1">{stages[stage].name}</span>
                  <span className="rider-count">{stages[stage].recruitIdsInStage.length}</span>
                </div>
              </Col>
            ))}
      </Row>
    </Container>
  );
}

const mapDispatch = {
  list: recruitActions.list
};

function mapStateToProps(state) {
  const { stages } = state.recruit;
  return { stages };
}
const connectedRecruitmentOverview = connect(
  mapStateToProps,
  mapDispatch
)(RecruitmentOverview);
export { connectedRecruitmentOverview as RecruitmentOverview };
