import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Input, Button, Form, Label } from 'reactstrap';
import { FormLabel } from '../../Shared';
import { noticeActions, riderStylesActions } from '../../_actions';
import { RiderStyleSelect } from '../../Inputs';
import { weekFromNow } from '../../_helpers';
import DatePicker from 'react-date-picker';

const defaultState = {
  text: '',
  region: '',
  riderStyleId: 0,
  dateExpires: weekFromNow(),
  charCount: 0,
  invalid: true
};

class AddNotice extends React.Component {
  state = { ...defaultState };
  componentDidMount = () => {
    this.props.getRiderStyles();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { text, region, dateExpires, riderStyleId } = this.state;
    if (text && text.length && text.length <= 100) {
      this.props.create({ text, region, dateExpires, riderStyleId });
    }
    this.setState({ ...defaultState });
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'text') {
      const tooShort = value.length <= 0;
      const tooLong = value.length >= 100;
      this.setState({
        charCount: value.length,
        invalid: tooShort || tooLong
      });
    }
    this.setState({ [name]: value });
  };

  render() {
    const { styles } = this.props;
    const charCountStyle = {
      position: `absolute`,
      bottom: `4px`,
      right: `16px`,
      color: `darkgray`,
      fontSize: `0.8rem`
    };
    return (
      <Container className="add-notice mb-5 grey-bg">
        <Row>
          <Col>
            <FormLabel team text="Add Notice" />
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit} className="pb-3 mt-4">
          <Row>
            <Col xs={12} className="position-relative">
              <Input
                name="text"
                type="textarea"
                onChange={this.handleChange}
                value={this.state.text}
                invalid={this.state.charCount > 100}
                placeholder="Brief description"
                className="mb-4"
              />
              <div style={charCountStyle}>{this.state.charCount}/100</div>
            </Col>
            {/*
            <Col xs={12}>
              <Input
                name="region"
                typ="text"
                onChange={this.handleChange}
                value={this.state.region}
                placeholder="Region or country"
                className="mb-2"
              />
            </Col>
            */}
            <Col xs={6} sm={12} xl={6}>
              <Label for="dateExpires">Expiry</Label>
              <DatePicker
                className="form-control"
                name="dateExpires"
                onChange={value =>
                  this.handleChange({ target: { name: 'dateExpires', value: value } })
                }
                value={this.state.dateExpires}
                showLeadingZeros={true}
                clearIcon={null}
                locale="en-AU" // how about navigator.language(s)?
              />
            </Col>
            <Col xs={6} sm={12} xl={6}>
              <RiderStyleSelect
                styles={styles}
                placeholder="Any"
                currentStyleId={this.state.riderStyleId}
                allowSelectAny={true}
                handleProfileChange={this.handleChange}
              />
            </Col>
            <Col xs={6} md={9} className="mt-2">
              <Link to="/notices">View All »</Link>
            </Col>
            <Col xs={6} md={3} className="d-flex justify-content-end">
              <Button outline color="primary" disabled={this.state.invalid}>
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
const mapDispatch = {
  getRiderStyles: riderStylesActions.getRiderStyles,
  create: noticeActions.create
};
function mapStateToProps(state) {
  const {
    notice,
    riderStyles: { styles = [] }
  } = state;
  return { notice, styles };
}

const connectedAddNotice = connect(
  mapStateToProps,
  mapDispatch
)(AddNotice);
export { connectedAddNotice as AddNotice };
