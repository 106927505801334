import { teamProfileConstants } from '../_constants';

export function teamProfiles(state = {}, action) {
  switch (action.type) {
    case teamProfileConstants.TEAM_PROFILE_VIEW_REQUEST:
      return {
        ...state
      };
    case teamProfileConstants.TEAM_PROFILE_VIEW_SUCCESS:
      const user = action.data;
      const { profileSlug } = user.teamProfile;
      const list = state.list || {};
      list[profileSlug] = action.data;
      return {
        ...state,
        loading: false,
        list: { ...list }
      };
    case teamProfileConstants.TEAM_PROFILE_VIEW_FAILURE:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
}
