import { contributorConstants } from '../_constants';
import { contributorService } from '../_services';

export const contributorActions = {
  getContributors,
  addContributor,
  removeContributor
};

function getContributors() {
  return dispatch => {
    dispatch(request());
    contributorService.getContributors().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: contributorConstants.GET_CONTRIBUTORS_REQUEST };
  }
  function success(data) {
    return { type: contributorConstants.GET_CONTRIBUTORS_SUCCESS, data };
  }
  function failure(error) {
    return { type: contributorConstants.GET_CONTRIBUTORS_FAILURE, error };
  }
}

function addContributor(userId) {
  return dispatch => {
    dispatch(request(userId));
    contributorService.addContributor(userId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contributorConstants.ADD_CONTRIBUTOR_REQUEST, data };
  }
  function success(data) {
    return { type: contributorConstants.ADD_CONTRIBUTOR_SUCCESS, data };
  }
  function failure(error) {
    return { type: contributorConstants.ADD_CONTRIBUTOR_FAILURE, error };
  }
}

function removeContributor(userId) {
  return dispatch => {
    dispatch(request(userId));
    contributorService.removeContributor(userId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contributorConstants.REMOVE_CONTRIBUTOR_REQUEST, data };
  }
  function success(data) {
    return { type: contributorConstants.REMOVE_CONTRIBUTOR_SUCCESS, data };
  }
  function failure(error) {
    return { type: contributorConstants.REMOVE_CONTRIBUTOR_FAILURE, error };
  }
}
