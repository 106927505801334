import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { AdminHeader } from './Admin/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Admin = () => {
  const panels = [
    {
      name: 'News',
      href: '/admin/news/',
      icon: ['far', 'newspaper'],
      desc: 'Create, update, and delete News and News Categories.'
    },
    {
      name: 'Manage Admins',
      href: '/admin/manageadmins/',
      icon: ['far', 'newspaper'],
      desc: 'Manage the site administrators.'
    },
    {
      name: 'Announcements',
      href: '/admin/announcements/',
      icon: 'bullhorn',
      desc: 'Manage site Announcements'
    },
    {
      name: 'Contributors',
      href: '/admin/managecontributors',
      icon: 'users',
      desc: 'Add and remove contributors'
    },
    {
      name: 'Site Settings',
      href: '/admin/sitesettings/',
      icon: 'cogs',
      desc: 'Manage various settings of Cycling Connect'
    }
  ];
  return (
    <Fragment>
      <Helmet title="Admin Tools - Cycling Connect" />
      <AdminHeader />
      <Container className="mb-5 admin-index">
        <Row>
          {panels.map((panel, idx) => (
            <Col sm={4} md={3} key={idx} className="pb-2 mb-2">
              <Card className="shadow h-100" tag={Link} to={panel.href}>
                <CardBody className="text-center">
                  <FontAwesomeIcon size="3x" icon={panel.icon} />
                  <p className="mb-0">{panel.name}</p>
                  <p className="text-muted mb-0">{panel.desc}</p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};
