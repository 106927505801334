import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RRNavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Logout } from './Logout';

export const TeamNavMenu = ({ team, navigated }) => {
  const { teamProfile } = team;
  const slug = teamProfile.profileSlug;
  const profileUrl = `/team/${teamProfile.profileSlug}`;
  return (
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Hi, {team.firstName}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={RRNavLink} exact to="/team/edit" onClick={navigated}>
            Edit Team Profile
          </DropdownItem>
          {slug && (
            <DropdownItem tag={RRNavLink} to={profileUrl} onClick={navigated}>
              View Team Profile
            </DropdownItem>
          )}
          <DropdownItem tag={RRNavLink} to="/news/" onClick={navigated}>
            News
          </DropdownItem>
          <DropdownItem divider />
          <Logout navigated={navigated} />
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

TeamNavMenu.propTypes = {
  team: PropTypes.object.isRequired,
  navigated: PropTypes.func.isRequired
};
