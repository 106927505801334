import { riderPowerConstants } from '../_constants';
import { riderPowerService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const riderPowerActions = {
  getTypes,
  getTypesAndList,
  update
};

function getTypes() {
  return dispatch => {
    dispatch(request());
    riderPowerService.getTypes().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderPowerConstants.POWER_TYPES_GET_REQUEST, data };
  }
  function success(data) {
    return { type: riderPowerConstants.POWER_TYPES_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderPowerConstants.POWER_TYPES_GET_FAILURE, error };
  }
}

function getTypesAndList() {
  return dispatch => {
    dispatch(request());
    riderPowerService.getTypesAndList().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderPowerConstants.TYPES_AND_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: riderPowerConstants.TYPES_AND_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderPowerConstants.TYPES_AND_LIST_FAILURE, error };
  }
}

function update(data) {
  return dispatch => {
    dispatch(request(data));
    riderPowerService.update(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Power Updated!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderPowerConstants.POWER_UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: riderPowerConstants.POWER_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderPowerConstants.POWER_UPDATE_FAILURE, error };
  }
}
