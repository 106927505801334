import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NoticeCard, NoticeCardFooter, ContactModal } from '.';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../../Shared';
import { noticeActions, connectionsActions } from '../../_actions';
import { PlaceholderCard } from '../../Shared';
import isEmpty from 'lodash/isEmpty';

function Notices(props) {
  const [contactModal, setContactModal] = useState(false);
  const [contactName, setContactName] = useState('');
  const [noticeId, setNoticeId] = useState('');
  const { list, notices, approved } = props;

  useEffect(() => {
    list();
    const interval = setInterval(list, 30000);
    return () => clearInterval(interval);
  }, [list]);

  const profileView = noticeId => {
    props.profileView(noticeId);
  };

  const contactToggle = (contactName, noticeId) => {
    if (!contactModal) {
      setContactName(contactName);
      setNoticeId(noticeId);
    }
    setContactModal(!contactModal);
  };

  const follow = e => {
    const { value } = e.target;
    props.follow(value);
  };

  return (
    <div>
      <Container className="rider-notices grey-bg pb-2 mb-5">
        <Row>
          <Col xs={12}>
            <FormLabel rider text="Notices" />
          </Col>
          <Col className="notice-list mt-4">
            {isEmpty(notices) && (
              <PlaceholderCard message="Notices from Teams will appear here when available." />
            )}
            {notices &&
              notices.map((notice, index) => (
                <NoticeCard key={index} notice={notice}>
                  <NoticeCardFooter
                    notice={notice}
                    contactToggle={contactToggle}
                    follow={follow}
                    disableFollow={approved ? !!approved[notice.team.teamManagerId] : true}
                    profileView={profileView}
                  />
                </NoticeCard>
              ))}
          </Col>
        </Row>
      </Container>
      <ContactModal
        isOpen={contactModal}
        toggle={contactToggle}
        noticeId={noticeId}
        contactName={contactName}
      />
    </div>
  );
}

const mapDispatch = {
  list: noticeActions.list,
  profileView: noticeActions.profileView,
  follow: connectionsActions.follow
};
function mapStateToProps(state) {
  const {
    notice: { notices = null },
    connections: { following = null, approved = null }
  } = state;
  return { notices, following, approved };
}
const connectedNotices = connect(
  mapStateToProps,
  mapDispatch
)(Notices);
export { connectedNotices as Notices };
