import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card, CardTitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AddArticle({ text, href }) {
  return (
    <Col xs={12} md={2} className="mb-4">
      <Card tag={Link} to={href} className="p-2 shadow" body>
        <CardTitle className="text-center">{text}</CardTitle>
        <div className="text-center">
          <FontAwesomeIcon size="3x" icon="plus-circle" />
        </div>
      </Card>
    </Col>
  );
}

AddArticle.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};
