import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RiderRosterCard = props => {
  const {
    rider,
    rider: { profilePhoto }
  } = props;
  const imageStyles = {
    background: `rgba(0, 0, 0, 0) url('${rider.profilePhoto}') no-repeat scroll 50% 50% / cover`,
    minHeight: `110px`
  };
  const profileUrl = `/rider/${rider.profileSlug}`;
  const profilePhotoRich = <div to={profileUrl} style={imageStyles} className="flex-grow-1" />;
  const withoutPhoto = (
    <div to={profileUrl} className="align-middle text-center mx-auto placeholder">
      <FontAwesomeIcon icon="user" size="5x" className="img-fluid" style={{ minHeight: `150px` }} />
    </div>
  );
  return (
    <Col sm={3} md={2} className="text-center d-flex mb-4">
      <Card tag={Link} to={profileUrl} className="shadow flex-grow-1">
        {profilePhoto ? profilePhotoRich : withoutPhoto}
        <CardFooter style={{ color: `#fff`, background: `#404040` }}>
          {rider.displayName}
        </CardFooter>
      </Card>
    </Col>
  );
};
