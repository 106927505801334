import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';

export const dashboardActions = {
  latestNews,
  suggestedConnections,
  teamOpenings
};

function latestNews() {
  return dispatch => {
    dispatch(request());

    dashboardService.latestNews().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: dashboardConstants.LATEST_NEWS_REQUEST, data };
  }
  function success(data) {
    return { type: dashboardConstants.LATEST_NEWS_SUCCESS, data };
  }
  function failure(error) {
    return { type: dashboardConstants.LATEST_NEWS_FAILURE, error };
  }
}

function suggestedConnections() {
  return dispatch => {
    dispatch(request());

    dashboardService.suggestedConnections().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: dashboardConstants.SUGGESTED_CONNECTIONS_REQUEST, data };
  }
  function success(data) {
    return { type: dashboardConstants.SUGGESTED_CONNECTIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: dashboardConstants.SUGGESTED_CONNECTIONS_FAILURE, error };
  }
}

function teamOpenings() {
  return dispatch => {
    dispatch(request());

    dashboardService.teamOpenings().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: dashboardConstants.TEAM_OPENINGS_REQUEST, data };
  }
  function success(data) {
    return { type: dashboardConstants.TEAM_OPENINGS_SUCCESS, data };
  }
  function failure(error) {
    return { type: dashboardConstants.TEAM_OPENINGS_FAILURE, error };
  }
}
