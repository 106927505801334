import React from 'react';
import PropTypes from 'prop-types';

const cardStyle = {
  background: '#fff',
  marginLeft: '8px',
  marginRight: '8px',
  marginBottom: '8px',
  padding: '4px'
};

export const PlaceholderCard = props => (
  <div className="shadow text-center" style={cardStyle}>
    {props.message && <p className="mt-3"> {props.message}</p>}
    {props.children}
  </div>
);

PlaceholderCard.propTypes = {
  message: PropTypes.any
};
