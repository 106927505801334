import { headers, authFetch } from '../_helpers';

export const noticeService = {
  create,
  list,
  listTeamNotices,
  listExpiredTeamNotices,
  emailNoticePoster,
  extend,
  cancel,
  profileView
};

async function create(data) {
  const response = await authFetch(`/api/Notice/Create`, headers.post(data));
  return response;
}

async function emailNoticePoster(data) {
  const response = await authFetch(`/api/Notice/Contact`, headers.post(data));
  return response;
}

async function list() {
  const response = await authFetch(`/api/Notice/List`, headers.get());
  return response;
}

async function listTeamNotices() {
  const response = await authFetch(`/api/Notice/ListTeamNotices`, headers.get());
  return response;
}

async function listExpiredTeamNotices() {
  const response = await authFetch(`/api/Notice/ListExpiredTeamNotices`, headers.get());
  return response;
}

async function extend(noticeId) {
  const response = await authFetch(`/api/Notice/Extend`, headers.postPrimitive(noticeId));
  return response;
}

async function cancel(noticeId) {
  const response = await authFetch(`/api/Notice/Cancel`, headers.postPrimitive(noticeId));
  return response;
}

async function profileView(noticeId) {
  const response = await authFetch(`/api/Notice/ProfileView`, headers.postPrimitive(noticeId));
  return response;
}
