import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { connectionsActions } from '../_actions';

const defaultState = { showMessageForm: false, message: '' };

class RemoveModal extends React.Component {
  state = { ...defaultState };
  // if the email was sent, then we close the modal
  componentDidUpdate = prevProps => {
    if (prevProps.removed !== this.props.removed) {
      if (this.props.removed) {
        this.props.toggle();
      }
    }
  };
  handleCancel = () => {
    this.setState({ ...defaultState });
    this.props.toggle();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleRemove = e => {
    // todo
    const userId = this.props.toRemove.id;
    const { message } = this.state;
    if (userId) {
      this.props.remove({ userId, message });
    }
  };

  messageFormToggle = () => {
    this.setState({ showMessageForm: !this.state.showMessageForm });
  };

  render() {
    const { removing, isOpen, toggle, toRemove, isRider, isTeamManager } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Remove Connection with {!isEmpty(toRemove) && toRemove.displayName}
        </ModalHeader>
        <ModalBody>
          {isRider && (
            <div>
              <p>
                You are about to remove your Connection with{' '}
                {!isEmpty(toRemove) && toRemove.displayName}.
              </p>
              <p>
                This will remove their access to your Power Data and Referees. If you wish, you can
                send {!isEmpty(toRemove) && toRemove.displayName} a message telling them why,
                otherwise we'll inform them of your decision.
              </p>
            </div>
          )}
          {isTeamManager && (
            <div>
              <p>
                You are about to remove your Connection with{' '}
                {!isEmpty(toRemove) && toRemove.displayName}.
              </p>
              <p>
                This will also remove {!isEmpty(toRemove) && toRemove.firstName} from your
                Recruitment Manager, and will also prevent you from viewing their Power Data and
                Referees. If you wish, you can send {!isEmpty(toRemove) && toRemove.displayName} a
                message telling them why, otherwise we'll inform them of your decision.
              </p>
            </div>
          )}
          {!this.state.showMessageForm && (
            <Row>
              <Col className="d-flex">
                <Button className="mx-auto" color="primary" onClick={this.messageFormToggle}>
                  Send a message
                </Button>
              </Col>
              <Col className="d-flex">
                {removing ? (
                  <Button color="info">
                    <FontAwesomeIcon icon="spinner" spin /> Removing
                  </Button>
                ) : (
                  <Button color="danger" onClick={this.handleRemove}>
                    Just remove
                  </Button>
                )}{' '}
              </Col>
            </Row>
          )}
          {this.state.showMessageForm && (
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="message"
                    placeholder="Your message"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </ModalBody>
        {this.state.showMessageForm && (
          <ModalFooter>
            {removing ? (
              <Button color="info">
                <FontAwesomeIcon icon="spinner" spin /> Sending
              </Button>
            ) : (
              <Button color="primary" onClick={this.handleRemove}>
                Send and Remove
              </Button>
            )}{' '}
            <Button color="danger" onClick={this.handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { toRemove, removing = false, removed } = state.connections;
  const { isRider, isTeamManager } = state.authentication.user;
  return { toRemove, isRider, isTeamManager, removing, removed };
}

const connectedRemoveModal = connect(
  mapStateToProps,
  { connect: connectionsActions.connect, remove: connectionsActions.remove }
)(RemoveModal);
export { connectedRemoveModal as RemoveModal };
