export const racingLevelsConstants = {
  RACING_LEVELS_GET_REQUEST: 'RACING_LEVELS_GET_REQUEST',
  RACING_LEVELS_GET_SUCCESS: 'RACING_LEVELS_GET_SUCCESS',
  RACING_LEVELS_GET_FAILURE: 'RACING_LEVELS_GET_FAILURE',
  RACING_LEVEL_UPDATE_REQUEST: 'RACING_LEVEL_UPDATE_REQUEST',
  RACING_LEVEL_UPDATE_SUCCESS: 'RACING_LEVEL_UPDATE_SUCCESS',
  RACING_LEVEL_UPDATE_FAILURE: 'RACING_LEVEL_UPDATE_FAILURE',
  RACING_LEVEL_CREATE_REQUEST: 'RACING_LEVEL_CREATE_REQUEST',
  RACING_LEVEL_CREATE_SUCCESS: 'RACING_LEVEL_CREATE_SUCCESS',
  RACING_LEVEL_CREATE_FAILURE: 'RACING_LEVEL_CREATE_FAILURE',
  RACING_LEVEL_DELETE_REQUEST: 'RACING_LEVEL_DELETE_REQUEST',
  RACING_LEVEL_DELETE_SUCCESS: 'RACING_LEVEL_DELETE_SUCCESS',
  RACING_LEVEL_DELETE_FAILURE: 'RACING_LEVEL_DELETE_FAILURE',
  RACING_LEVEL_GET_USE_REQUEST: 'RACING_LEVEL_GET_USE_REQUEST',
  RACING_LEVEL_GET_USE_SUCCESS: 'RACING_LEVEL_GET_USE_SUCCESS',
  RACING_LEVEL_GET_USE_FAILURE: 'RACING_LEVEL_GET_USE_FAILURE'
};
