import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, FormGroup, Button, Label } from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import debounce from 'lodash/debounce';
import { reactSelectStyles } from '../Inputs/ReactSelectStyles';
import { adminActions, contributorActions } from '../_actions';
import { CurrentContributors } from './CurrentContributors';
import { AdminHeader } from './AdminHeader';

export function ManageContributors() {
  return (
    <>
      <AdminHeader title="Manage Contributors" />
      <AddContributor />
      <CurrentContributors />
    </>
  );
}

function AddContributor() {
  const [value, setValue] = useState(null);
  const { lookup = [] } = useSelector(state => state.admin);
  const { added, adding } = useSelector(state => state.contributors);
  const dispatch = useDispatch();

  const { userLookup } = adminActions;
  const { addContributor } = contributorActions;

  useEffect(() => {
    if (added) {
      setValue(null);
    }
  }, [added]);

  const handleLookup = debounce((terms, callback) => {
    dispatch(userLookup(terms));
    setTimeout(() => {
      callback(
        lookup.map(l => ({
          value: l.id,
          label: l.displayName,
          id: l.id
        }))
      );
    }, 250);
  }, 350);

  const handleSelect = value => {
    setValue(value);
  };

  const handleClick = () => {
    if (value.id) {
      dispatch(addContributor(value.id));
    }
  };

  return (
    <Container>
      <Row>
        <Col md={4}>
          <UserLookup value={value} handleLookup={handleLookup} handleSelect={handleSelect} />
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label>Apply</Label>
            <Button color="primary" block onClick={handleClick} disabled={adding}>
              Make Contributor
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}

function UserLookup({ handleLookup, handleSelect, value }) {
  return (
    <FormGroup>
      <Label>User Lookup</Label>
      <AsyncSelect
        cacheOptions
        styles={reactSelectStyles}
        loadOptions={handleLookup}
        value={value}
        onChange={val => handleSelect(val)}
        name="terms"
      />
    </FormGroup>
  );
}
UserLookup.propTypes = {
  handleLookup: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired
};
