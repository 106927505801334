import { riderSearchConstants } from '../_constants';

export function riderSearch(state = {}, action) {
  switch (action.type) {
    case riderSearchConstants.RIDER_SEARCH_REQUEST:
      return {
        loading: true,
        success: false,
        results: [],
        resultCount: 0,
        pageNumber: 0
      };
    case riderSearchConstants.RIDER_SEARCH_SUCCESS:
      return {
        loading: false,
        success: true,
        ...action.data
      };
    case riderSearchConstants.RIDER_SEARCH_PAGE_CHANGE:
      const start = (action.data - 1) * 30;
      const end = start + 30;
      const results = state.resultSet.slice(start, end);
      return {
        ...state,
        results,
        pageNumber: action.data
      };
    case riderSearchConstants.RIDER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        resultCount: 0
      };
    default:
      return state;
  }
}
