import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const FacebookInput = props => (
  <FormGroup>
    <Label for="facebookUrl">Facebook</Label>
    <Input
      name="facebookUrl"
      id="facebookUrl"
      defaultValue={props.facebookUrl}
      onChange={props.handleChange}
    />
  </FormGroup>
);
FacebookInput.propTypes = {
  facebookUrl: PropTypes.string,
  handleChange: PropTypes.func
};
