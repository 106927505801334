import React from 'react';
import { AdminHeader, CurrentAdmins, UserLookup } from './';

export const ManageAdmins = () => (
  <>
    <AdminHeader title="Manage Admins" />
    <UserLookup />
    <CurrentAdmins />
  </>
);
