export const riderCareerHistoryConstants = {
  LIST_HISTORY_REQUEST: 'LIST_HISTORY_REQUEST',
  LIST_HISTORY_SUCCESS: 'LIST_HISTORY_SUCCESS',
  LIST_HISTORY_FAILURE: 'LIST_HISTORY_FAILURE',
  CREATE_UPDATE_CAREER_REQUEST: 'CREATE_UPDATE_CAREER_REQUEST',
  CREATE_UPDATE_CAREER_SUCCESS: 'CREATE_UPDATE_CAREER_SUCCESS',
  CREATE_UPDATE_CAREER_FAILURE: 'CREATE_UPDATE_CAREER_FAILURE',
  DELETE_CAREER_REQUEST: 'DELETE_CAREER_REQUEST',
  DELETE_CAREER_SUCCESS: 'DELETE_CAREER_SUCCESS',
  DELETE_CAREER_FAILURE: 'DELETE_CAREER_FAILURE'
};
