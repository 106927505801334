import { riderSexesConstants } from '../_constants';
import { riderSexesService } from '../_services';

export const riderSexesActions = {
  getRiderSexes
};

function getRiderSexes() {
  return dispatch => {
    dispatch(request());

    riderSexesService.getRiderSexes().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: riderSexesConstants.RIDER_SEXES_GET_REQUEST, data };
  }
  function success(data) {
    return { type: riderSexesConstants.RIDER_SEXES_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderSexesConstants.RIDER_SEXES_GET_FAILURE, error };
  }
}
