import React from 'react';
import { connect } from 'react-redux';
import { riderCareerHistoryActions } from '../_actions';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

class CareerHistoryCreator extends React.Component {
  state = {
    current: false,
    startYear: '',
    endYear: '',
    teamName: ''
  };

  handleChange = e => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  createUpdate = e => {
    e.preventDefault();
    if (this.state.startYear && this.state.endYear && this.state.teamName) {
      this.props.createUpdateCareerHistory({ ...this.state });
      this.setState({ current: false, startYear: '', endYear: '', teamName: '' });
    }
  };

  render() {
    return (
      <Container className="account-form career-history">
        <Form onSubmit={this.createUpdate}>
          <Row>
            <Col xs={12} className="pb-4">
              <FormLabel rider text="Add Career History" />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <FormGroup>
                <Label for="teamName">Team</Label>
                <Input
                  name="teamName"
                  id="teamName"
                  value={this.state.teamName}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={4} md={2} lg={3}>
              <FormGroup>
                <Label for="startYear">Start Year</Label>
                <Input
                  name="startYear"
                  id="startYear"
                  type="number"
                  min="1970"
                  value={this.state.startYear}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={4} md={2} lg={3}>
              <FormGroup>
                <Label for="endYear">End Year</Label>
                <Input
                  name="endYear"
                  id="endYear"
                  type="number"
                  value={this.state.endYear}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={3} md={2}>
              <FormGroup>
                <Label>Current</Label>
                <div>
                  <CustomInput
                    type="checkbox"
                    id="current"
                    name="current"
                    label="Current"
                    onChange={this.handleChange}
                    value={this.state.current}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col xs={3} sm={3} md={2} lg={1}>
              <FormGroup>
                <Label>Add</Label>
                <Button block outline color="primary">
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
const mapDispatch = {
  createUpdateCareerHistory: riderCareerHistoryActions.createUpdateCareerHistory
};
function mapStateToProps(state) {
  const { riderProfile } = state;
  return { riderProfile };
}

const connectedCareerHistoryCreator = connect(
  mapStateToProps,
  mapDispatch
)(CareerHistoryCreator);
export { connectedCareerHistoryCreator as CareerHistoryCreator };
