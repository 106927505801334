import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const WebsiteInput = props => (
  <FormGroup>
    <Label for="websiteUrl">{props.label ? props.label : 'Website'}</Label>
    <Input
      name="websiteUrl"
      id="websiteUrl"
      defaultValue={props.websiteUrl}
      onChange={props.handleChange}
    />
  </FormGroup>
);
WebsiteInput.propTypes = {
  websiteUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
