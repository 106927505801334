import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';

export const RiderStyleSelect = props => {
  let { placeholder, styles, allowSelectAny = false } = props;
  let currentStyle = props.currentStyleId;
  let optionItems = styles.map(style => (
    <option key={style.riderStyleId} value={style.riderStyleId}>
      {style.riderStyleName}
    </option>
  ));
  return (
    <FormGroup>
      <Label for="riderStyle">Riding Style</Label>
      <Input
        type="select"
        className="custom-select"
        name="riderStyleId"
        onChange={props.handleProfileChange}
        defaultValue={currentStyle || 0}
      >
        {currentStyle !== 'undefined' && (
          <option disabled={!allowSelectAny} value={0}>
            {placeholder || 'Choose...'}
          </option>
        )}
        {optionItems}
      </Input>
    </FormGroup>
  );
};

RiderStyleSelect.propTypes = {
  styles: PropTypes.array.isRequired,
  currentStyleId: PropTypes.number,
  handleProfileChange: PropTypes.func.isRequired
};
