import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { FormLabel } from '../Shared';
import { accountActions } from '../_actions';

function MyAccount({ team = false, rider = false, get, update, account, save }) {
  const { firstName, lastName, email } = account;

  useEffect(() => {
    get();
  }, [get]);

  const handleChange = e => {
    const { name, value } = e.target;
    update({ name, value });
  };

  const handleSave = e => {
    e.preventDefault();
    save({ ...account });
  };

  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team={team} rider={rider} text="My Account" />
        </Col>
      </Row>
      <Form onSubmit={handleSave}>
        <Row>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label for="name">First Name</Label>
              <Input
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label for="name">Last Name</Label>
              <Input
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={handleChange}
                required
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label for="name">Email</Label>
              <Input name="email" id="email" value={email} onChange={handleChange} required />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color={team ? 'team' : 'rider'}>Save Changes</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

function mapState(state) {
  const { account } = state;
  return { account };
}
const mapDispatch = {
  ...accountActions
};
const connectedMyAccount = connect(
  mapState,
  mapDispatch
)(MyAccount);

export { connectedMyAccount as MyAccount };
