import { headers, authFetch } from '../_helpers';

export const recruitService = {
  list,
  addRecruit,
  updateOrder,
  updateStage,
  emailRecruit,
  addNote,
  listNotesForRecruit
};

async function list() {
  const response = await authFetch(`/api/Recruit/List`, headers.get());
  return response;
}

async function addRecruit(connectionId) {
  const response = await authFetch(`/api/Recruit/Add`, headers.postPrimitive(connectionId));
  return response;
}

async function updateOrder(data) {
  const response = await authFetch(`/api/Recruit/ChangeIndex`, headers.post(data));
  return response;
}

async function updateStage(data) {
  const response = await authFetch(`/api/Recruit/ChangeStage`, headers.post(data));
  return response;
}

async function emailRecruit(data) {
  const response = await authFetch(`/api/Recruit/Email`, headers.post(data));
  return response;
}

async function addNote(note) {
  const response = await authFetch(`/api/Recruit/AddNote`, headers.post(note));
  return response;
}

async function listNotesForRecruit(recruitId) {
  const response = await authFetch(
    `/api/Recruit/ListNotesForRecruit`,
    headers.postPrimitive(recruitId)
  );
  return response;
}
