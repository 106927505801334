import React from 'react';
import classnames from 'classnames';
import { ShadowScrollbars } from '../Shared';
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
export const RaceSelector = props => {
  const { races, selectRace, raceIndex } = props;
  return (
    <Card style={{ border: 'none' }} className="race-selector">
      <CardHeader>
        <h5 className="mb-0">Select Race</h5>
      </CardHeader>
      <ListGroup>
        <ShadowScrollbars>
          {!isEmpty(races) &&
            races.map((race, index) => {
              const active = index === raceIndex;
              return (
                <ListGroupItem
                  className={classnames('race-item', { active })}
                  key={index}
                  onClick={() => selectRace(race.id, index)}
                >
                  {race.name} - {race.country}
                </ListGroupItem>
              );
            })}
        </ShadowScrollbars>
      </ListGroup>
    </Card>
  );
};
