import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FormLabel } from '../Shared';
import { LocationFinder, RiderSexSelect, NationalityInput } from '../Inputs';

export const RiderAboutMeEdit = props => {
  const {
    user,
    profile,
    sexes,
    handleUserChange,
    handleProfileChange,
    handleNationalityChange
  } = props;
  return (
    <Container className="account-form mt-0">
      <Row>
        <Col sm={12} className="pt-4">
          <FormLabel rider text="About Me" />
          <Row>
            <Col xs={12} sm={6} md={3}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  name="firstName"
                  id="firstName"
                  defaultValue={user.firstName}
                  onChange={handleUserChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  name="lastName"
                  id="lastName"
                  defaultValue={user.lastName}
                  onChange={handleUserChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <FormGroup>
                <Label for="dateOfBirth">Date of Birth</Label>
                <Input
                  name="dateOfBirth"
                  id="dateOfBirth"
                  defaultValue={user && user.dateOfBirth ? user.dateOfBirth.slice(0, 10) : ''}
                  type="date"
                  onChange={handleUserChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4} md={3}>
              {sexes && (
                <RiderSexSelect
                  handleChange={handleProfileChange}
                  currentSexId={profile.riderSexId}
                  sexes={sexes}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={3}>
              <NationalityInput
                nationality={profile.nationality}
                handleChange={handleNationalityChange}
                rider
              />
            </Col>
            <Col xs={12} md={3}>
              <LocationFinder location={profile.location} handleChange={handleProfileChange} />
            </Col>
            <Col xs={12} md={3}>
              <FormGroup>
                <Label for="language">Language</Label>
                <Input
                  name="language"
                  id="language"
                  defaultValue={profile.language}
                  onChange={handleProfileChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <FormGroup>
                <Label for="profileSlug">Profile URL</Label>
                <Input
                  name="profileSlug"
                  id="profileSlug"
                  defaultValue={profile.profileSlug}
                  onChange={handleProfileChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

RiderAboutMeEdit.propTypes = {
  user: PropTypes.object,
  profile: PropTypes.object,
  sexes: PropTypes.array,
  handleUserChange: PropTypes.func.isRequired,
  handleProfileChange: PropTypes.func.isRequired,
  handleNationalityChange: PropTypes.func.isRequired
};
