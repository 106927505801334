import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

export function TodaysPlan() {
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel rider text="Connect Today's Plan" />
        </Col>
        <Col className="mt-3">
          <p>
            Connect your Cycling Connect and Today's Plan accounts and we'll handle displaying your
            best power curves to Connected Teams.
          </p>
          <p className="text-center">
            <a
              href="https://staging.todaysplan.com.au/en/authorize/cyclingconnect"
              className="btn btn-rider"
            >
              Let's Go
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
