import { authFetch, headers } from '../_helpers';

export const riderSearchService = {
  search
};

async function search(terms) {
  const response = await authFetch(`/api/RiderSearch`, headers.postPrimitive(terms));
  return response;
}
