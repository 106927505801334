import { handleResponse, headers } from '../_helpers';

export const authenticationService = {
  login,
  logout,
  register
};

async function login(email, password) {
  const response = await fetch(`/api/Auth/Login`, headers.post({ email, password }));
  const user = await handleResponse(response);
  // login successful if there's a jwt token in the response
  if (user.token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user', JSON.stringify(user));
  }
  return user;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('state');
}

async function register(email, password, accountType, firstName, lastName, newsletterOptIn) {
  const res = await fetch(
    `/api/Account/Register`,
    headers.post({ email, password, accountType, firstName, lastName, newsletterOptIn })
  );
  const response = await handleResponse(res);
  return response;
}
