import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from 'reactstrap';

export const FollowFooter = props => (
  <ButtonGroup className="btn-block">
    <Button
      className="col"
      to={`/${props.isRider ? 'rider' : 'team'}/${props.user.profileSlug}`}
      tag={Link}
      color="primary"
    >
      Profile
    </Button>
    <Button className="col" color="warning" value={props.user.id} onClick={props.unfollow}>
      Unfollow
    </Button>
    <Button
      className="col"
      color="success"
      value={props.user.id}
      onClick={e => props.connect(e, props.user.displayName)}
      disabled={props.disableConnect}
    >
      Connect
    </Button>
  </ButtonGroup>
);

FollowFooter.propTypes = {
  connect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  unfollow: PropTypes.func.isRequired,
  isRider: PropTypes.bool
};
