import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RRNavLink } from 'react-router-dom';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import { Logout } from './';

export const RiderNavMenu = ({ rider, navigated }) => {
  const { riderProfile } = rider;

  if (!riderProfile.profileSlug) {
    return '';
  }

  const profileUrl = `/rider/${riderProfile.profileSlug}`;

  return (
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Hi, {rider.firstName}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={RRNavLink} to="/rider/edit" onClick={navigated}>
            Update Profile
          </DropdownItem>
          <DropdownItem tag={RRNavLink} to={profileUrl} onClick={navigated}>
            View Profile
          </DropdownItem>
          <DropdownItem tag={RRNavLink} to="/news/" onClick={navigated}>
            News
          </DropdownItem>
          <DropdownItem divider />
          <Logout navigated={navigated} />
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RiderNavMenu.propTypes = {
  rider: PropTypes.object.isRequired,
  navigated: PropTypes.func.isRequired
};
