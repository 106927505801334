import React from 'react';
import PropTypes from 'prop-types';

export function Error({ error, className = '' }) {
  return <p className={`text-danger bg white px-1 pt-1 ${className}`}>{error}</p>;
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string
};
