export const announcementConstants = {
  GET_ANNOUNCEMENTS_REQUEST: 'GET_ANNOUNCEMENTS_REQUEST',
  GET_ANNOUNCEMENTS_SUCCESS: 'GET_ANNOUNCEMENTS_SUCCESS',
  GET_ANNOUNCEMENTS_FAILURE: 'GET_ANNOUNCEMENTS_FAILURE',
  GET_ANNOUNCEMENTS_FOR_USER_REQUEST: 'GET_ANNOUNCEMENTS_FOR_USER_REQUEST',
  GET_ANNOUNCEMENTS_FOR_USER_SUCCESS: 'GET_ANNOUNCEMENTS_FOR_USER_SUCCESS',
  GET_ANNOUNCEMENTS_FOR_USER_FAILURE: 'GET_ANNOUNCEMENTS_FOR_USER_FAILURE',
  CREATE_ANNOUNCEMENT_REQUEST: 'CREATE_ANNOUNCEMENT_REQUEST',
  CREATE_ANNOUNCEMENT_SUCCESS: 'CREATE_ANNOUNCEMENT_SUCCESS',
  CREATE_ANNOUNCEMENT_FAILURE: 'CREATE_ANNOUNCEMENT_FAILURE',
  DELETE_ANNOUNCEMENT_REQUEST: 'DELETE_ANNOUNCEMENT_REQUEST',
  DELETE_ANNOUNCEMENT_SUCCESS: 'DELETE_ANNOUNCEMENT_SUCCESS',
  DELETE_ANNOUNCEMENT_FAILURE: 'DELETE_ANNOUNCEMENT_FAILURE',
  HIDE_ANNOUNCEMENT_REQUEST: 'HIDE_ANNOUNCEMENT_REQUEST',
  HIDE_ANNOUNCEMENT_SUCCESS: 'HIDE_ANNOUNCEMENT_SUCCESS',
  HIDE_ANNOUNCEMENT_FAILURE: 'HIDE_ANNOUNCEMENT_FAILURE'
};
