import { headers, authFetch } from '../_helpers';

export const dashboardService = {
  latestNews,
  suggestedConnections,
  teamOpenings
};

async function latestNews() {
  const response = await authFetch(`/api/Dashboard/LatestNews`, headers.get());
  return response;
}

async function suggestedConnections() {
  const response = await authFetch(`/api/Dashboard/SuggestedConnections`, headers.get());
  return response;
}

async function teamOpenings() {
  const response = await authFetch(`/api/Dashboard/TeamOpenings`, headers.get());
  return response;
}
