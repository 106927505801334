import { announcementConstants } from '../_constants';

export function announcement(state = {}, action) {
  switch (action.type) {
    case announcementConstants.GET_ANNOUNCEMENTS_REQUEST:
    case announcementConstants.GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        all: action.data
      };
    case announcementConstants.GET_ANNOUNCEMENTS_FAILURE:
    case announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_REQUEST:
    case announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_SUCCESS:
      return {
        ...state,
        forUser: action.data
      };
    case announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_FAILURE:
    case announcementConstants.CREATE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        creating: true,
        created: false
      };
    case announcementConstants.CREATE_ANNOUNCEMENT_SUCCESS: {
      const { all = [] } = state;
      all.push(action.data);
      return {
        ...state,
        creating: false,
        created: true,
        all
      };
    }
    case announcementConstants.CREATE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        creating: false
      };
    case announcementConstants.DELETE_ANNOUNCEMENT_REQUEST: {
      const all = state.all.filter(ann => ann.id !== action.data);
      return {
        ...state,
        all
      };
    }
    case announcementConstants.DELETE_ANNOUNCEMENT_SUCCESS:
    case announcementConstants.DELETE_ANNOUNCEMENT_FAILURE:
    case announcementConstants.HIDE_ANNOUNCEMENT_REQUEST:
      const forUser = state.forUser.filter(a => a.id !== action.data);
      return {
        ...state,
        forUser
      };
    case announcementConstants.HIDE_ANNOUNCEMENT_SUCCESS:
    case announcementConstants.HIDE_ANNOUNCEMENT_FAILURE:
    default:
      return state;
  }
}
