import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Container, Row, Col, Button } from 'reactstrap';
import { RiderLookup } from './';
import { FormLabel } from '../Shared';
import { teamRosterActions } from '../_actions';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const defaultState = { season: new Date().getFullYear(), riderId: '', riderName: '' };

class TeamRosterCreator extends React.Component {
  state = { ...defaultState };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createRoster({ ...this.state });
    this.setState({ ...defaultState, riderName: '', riderId: '' });
  };

  riderLookupChange = debounce(terms => {
    if (!terms) {
      return;
    }
    this.setState({ riderName: terms });
    if (terms.length >= 4) {
      this.props.riderLookup(terms);
    }
  }, 350);

  riderLookupSelect = val => {
    if (!val) {
      return;
    }
    console.log(val);
    const { value, label } = val;
    // if the label and value are not equal, it means a looked up rider has been
    // selected, so we need to set the ID
    if (label !== value) {
      this.setState({ riderId: value, riderName: label });
    } else {
      this.setState({ riderName: label });
    }
  };

  render() {
    const {
      teamRoster: { riders }
    } = this.props;
    return (
      <Container className="account-form">
        <Row>
          <Col className="pb-4">
            <FormLabel team text="Team Roster" />
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="season">Season</Label>
                <Input
                  name="season"
                  id="season"
                  onChange={this.handleChange}
                  placeholder={new Date().getFullYear()}
                />
              </FormGroup>
            </Col>
            <Col md={7}>
              <RiderLookup
                riders={riders}
                riderName={this.state.riderName}
                riderId={this.state.riderId}
                riderLookupChange={this.riderLookupChange}
                riderLookupSelect={this.riderLookupSelect}
              />
            </Col>
            <Col>
              <FormGroup>
                <Label>Add</Label>
                <Button block outline color="primary">
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
const mapDispatch = {
  createRoster: teamRosterActions.createRoster,
  riderLookup: teamRosterActions.riderLookup
};
function mapStateToProps(state) {
  const { teamRoster } = state;
  return { teamRoster };
}

const connectedTeamRosterCreator = connect(
  mapStateToProps,
  mapDispatch
)(TeamRosterCreator);
export { connectedTeamRosterCreator as TeamRosterCreator };
