import { noticeConstants } from '../_constants';
import { noticeService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const noticeActions = {
  create,
  list,
  listTeamNotices,
  listExpiredTeamNotices,
  emailNoticePoster,
  cancel,
  extend,
  profileView
};

function emailNoticePoster(data) {
  return dispatch => {
    dispatch(request(data));
    noticeService.emailNoticePoster(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Email sent!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.CONTACT_NOTICE_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.CONTACT_NOTICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.CONTACT_NOTICE_FAILURE, error };
  }
}

function create(data) {
  return dispatch => {
    dispatch(request(data));
    noticeService.create(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Notice added!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.CREATE_NOTICE_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.CREATE_NOTICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.CREATE_NOTICE_FAILURE, error };
  }
}

function list(data) {
  return dispatch => {
    dispatch(request(data));
    noticeService.list().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.LIST_NOTICES_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.LIST_NOTICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.LIST_NOTICES_FAILURE, error };
  }
}

function listTeamNotices() {
  return dispatch => {
    dispatch(request());
    noticeService.listTeamNotices().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.LIST_TEAM_NOTICES_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.LIST_TEAM_NOTICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.LIST_TEAM_NOTICES_FAILURE, error };
  }
}

function listExpiredTeamNotices() {
  return dispatch => {
    dispatch(request());
    noticeService.listExpiredTeamNotices().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.LIST_EXPIRED_TEAM_NOTICES_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.LIST_EXPIRED_TEAM_NOTICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.LIST_EXPIRED_TEAM_NOTICES_FAILURE, error };
  }
}

function cancel(noticeId) {
  return dispatch => {
    dispatch(request(noticeId));
    noticeService.cancel(noticeId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.CANCEL_NOTICE_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.CANCEL_NOTICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.CANCEL_NOTICE_FAILURE, error };
  }
}

function extend(noticeId) {
  return dispatch => {
    dispatch(request(noticeId));
    noticeService.extend(noticeId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: noticeConstants.EXTEND_NOTICE_REQUEST, data };
  }
  function success(data) {
    return { type: noticeConstants.EXTEND_NOTICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: noticeConstants.EXTEND_NOTICE_FAILURE, error };
  }
}

function profileView(noticeId) {
  return dispatch => {
    dispatch(request(noticeId));
    noticeService.profileView(noticeId);
  };
  function request(noticeId) {
    return { type: noticeConstants.NOTICE_PROFILE_VIEW_REQUEST, noticeId };
  }
}
