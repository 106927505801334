import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MailChimpSignup } from './MailChimpSignup';

const Footer = () => (
  <footer className="footer">
    <Container>
      <Row>
        <Col xs={12} sm={4}>
          <h4>Newsletter</h4>
          <MailChimpSignup />
        </Col>
        <Col className="social text-center" sm={4}>
          <h4>Stay in Touch</h4>
          <Row>
            <Col>
              <a
                href="https://www.facebook.com/cyclingconnectsport/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="rotate" size="2x" icon={['fab', 'facebook']} />
              </a>
            </Col>
            <Col>
              <a
                href="https://twitter.com/Cycling_Connect"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="rotate" size="2x" icon={['fab', 'twitter']} />
              </a>
            </Col>
            {/**
            <Col>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="rotate" size="2x" icon={['fab', 'youtube']} />
              </a>
            </Col>
             */}
            <Col>
              <a
                href="https://instagram.com/CyclingConnect"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon className="rotate" size="2x" icon={['fab', 'instagram']} />
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={4}>
          <h4>More</h4>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
