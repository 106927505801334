import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function FollowNoActionsButton(props) {
  const { size, color, block = false } = props;
  return (
    <div className="follow following">
      <Button color={color || 'primary'} block={block} size={size}>
        <FontAwesomeIcon icon="check-circle" /> Following
      </Button>
    </div>
  );
}
