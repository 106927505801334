import React from 'react';
import { connect } from 'react-redux';
import { riderCareerHistoryActions } from '../_actions';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Button,
  Form
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

class CareerHistoryEditor extends React.Component {
  state = {};
  componentDidMount = () => {
    this.props.listCareerHistory();
  };

  handleSubmit = (e, id) => {
    e.preventDefault();
    const historyItem = this.props.list.find(i => i.id === id);
    const updatedHistory = { ...historyItem, ...this.state[id] };
    this.props.createUpdateCareerHistory(updatedHistory);
    // Updates the value in state for the given item so that the checkbox will
    // render correctly.
    this.setState({ [id]: { checked: updatedHistory.checked } });
  };

  handleDelete = e => {
    e.preventDefault();
    const historyId = e.target.value;
    this.props.deleteCareerHistory(historyId);
  };

  handleChange = (e, id) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [id]: { [name]: value } });
  };

  render() {
    const careerHistory = this.props.list || [];
    const careerList = careerHistory.map(c => (
      <Form onSubmit={e => this.handleSubmit(e, c.id)} key={c.id} className="career-history-item">
        <Row>
          <Col xs={6} sm={4} md={4} lg={3}>
            <FormGroup>
              <Label>Team</Label>
              <Input
                name="teamName"
                defaultValue={c.teamName}
                required
                onChange={e => this.handleChange(e, c.id)}
              />
            </FormGroup>
          </Col>
          <Col xs={6} sm={4} md={4} lg={3}>
            <FormGroup>
              <Label>Start Year</Label>
              <Input
                name="startYear"
                defaultValue={c.startYear}
                required
                onChange={e => this.handleChange(e, c.id)}
              />
            </FormGroup>
          </Col>
          <Col xs={6} sm={4} md={4} lg={3}>
            <FormGroup>
              <Label>End Year</Label>
              <Input
                name="endYear"
                defaultValue={c.endYear}
                required
                onChange={e => this.handleChange(e, c.id)}
              />
            </FormGroup>
          </Col>
          <Col xs={6} sm={4} md={2} lg={1}>
            <FormGroup>
              <Label>Current</Label>
              <div>
                <CustomInput
                  type="checkbox"
                  id={`current-${c.id}`}
                  name="current"
                  label="Current"
                  checked={
                    this.state[c.id] && this.state[c.id].current
                      ? this.state[c.id].current
                      : c.current
                  }
                  onChange={e => this.handleChange(e, c.id)}
                />
              </div>
            </FormGroup>
          </Col>
          <Col xs={6} sm={4} md={2} lg={1}>
            <FormGroup>
              <Label>Save</Label>
              <Button block outline color="primary">
                <FontAwesomeIcon icon="check" />
              </Button>
            </FormGroup>
          </Col>
          <Col xs={6} sm={4} md={2} lg={1}>
            <FormGroup>
              <Label>Delete</Label>
              <Button block outline color="danger" value={c.id} onClick={this.handleDelete}>
                <FontAwesomeIcon icon="times" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <hr />
      </Form>
    ));
    if (careerHistory.length) {
      return (
        <Container className="account-form career-history-editor">
          <Row>
            <Col xs={12} className="pt-4">
              <FormLabel rider text="Edit Career History" />
            </Col>
            <Col xs={12}>{careerList}</Col>
          </Row>
        </Container>
      );
    }

    return null;
  }
}
const mapDispatch = {
  listCareerHistory: riderCareerHistoryActions.listCareerHistory,
  createUpdateCareerHistory: riderCareerHistoryActions.createUpdateCareerHistory,
  deleteCareerHistory: riderCareerHistoryActions.deleteCareerHistory
};
function mapStateToProps(state) {
  const {
    myProfile: { user = null },
    riderCareerHistory: { list = null }
  } = state;

  return { user, list };
}
const connectedCareerHistoryEditor = connect(
  mapStateToProps,
  mapDispatch
)(CareerHistoryEditor);
export { connectedCareerHistoryEditor as CareerHistoryEditor };
