import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { EmailInput, PasswordInput } from '../HookInputs';
import { FormLabel } from '../Shared';
import { authenticationActions, accountActions } from '../_actions';
import { useRegistration } from '../_hooks/useRegistration';

export function ResetPassword(props) {
  const dispatch = useDispatch();
  const {
    email,
    setEmail,
    validateEmail,
    emailValid,
    password,
    setPassword,
    validatePassword,
    passwordValid
  } = useRegistration();

  const { code } = props.match.params;

  useEffect(() => {
    dispatch(authenticationActions.logoutNoRedirect());
  }, [dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    if (email && code && password) {
      const data = { email, code, newPassword: password };
      dispatch(accountActions.resetPassword(data));
    }
  };

  const { success = false, error = '' } = props;
  return (
    <Container className="account-container registration" fluid>
      <Helmet title="Reset Your Password - Cycling Connect" />
      <Row style={{ width: `100%` }}>
        {!success ? (
          <Col sm={12} md={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel text="Reset Your Password" />
            <div className="mt-5">
              {!success && error.length > 0 && (
                <p style={{ display: 'block', color: 'red' }}>{error}</p>
              )}
              <Form onSubmit={handleSubmit}>
                <EmailInput
                  valid={emailValid}
                  handleChange={setEmail}
                  value={email}
                  validate={validateEmail}
                />
                <PasswordInput
                  valid={passwordValid}
                  handleChange={setPassword}
                  value={password}
                  validate={validatePassword}
                />
                <Button color="primary">Submit</Button>
                <div className="float-right">
                  <Button tag={Link} color="secondary" to="/register">
                    Register
                  </Button>{' '}
                  <Button color="info" tag={Link} to="/login">
                    Already Registered?
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        ) : (
          <Col sm={12} md={{ size: 6, offset: 3 }} className="account-form rounded">
            <FormLabel text="Password Reset" />
            <p className="message">Your password has been reset, you may now login.</p>
          </Col>
        )}
      </Row>
    </Container>
  );
}
