import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { MarkRead, MarkUnread } from '.';

export const NotificationCard = props => {
  const {
    notification: { id, isRead, url, text, timeAgo },
    toggleRead,
    archive
  } = props;
  return (
    <div className={classnames('notification-card', 'shadow', { unread: !isRead })}>
      <Link to={url || '#'}>
        <div className="body">{text}</div>
        <div className="meta d-flex">
          <div className="time-ago">{timeAgo}</div>
          <div className="actions ml-auto d-flex">
            <div className="archive" onClick={e => archive(e, id)}>
              <FontAwesomeIcon icon="times-circle" size="1x" href="#" id={`archive-${id}`} />
              <UncontrolledTooltip placement="top" target={`archive-${id}`}>
                Archive
              </UncontrolledTooltip>
            </div>
            <div className="mark-read ml-2" onClick={e => toggleRead(e, id)}>
              {isRead ? <MarkUnread id={id} /> : <MarkRead id={id} />}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

NotificationCard.propTypes = {
  toggleRead: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired
};
