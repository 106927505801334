import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export function EmailInput({ valid, handleChange, value, validate }) {
  return (
    <FormGroup>
      <Label for="email" className="sr-only">
        Email
      </Label>
      <Input
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        autoComplete="email"
        required
        value={value}
        invalid={valid != null && !valid}
        valid={valid}
        onChange={e => {
          handleChange(e.target.value);
          validate(e.target.value);
        }}
      />
      <FormFeedback>Please enter a valid email address</FormFeedback>
    </FormGroup>
  );
}

EmailInput.propTypes = {
  valid: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired
};
