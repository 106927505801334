import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

export const Summary = props => {
  const { profile } = props;
  return (
    profile.summary && (
      <Col sm={6}>
        <Col className="shadow team-data summary mb-5 pb-4">
          <FormLabel team text={profile.teamName + ' Summary'} />
          <Row>
            <Col>
              <div dangerouslySetInnerHTML={{ __html: profile.summary }} />
            </Col>
          </Row>
        </Col>
      </Col>
    )
  );
};

Summary.propTypes = {
  profile: PropTypes.object.isRequired
};
