export const contributorConstants = {
  GET_CONTRIBUTORS_REQUEST: 'GET_CONTRIBUTORS_REQUEST',
  GET_CONTRIBUTORS_FAILURE: 'GET_CONTRIBUTORS_FAILURE',
  GET_CONTRIBUTORS_SUCCESS: 'GET_CONTRIBUTORS_SUCCESS',
  ADD_CONTRIBUTOR_REQUEST: 'ADD_CONTRIBUTOR_REQUEST',
  ADD_CONTRIBUTOR_FAILURE: 'ADD_CONTRIBUTOR_FAILURE',
  ADD_CONTRIBUTOR_SUCCESS: 'ADD_CONTRIBUTOR_SUCCESS',
  REMOVE_CONTRIBUTOR_REQUEST: 'REMOVE_CONTRIBUTOR_REQUEST',
  REMOVE_CONTRIBUTOR_FAILURE: 'REMOVE_CONTRIBUTOR_FAILURE',
  REMOVE_CONTRIBUTOR_SUCCESS: 'REMOVE_CONTRIBUTOR_SUCCESS'
};
