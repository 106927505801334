import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { multiSelectStyles } from './MultiSelectStyles';

export const RiderStyleMultiSelect = props => {
  const { styles = [], handleChange } = props;

  const stylesOptions = styles.map(level => {
    return { value: level.riderStyleId, label: level.riderStyleName };
  });

  return (
    <FormGroup>
      <Label>Riding Style</Label>
      <Select
        isMulti
        options={stylesOptions}
        styles={multiSelectStyles}
        name="riderStyleId"
        onChange={val => {
          handleChange({
            target: { name: 'riderStyleId', value: val }
          });
        }}
      />
    </FormGroup>
  );
};
RiderStyleMultiSelect.propTypes = {
  styles: PropTypes.array.isRequired
};
