import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { LatestNews, Notifications, MyConnections } from './';
import { Notices, OpenTeams, Connections } from './Rider';

export const RiderDashboard = () => {
  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          <Notifications rider />
          <MyConnections rider showApproved />
        </Col>
        <Col>
          <Row>
            <Col>
              <OpenTeams />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Connections />
            </Col>
            <Col>
              <LatestNews rider />
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          <Notices />
        </Col>
      </Row>
    </Container>
  );
};
