import React from 'react';
import { Helmet } from 'react-helmet';
import { TeamSearchHeader, TeamSearchForm } from './';
import 'react-input-range/lib/css/index.css';

export const TeamSearch = () => {
  return (
    <div className="search-wrapper">
      <Helmet title="Search for Teams - Cycling Connect" />
      <TeamSearchHeader />
      <TeamSearchForm />
    </div>
  );
};
