import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const StravaInput = props => (
  <FormGroup>
    <Label for="stravaUrl">Strava</Label>
    <Input
      name="stravaUrl"
      id="stravaUrl"
      defaultValue={props.stravaUrl}
      onChange={props.handleChange}
    />
  </FormGroup>
);
StravaInput.propTypes = {
  stravaUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
