import React from 'react';
import PropTypes from 'prop-types';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConnectionsButton } from '../Connections';
import { FollowButton } from '../Follow';

export const ProfileHeader = props => {
  const { profile, user } = props;
  const bgImg = '/img/banners/giro-rosa-bridge-2018.jpg';
  const backgroundStyles = {
    background: `linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.65)), url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: '60% 60%'
  };

  return (
    <Jumbotron className="profile-banner" style={backgroundStyles}>
      <Container>
        <Row>
          {profile && (
            <Col xs={12} sm={6} md={9}>
              <h3 className="display-5">
                {profile.teamName} {profile.seekingRiders && <span> » Seeking Riders</span>}
              </h3>
              {profile.location && (
                <h5>
                  <FontAwesomeIcon icon="map-marker-alt" /> {profile.location}
                </h5>
              )}
              {user && user.isRider && (
                <div>
                  <ConnectionsButton
                    userId={profile.teamManagerId}
                    userName={profile.teamName}
                    size="lg"
                    color="team"
                  />
                  <FollowButton userId={profile.teamManagerId} size="lg" color="team" />
                </div>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </Jumbotron>
  );
};

ProfileHeader.propTypes = {
  profile: PropTypes.object.isRequired,
  user: PropTypes.object
};
