import { riderStylesConstants } from '../_constants';

export function riderStyles(state = {}, action) {
  switch (action.type) {
    case riderStylesConstants.RIDER_STYLES_GET_REQUEST:
    case riderStylesConstants.RIDER_STYLES_GET_FAILURE:
      return {
        ...state
      };
    case riderStylesConstants.RIDER_STYLES_GET_SUCCESS:
      return {
        styles: action.data.riderStyles
      };
    default:
      return state;
  }
}
