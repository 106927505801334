import { todaysPlanConstants } from '../_constants';
export function todaysPlan(state = {}, action) {
  switch (action.type) {
    case todaysPlanConstants.TOKEN_GRANT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false
      };
    case todaysPlanConstants.TOKEN_GRANT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case todaysPlanConstants.TOKEN_GRANT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false
      };
    case todaysPlanConstants.PEAK_W_PER_KG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false
      };
    case todaysPlanConstants.PEAK_W_PER_KG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        chartable: action.data
      };
    case todaysPlanConstants.PEAK_W_PER_KG_FAILURE:
    default:
      return state;
  }
}
