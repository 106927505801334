import { handleResponse, headers, authFetch } from '../_helpers';

export const racingLevelsService = {
  list,
  create,
  update,
  remove,
  getUse
};

async function list() {
  const response = await fetch(`/api/RacingLevels/List`);
  const racingLevels = await handleResponse(response);
  return racingLevels;
}

async function create(newLevel) {
  const response = await authFetch(`/api/RacingLevels/Create`, headers.post(newLevel));
  return response;
}

async function update(racingLevel) {
  const response = await authFetch(`/api/RacingLevels/Update`, headers.post(racingLevel));
  return response;
}

async function remove(deleteRacingLevelDto) {
  const response = await authFetch(`/api/RacingLevels/Remove`, headers.post(deleteRacingLevelDto));
  return response;
}

async function getUse(racingLevelId) {
  const response = await authFetch(
    `/api/RacingLevels/GetUse`,
    headers.postPrimitive(racingLevelId)
  );
  return response;
}
