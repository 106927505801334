import React from 'react';
import PropTypes from 'prop-types';

export function FormLabel({ rider = false, team = false, text }) {
  return <span className={`form-label ${rider ? `rider`:''} ${team ? `team`: ''}`}>{text}</span>;
}

FormLabel.propTypes = {
  rider: PropTypes.bool,
  team: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};
