import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Button } from 'reactstrap';

export const SignUpBanner = () => (
  <Jumbotron fluid className="signup-for-cc">
    <Container>
      <Row>
        <Col sm={8} className="text-center offset-sm-2">
          <h3>Sign up for CyclingConnect</h3>
          <p className="lead">
            For Riders and Teams of all categories across the globe, you'll be able to effortlessly
            stay connected and find people that are the right fit for you.
          </p>
          <Button outline color="primary" size="lg" tag={Link} to="/register">
            Sign up
          </Button>
          <div className="divider" />
          <Button outline color="info" size="lg" tag={Link} to="/login">
            Log in
          </Button>
        </Col>
      </Row>
    </Container>
  </Jumbotron>
);
