import React from 'react';
import { Helmet } from 'react-helmet';
import { RiderSearchHeader, RiderSearchForm } from './';
import 'react-input-range/lib/css/index.css';

export const RiderSearch = () => {
  return (
    <div className="search-wrapper">
      <Helmet title="Search for Riders - Cycling Connect" />
      <RiderSearchHeader />
      <RiderSearchForm />
    </div>
  );
};
