import { headers, authFetch } from '../_helpers';

export const connectionsService = {
  follow,
  unfollow,
  connect,
  approve,
  decline,
  declineAndFollow,
  cancel,
  list,
  remove
};

async function follow(toUserId) {
  const response = await authFetch(`/api/Connections/Follow`, headers.postPrimitive(toUserId));
  return response;
}

async function unfollow(toUserId) {
  const response = await authFetch(`/api/Connections/Unfollow`, headers.postPrimitive(toUserId));
  return response;
}

async function connect(toUserId) {
  const response = await authFetch(`/api/Connections/Connect`, headers.postPrimitive(toUserId));
  return response;
}

async function cancel(toUserId) {
  const response = await authFetch(`/api/Connections/Cancel`, headers.postPrimitive(toUserId));
  return response;
}

async function approve(fromUserId) {
  const response = await authFetch(`/api/Connections/Approve`, headers.postPrimitive(fromUserId));
  return response;
}

async function decline(fromUserId) {
  const response = await authFetch(`/api/Connections/Decline`, headers.postPrimitive(fromUserId));
  return response;
}

async function declineAndFollow(fromUserId) {
  const response = await authFetch(
    `/api/Connections/DeclineFollow`,
    headers.postPrimitive(fromUserId)
  );
  return response;
}

async function list() {
  const response = await authFetch(`/api/Connections/List`, headers.get());
  return response;
}

async function remove(remove) {
  const response = await authFetch(`/api/Connections/Remove`, headers.post(remove));
  return response;
}
