import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../_helpers';
export const NewsItem = props => {
  const { news } = props;
  return (
    <div className="news-item shadow mb-3">
      <Row>
        {news.image && (
          <Col>
            <Link to={`/news/${news.slug}`}>
              <img src={news.image} alt={news.title} className="img-fluid" />
            </Link>
          </Col>
        )}

        <Col>
          <Link to={`/news/${news.slug}`}>
            <h5>{news.title}</h5>
          </Link>
          <p className="text-muted">{dateFormatter(news.datePublished)}</p>
        </Col>
      </Row>
      {news.excerpt && (
        <Row>
          <Col>
            <p className="excerpt">{news.excerpt}</p>
          </Col>
        </Row>
      )}
    </div>
  );
};
