import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

export const NoticeCard = props => {
  const {
    notice,
    notice: { team }
  } = props;
  return (
    <div className="notice-card shadow">
      <div className="notice-detail">
        <strong>{team.teamName} »</strong> <em>{notice.text}</em>. Seeking a{' '}
        {notice.riderStyle ? notice.riderStyle.riderStyleName : 'rider of any style'} located{' '}
        {notice.region.length ? notice.region : 'anywhere'}.{' '}
        <div className="d-flex mt-1 pt-1" style={{ borderTop: '1px solid #303030' }}>
          <div className="flex-fill" id={`n-contact-${notice.id}`}>
            Contacts: {notice.contactsGenerated || 0}
          </div>
          <UncontrolledTooltip target={`n-contact-${notice.id}`}>
            Number of contact email sent to you
          </UncontrolledTooltip>
          <div className="flex-fill" id={`n-views-${notice.id}`}>
            Profile Views: {notice.profileViews || 0}
          </div>
          <UncontrolledTooltip target={`n-views-${notice.id}`}>
            Number of profile views generated by this notice
          </UncontrolledTooltip>
        </div>
      </div>
      {props.children}
    </div>
  );
};
