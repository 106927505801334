import { teamRosterConstants } from '../_constants';
import { teamRosterService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const teamRosterActions = {
  createRoster,
  updateRoster,
  deleteRoster,
  listRoster,
  listRosterForUser,
  riderLookup
};

function createRoster(data) {
  return dispatch => {
    dispatch(request(data));

    teamRosterService.createRoster(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Rider added to your Roster');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to add to your Roster');
      }
    );
  };
  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_CREATE_FAILURE, error };
  }
}

function updateRoster(data) {
  return dispatch => {
    dispatch(request);
    teamRosterService.updateRoster(data).then(
      data => {
        toastr.success('Roster updated');
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_UPDATE_FAILURE, error };
  }
}

function deleteRoster(rosterId) {
  return dispatch => {
    dispatch(request(rosterId));
    teamRosterService.deleteRoster(rosterId).then(
      data => {
        toastr.success('Roster item removed');
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_DELETE_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_DELETE_FAILURE, error };
  }
}

function listRoster(teamId) {
  return dispatch => {
    dispatch(request(teamId));
    teamRosterService.listRoster(teamId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_FAILURE, error };
  }
}

function listRosterForUser() {
  return dispatch => {
    dispatch(request());
    teamRosterService.listRosterForUser().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_LIST_FAILURE, error };
  }
}

function riderLookup(terms) {
  return dispatch => {
    dispatch(request(terms));
    teamRosterService.riderLookup(terms).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_REQUEST, data };
  }
  function success(data) {
    return { type: teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: teamRosterConstants.TEAM_ROSTER_RIDER_LOOKUP_FAILURE, error };
  }
}
