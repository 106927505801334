import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import { connectionsActions } from '../_actions';
import { TeamConnections, RiderConnections, RemoveModal } from '.';

class Connections extends React.Component {
  state = { removeModal: false };
  componentDidMount = () => {
    this.props.list();
  };

  removeModalToggle = e => {
    const { removeModal } = this.state;
    if (!removeModal) {
      const { value } = e.target;
      this.props.setRemovingConnection(value);
    }

    this.setState({ removeModal: !removeModal });
  };

  unfollow = e => {
    const { value } = e.target;
    this.props.unfollow(value);
  };

  connect = (e, userName) => {
    const { value } = e.target;
    this.props.connect(value, userName);
  };

  cancel = e => {
    this.props.cancel(e.target.value);
  };

  declineAndFollow = e => {
    this.props.declineAndFollow(e.target.value);
  };

  follow = e => {
    const { value } = e.target;
    this.props.follow(value);
  };

  approveRequest = e => {
    this.props.approve(e.target.value);
  };

  declineRequest = e => {
    this.props.decline(e.target.value);
  };

  render() {
    const { requests, pending, user, following, approved } = this.props;
    return (
      <div className="connection-requests">
        <Helmet title="Connection Requests - Cycling Connect" />
        <Jumbotron className="mb-0">
          <Container>
            <Row>
              <Col>
                <h2 className="display-4">Connection Requests</h2>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container fluid>
          {user.isRider && (
            <RiderConnections
              requests={requests}
              pending={pending}
              following={following}
              approved={approved}
              decline={this.declineRequest}
              approve={this.approveRequest}
              follow={this.follow}
              unfollow={this.unfollow}
              declineAndFollow={this.declineAndFollow}
              connect={this.connect}
              cancel={this.cancel}
              removeModalToggle={this.removeModalToggle}
            />
          )}
          {user.isTeamManager && (
            <TeamConnections
              requests={requests}
              pending={pending}
              following={following}
              approved={approved}
              decline={this.declineRequest}
              approve={this.approveRequest}
              follow={this.follow}
              unfollow={this.unfollow}
              declineAndFollow={this.declineAndFollow}
              connect={this.connect}
              cancel={this.cancel}
              removeModalToggle={this.removeModalToggle}
            />
          )}
        </Container>
        <RemoveModal isOpen={this.state.removeModal} toggle={this.removeModalToggle} />
      </div>
    );
  }
}
const mapDispatch = {
  list: connectionsActions.list,
  setRemovingConnection: connectionsActions.setRemovingConnection,
  unfollow: connectionsActions.unfollow,
  connect: connectionsActions.connect,
  cancel: connectionsActions.cancel,
  declineAndFollow: connectionsActions.declineAndFollow,
  follow: connectionsActions.follow,
  approve: connectionsActions.approve,
  decline: connectionsActions.decline
};
function mapStateToProps(state) {
  const {
    connections: { requests, pending, following, approved },
    authentication: { user }
  } = state;
  return { requests, pending, user, following, approved };
}

const connectedConnections = connect(
  mapStateToProps,
  mapDispatch
)(Connections);
export { connectedConnections as Connections };
