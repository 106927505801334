import React, { Fragment } from 'react';
import { NavMenu } from './NavMenu';
import Footer from './Footer';
import { UserAnnouncements } from './UserAnnouncements';

export function Layout(props) {
  return (
    <Fragment>
      <NavMenu />
      <UserAnnouncements />
      {props.children}
      <Footer />
    </Fragment>
  );
}
