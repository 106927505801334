import React from 'react';
import { Form, Container, Row, Col, FormGroup, Label, Button, Input } from 'reactstrap';
import { FormLabel } from '../Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class CareerHighlightsItems extends React.Component {
  state = {};

  createUpdate = (e, id) => {
    e.preventDefault();
    const highlightItem = this.props.highlights.list.find(i => i.id === id);
    const careerHighlight = { ...highlightItem, ...this.state[id] };
    this.props.handleUpdate(careerHighlight);
  };

  handleChange = (e, id) => {
    const { name, value } = e.target;
    this.setState({ [id]: { [name]: value } });
  };

  render() {
    const { highlights } = this.props;
    let highlightList;
    if (highlights && highlights.list) {
      highlightList = highlights.list.map(h => (
        <CareerHighlightItem
          place={h.place}
          event={h.event}
          key={h.id}
          id={h.id}
          handleChange={this.handleChange}
          createUpdate={this.createUpdate}
          handleDelete={this.props.handleDelete}
        />
      ));
    }
    return (
      <Container className="account-form career-highlights-editor">
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel rider text="Edit Career Highlights" />
          </Col>
          <Col xs={12}>{highlightList}</Col>
        </Row>
      </Container>
    );
  }
}

function CareerHighlightItem(props) {
  return (
    <Form
      id={props.id}
      onSubmit={e => props.createUpdate(e, props.id)}
      className="career-highlight-item"
    >
      <Row>
        <Col md={4} lg={3}>
          <FormGroup>
            <Label>Race Result</Label>
            <Input
              type="number"
              name="place"
              defaultValue={props.place}
              onChange={e => props.handleChange(e, props.id)}
              required
            />
          </FormGroup>
        </Col>
        <Col md={8} lg={7} xl={6}>
          <FormGroup>
            <Label>Event</Label>
            <Input
              name="event"
              defaultValue={props.event}
              onChange={e => props.handleChange(e, props.id)}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={6} md={2} lg={1} className="col-xl">
          <Label>Save</Label>
          <FormGroup>
            <Button block outline color="primary">
              <FontAwesomeIcon icon="check" />
            </Button>
          </FormGroup>
        </Col>
        <Col xs={6} md={2} lg={1} className="col-xl">
          <Label>Delete</Label>
          <FormGroup>
            <Button block outline color="danger" onClick={e => props.handleDelete(e, props.id)}>
              <FontAwesomeIcon icon="times" />
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <hr />
    </Form>
  );
}
