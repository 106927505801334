import React from 'react';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
export const RecruitManagerHeader = () => (
  <Jumbotron>
    <Container>
      <Row>
        <Col>
          <h2 className="display-4">Recruitment Manager</h2>
        </Col>
      </Row>
    </Container>
  </Jumbotron>
);
