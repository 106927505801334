import { announcementService } from '../_services';
import { announcementConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const announcementActions = {
  create,
  remove,
  listAll,
  listForUser,
  hide
};

function create(ann) {
  return dispatch => {
    dispatch(request(ann));
    announcementService.create(ann).then(
      data => {
        dispatch(success(data));
        toastr.success('Announcement Created');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: announcementConstants.CREATE_ANNOUNCEMENT_REQUEST, data };
  }
  function success(data) {
    return { type: announcementConstants.CREATE_ANNOUNCEMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: announcementConstants.CREATE_ANNOUNCEMENT_FAILURE, error };
  }
}

function remove(announcementId) {
  return dispatch => {
    dispatch(request(announcementId));
    announcementService.remove(announcementId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: announcementConstants.DELETE_ANNOUNCEMENT_REQUEST, data };
  }
  function success(data) {
    return { type: announcementConstants.DELETE_ANNOUNCEMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: announcementConstants.DELETE_ANNOUNCEMENT_FAILURE, error };
  }
}

function listAll() {
  return dispatch => {
    dispatch(request());
    announcementService.listAll().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_REQUEST, data };
  }
  function success(data) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_FAILURE, error };
  }
}
function listForUser() {
  return dispatch => {
    dispatch(request());
    announcementService.listForUser().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_REQUEST, data };
  }
  function success(data) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: announcementConstants.GET_ANNOUNCEMENTS_FOR_USER_FAILURE, error };
  }
}

function hide(announcementId) {
  return dispatch => {
    dispatch(request(announcementId));
    announcementService.hide(announcementId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: announcementConstants.HIDE_ANNOUNCEMENT_REQUEST, data };
  }
  function success(data) {
    return { type: announcementConstants.HIDE_ANNOUNCEMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: announcementConstants.HIDE_ANNOUNCEMENT_FAILURE, error };
  }
}
