import { adminConstants } from '../_constants';

export function admin(state = {}, action) {
  switch (action.type) {
    case adminConstants.GET_SITE_SETTINGS_REQUEST:
    case adminConstants.GET_SITE_SETTINGS_FAILURE:
    case adminConstants.UPDATE_SITE_SETTINGS_REQUEST:
    case adminConstants.UPDATE_SITE_SETTINGS_FAILURE:
    case adminConstants.LIST_ADMINS_REQUEST:
    case adminConstants.LIST_ADMINS_FAILURE:
    case adminConstants.USER_LOOKUP_REQUEST:
    case adminConstants.USER_LOOKUP_FAILURE:
    case adminConstants.MAKE_USER_ADMIN_REQUEST:
    case adminConstants.MAKE_USER_ADMIN_FAILURE:
    case adminConstants.REVOKE_USER_ADMIN_REQUEST:
    case adminConstants.REVOKE_USER_ADMIN_FAILURE:
      return {
        ...state
      };
    case adminConstants.LIST_ADMINS_SUCCESS:
      return {
        ...state,
        admins: [...action.data]
      };
    case adminConstants.USER_LOOKUP_SUCCESS:
      return {
        ...state,
        lookup: [...action.data]
      };
    case adminConstants.MAKE_USER_ADMIN_SUCCESS:
      return {
        ...state,
        admins: [...state.admins, action.data]
      };
    case adminConstants.REVOKE_USER_ADMIN_SUCCESS:
      const updatedAdmins = state.admins.filter(ad => ad.id !== action.data);
      return {
        ...state,
        admins: [...updatedAdmins]
      };
    case adminConstants.GET_SITE_SETTINGS_SUCCESS:
    case adminConstants.UPDATE_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data
      };
    default:
      return state;
  }
}
