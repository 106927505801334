import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { NoticeHeader, NoticeCard, NoticeActions } from '.';
import { noticeActions } from '../_actions';
import { FormLabel } from '../Shared';
import isEmpty from 'lodash/isEmpty';
import { PlaceholderCard } from '../Shared';

class Notices extends React.Component {
  state = { hasLoadedExpired: false };
  componentDidMount = () => {
    this.props.listTeamNotices();
  };

  loadExpired = () => {
    this.setState({ hasLoadedExpired: true });
    this.props.listExpiredTeamNotices();
  };

  handleExtend = noticeId => {
    this.props.extend(noticeId);
  };

  handleCancel = noticeId => {
    this.props.cancel(noticeId);
  };

  render() {
    const { current, expired } = this.props;
    const { hasLoadedExpired } = this.state;
    return (
      <Fragment>
        <Helmet title="Notices - Cycling Connect" />
        <NoticeHeader />
        <Container className="grey-bg mb-3 mt-2">
          <Row>
            <Col xs={12}>
              <FormLabel team text="Current Notices" />
            </Col>
          </Row>
          <Row className="mt-5 pb-3">
            {isEmpty(current) && (
              <Col>
                <p>You have no active notices.</p>
              </Col>
            )}
            {current.map((cur, index) => (
              <Col xs={12} sm={6} md={4} key={index}>
                <NoticeCard notice={cur}>
                  <NoticeActions
                    notice={cur}
                    handleCancel={this.handleCancel}
                    handleExtend={this.handleExtend}
                  />
                </NoticeCard>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className="grey-bg mb-3 mt-5">
          <Row>
            <Col>
              <FormLabel team text="Expired Notices" />
            </Col>
          </Row>

          <Row className="mt-5 pb-3">
            {isEmpty(expired) && !hasLoadedExpired && (
              <Col sm={4}>
                <PlaceholderCard>
                  <p>
                    <Button color="info" onClick={this.loadExpired}>
                      Load Expired
                    </Button>
                  </p>
                </PlaceholderCard>
              </Col>
            )}
            {isEmpty(expired) && hasLoadedExpired && (
              <Col sm={4}>
                <PlaceholderCard message="No expired Notices to show." />
              </Col>
            )}
            {expired.map((exp, index) => (
              <Col xs={12} sm={6} md={4} key={index}>
                <NoticeCard notice={exp} />
              </Col>
            ))}
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  listTeamNotices: noticeActions.listTeamNotices,
  listExpiredTeamNotices: noticeActions.listExpiredTeamNotices,
  extend: noticeActions.extend,
  cancel: noticeActions.cancel
};
function mapStateToProps(state) {
  const { current = [], expired = [] } = state.notice;
  return { current, expired };
}
const connectedNotices = connect(
  mapStateToProps,
  mapDispatch
)(Notices);
export { connectedNotices as Notices };
