import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from 'reactstrap';

export const PendingFooter = props => (
  <ButtonGroup className="btn-block">
    <Button
      className="col"
      to={`/${props.isRider ? 'rider' : 'team'}/${props.user.profileSlug}`}
      tag={Link}
      color="primary"
    >
      Profile
    </Button>
    <Button
      className="col"
      color="warning"
      value={props.user.id}
      onClick={props.follow}
      disabled={props.disableFollow}
    >
      Follow
    </Button>
    <Button className="col" color="danger" value={props.user.id} onClick={props.cancel}>
      Cancel
    </Button>
  </ButtonGroup>
);

PendingFooter.propTypes = {
  user: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  follow: PropTypes.func.isRequired,
  disableFollow: PropTypes.bool.isRequired,
  isRider: PropTypes.bool
};
