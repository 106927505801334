export const multiSelectStyles = {
  option: base => ({
    ...base
  }),
  container: () => ({
    border: 'none'
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    background: `#fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5rem center`,
    backgroundSize: `8px 10px`,
    boxShadow: 'none',
    border: 'none',
    borderRadius: 0,
    borderBottom: isFocused ? '2px solid #3498db' : '2px solid #ced4da',
    ':hover': {
      border: 'none',

      borderBottom: '2px solid #222'
    },
    ':active': {
      border: 'none',
      borderBottom: '2px solid #3498db'
    }
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: '22px'
  }),
  multiValue: styles => ({
    ...styles,
    color: 'red',
    backgroundColor: '#58abc3'
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: '#f2f2f2'
  }),
  menu: () => ({
    borderBottom: '2px solid #3498db',
    position: 'absolute',
    zIndex: 1,
    width: 'calc(100% - 30px)',
    backgroundColor: '#fff'
  })
};
