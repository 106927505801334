import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FollowButton } from '../../Follow';
import { ConnectionsButton } from '../../Connections';
import { FlagIcon } from '../../Shared';

export function SuggestedRider(props) {
  const { rider } = props;
  const imageStyles = {
    background: `url('${rider.profilePhoto}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  };
  const profileUrl = `/rider/${rider.profileSlug}`;

  const profilePhoto = <Link to={profileUrl} style={imageStyles} className="flex-grow-1" />;

  const withoutPhoto = (
    <Link to={profileUrl} className="align-middle text-center mx-auto placeholder">
      <FontAwesomeIcon icon="user" size="5x" className="img-fluid" />
    </Link>
  );

  return (
    <div className="suggested-rider shadow mb-2">
      <Row>
        <Col xs={3} className="d-flex">
          {rider.profilePhoto ? profilePhoto : withoutPhoto}
        </Col>
        <Col xs={9} md={9}>
          <Row>
            <Col tag={Link} to={profileUrl}>
              <p>
                <strong>
                  {rider.displayName} {rider.country && `(${rider.country})`}
                </strong>{' '}
              </p>

              <p>
                {rider.countryCode && (
                  <span>
                    <FlagIcon code={rider.countryCode.toLowerCase()} /> {rider.nationality}.
                  </span>
                )}
                {rider.ridingStyleName && ` ${rider.ridingStyleName}.`}
                {rider.ridingLevel && ` ${rider.ridingLevel}.`}
              </p>
            </Col>

            <Col md={4}>
              <FollowButton color="rider" block userId={rider.id} />
              <hr style={{ margin: `0.25rem 0` }} />
              <ConnectionsButton
                color="rider"
                block
                userId={rider.id}
                userName={rider.displayName}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
