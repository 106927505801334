import { headers, authFetch } from '../_helpers';

export const adminService = {
  listAdmins,
  userLookup,
  makeUserAdmin,
  revokeAdmin,
  getSettings,
  updateSettings
};

async function listAdmins() {
  const res = await authFetch(`/api/UserManagement/ListAdmins`, headers.get());
  return res;
}

async function userLookup(terms) {
  const res = await authFetch(`/api/UserManagement/UserLookup`, headers.postPrimitive(terms));
  return res;
}

async function makeUserAdmin(userId) {
  const res = await authFetch(`/api/UserManagement/MakeUserAdmin`, headers.postPrimitive(userId));
  return res;
}

async function revokeAdmin(userId) {
  const res = await authFetch(`/api/UserManagement/RevokeAdmin`, headers.postPrimitive(userId));
  return res;
}

async function getSettings() {
  const res = await authFetch(`/api/AdminTools/GetSettings`, headers.get());
  return res;
}

async function updateSettings(settings) {
  const res = await authFetch(`/api/AdminTools/UpdateSettings`, headers.post(settings));
  return res;
}
