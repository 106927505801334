import { riderRefereeConstants } from '../_constants';
import { riderRefereeService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const riderRefereeActions = {
  create,
  update,
  list,
  deleteReferee
};

function create(data) {
  return dispatch => {
    dispatch(request(data));

    riderRefereeService.createReferee(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Referee added to your profile');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to add referee');
      }
    );
  };
  function request(data) {
    return { type: riderRefereeConstants.CREATE_REFEREE_REQUEST, data };
  }
  function success(data) {
    return { type: riderRefereeConstants.CREATE_REFEREE_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderRefereeConstants.CREATE_REFEREE_FAILURE, error };
  }
}

function update(data) {
  return dispatch => {
    dispatch(request);
    riderRefereeService.updateReferee(data).then(
      data => {
        toastr.success('Referee updated');
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderRefereeConstants.UPDATE_REFEREE_REQUEST, data };
  }
  function success(data) {
    return { type: riderRefereeConstants.UPDATE_REFEREE_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderRefereeConstants.UPDATE_REFEREE_FAILURE, error };
  }
}

function deleteReferee(refereeId) {
  return dispatch => {
    dispatch(request(refereeId));
    riderRefereeService.deleteReferee(refereeId).then(
      data => {
        toastr.success('Referee removed');
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderRefereeConstants.DELETE_REFEREE_REQUEST, data };
  }
  function success(data) {
    return { type: riderRefereeConstants.DELETE_REFEREE_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderRefereeConstants.DELETE_REFEREE_FAILURE, error };
  }
}

function list() {
  return dispatch => {
    dispatch(request());
    riderRefereeService.listReferees().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: riderRefereeConstants.LIST_REFEREES_REQUEST, data };
  }
  function success(data) {
    return { type: riderRefereeConstants.LIST_REFEREES_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderRefereeConstants.LIST_REFEREES_FAILURE, error };
  }
}
