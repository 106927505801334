import React from 'react';
import { FormLabel } from '../Shared';
import { FacebookInput, TwitterInput, YouTubeInput, InstagramInput, WebsiteInput } from '../Inputs';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

export const TeamSocialEdit = props => {
  const { profile, handleChange } = props;
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team text="Social" />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <FacebookInput facebookUrl={profile.facebookUrl} handleChange={handleChange} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <TwitterInput twitterUrl={profile.twitterUrl} handleChange={handleChange} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <YouTubeInput youTubeUrl={profile.youTubeUrl} handleChange={handleChange} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <InstagramInput instagramUrl={profile.instagramUrl} handleChange={handleChange} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <WebsiteInput websiteUrl={profile.websiteUrl} handleChange={handleChange} />
        </Col>
      </Row>
    </Container>
  );
};

TeamSocialEdit.propTypes = {
  handleChange: PropTypes.func.isRequired
};
