import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { RefereeTypeSelect } from './';
import { FormLabel } from '../Shared';
import { riderRefereeActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RefereeEditor extends React.Component {
  state = {};

  handleChange = (e, id) => {
    const { name, value } = e.target;
    this.setState({ [id]: { [name]: value } });
  };

  componentDidMount = () => {
    this.props.list();
  };

  handleSubmit = (e, id) => {
    e.preventDefault();
    const referee = this.props.riderReferees.list.find(i => i.id === id);
    const updatedReferee = { ...referee, ...this.state[id] };
    this.props.update(updatedReferee);
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    if (id) {
      this.props.deleteReferee(id);
    }
  };

  render() {
    const {
      riderReferees: { loading = true, list },
      refereeTypes
    } = this.props;

    if (loading) {
      return <div>loading</div>;
    }

    return (
      <Container className="account-form referee-editor">
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel rider text="Edit Referees" />
          </Col>
          <Col>
            {!loading &&
              list.map((ref, index) => (
                <RefereeItem
                  key={index}
                  id={ref.id}
                  name={ref.name}
                  text={ref.text}
                  contact={ref.contact}
                  refereeTypeId={ref.refereeTypeId}
                  refereeTypes={refereeTypes.types}
                  handleChange={this.handleChange}
                  handleDelete={this.handleDelete}
                  handleSubmit={this.handleSubmit}
                />
              ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

function RefereeItem(props) {
  const {
    id,
    name,
    text,
    contact,
    refereeTypeId,
    refereeTypes,
    handleChange,
    handleSubmit,
    handleDelete
  } = props;
  return (
    <Form onSubmit={e => handleSubmit(e, id)} className="referee-item">
      <Row>
        <Col xs={6} lg={{ size: 4, order: 1 }}>
          <FormGroup>
            <Label for={`name-${id}`}>Referee Name</Label>
            <Input
              type="text"
              name="name"
              id={`name-${id}`}
              defaultValue={name}
              onChange={e => handleChange(e, id)}
            />
          </FormGroup>
        </Col>
        <Col xs={6} lg={{ size: 4, order: 2 }}>
          <FormGroup>
            <Label for={`contact-${id}`}>Contact Detail</Label>
            <Input
              type="text"
              name="contact"
              id={`contact-${id}`}
              defaultValue={contact}
              onChange={e => handleChange(e, id)}
            />
          </FormGroup>
        </Col>
        <Col xs={12} lg={{ size: 9, order: 4 }}>
          <FormGroup>
            <Label for={`text-${id}`}>Reference</Label>
            <Input
              type="textarea"
              name="text"
              id={`text-${id}`}
              defaultValue={text}
              onChange={e => handleChange(e, id)}
            />
          </FormGroup>
        </Col>
        <Col xs={6} lg={{ size: 4, order: 3 }}>
          {refereeTypes && (
            <RefereeTypeSelect
              currentRefereeType={refereeTypeId}
              types={refereeTypes}
              handleChange={handleChange}
              id={id}
            />
          )}
        </Col>
        <Col lg={{ size: 3, order: 5 }}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Save</Label>
                <Button block outline color="primary">
                  <FontAwesomeIcon icon="check" />
                </Button>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Delete</Label>
                <Button block outline color="danger" onClick={e => handleDelete(e, id)}>
                  <FontAwesomeIcon icon="times" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </Form>
  );
}
const mapDispatch = {
  list: riderRefereeActions.list,
  update: riderRefereeActions.update,
  deleteReferee: riderRefereeActions.deleteReferee
};
function mapStateToProps(state) {
  const { riderReferees, refereeTypes } = state;
  return { riderReferees, refereeTypes };
}

const connectedRefereeEditor = connect(
  mapStateToProps,
  mapDispatch
)(RefereeEditor);
export { connectedRefereeEditor as RefereeEditor };
