import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

export const MarkUnread = props => (
  <div>
    <FontAwesomeIcon icon={['far', 'circle']} href="#" id={`mark-unread-${props.id}`} />
    <UncontrolledTooltip placement="top" target={`mark-unread-${props.id}`}>
      Mark unread
    </UncontrolledTooltip>
  </div>
);
