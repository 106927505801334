import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { FormLabel } from '../Shared';
import { Trophy } from '.';

export const CareerHighlights = props => {
  const { careerHighlights } = props;

  if (isEmpty(careerHighlights)) {
    return null;
  }
  return (
    <Col xs={6}>
      <Col className="shadow rider-data mb-5  career-highlights">
        <FormLabel rider text="Career Highlights" />
        <Row className="highlights">
          {careerHighlights.map((h, index) => (
            <Col sm={4} className="highlight-card" key={index}>
              <Card className="shadow flex-grow-1">
                <CardBody className="wreath">
                  <Trophy highlight={h} />
                </CardBody>
                <CardFooter className="highlight-footer">{h.event}</CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Col>
  );
};

CareerHighlights.propTypes = {
  careerHighlights: PropTypes.array
};
