import { raceConstants } from '../_constants';
import { raceService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const raceActions = {
  listRaces,
  listRaceResults,
  createRace,
  updateRace,
  createRaceResult,
  updateRaceResult,
  deleteRaceResult
};
function updateRaceResult(result) {
  return dispatch => {
    dispatch(request(result));
    raceService.updateRaceResult(result).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.UPDATE_RESULT_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.UPDATE_RESULT_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.UPDATE_RESULT_FAILURE, error };
  }
}

function deleteRaceResult(resultId) {
  return dispatch => {
    dispatch(request(resultId));
    raceService.deleteRaceResult(resultId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.DELETE_RESULT_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.DELETE_RESULT_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.DELETE_RESULT_FAILURE, error };
  }
}

function listRaces() {
  return dispatch => {
    dispatch(request());
    raceService.listRaces().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.LIST_RACES_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.LIST_RACES_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.LIST_RACES_FAILURE, error };
  }
}

function listRaceResults(raceId) {
  return dispatch => {
    dispatch(request(raceId));
    raceService.listRaceResults(raceId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(raceId) {
    return { type: raceConstants.LIST_RESULTS_REQUEST, raceId };
  }
  function success(data) {
    return { type: raceConstants.LIST_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.LIST_RESULTS_FAILURE, error };
  }
}

function createRace(race) {
  return dispatch => {
    dispatch(request(race));
    raceService.createRace(race).then(
      data => {
        dispatch(success(data));
        toastr.success('New Race created!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.CREATE_RACE_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.CREATE_RACE_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.CREATE_RACE_FAILURE, error };
  }
}

function updateRace(updatedRace) {
  return dispatch => {
    dispatch(request(updatedRace));
    raceService.updateRace(updatedRace).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.UPDATE_RACE_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.UPDATE_RACE_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.UPDATE_RACE_FAILURE, error };
  }
}

function createRaceResult(data) {
  return dispatch => {
    dispatch(request(data));
    raceService.createRaceResult(data).then(
      data => {
        dispatch(success(data));
        toastr.success('New Race Result created!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: raceConstants.CREATE_RESULT_REQUEST, data };
  }
  function success(data) {
    return { type: raceConstants.CREATE_RESULT_SUCCESS, data };
  }
  function failure(error) {
    return { type: raceConstants.CREATE_RESULT_FAILURE, error };
  }
}
