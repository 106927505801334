import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Jumbotron, Container, Row, Col } from 'reactstrap';
import { ConnectionsButton } from '../Connections';
import { FollowButton } from '../Follow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlagIcon } from '../Shared';

export const ProfileHeader = props => {
  const {
    userDetails,
    user,
    userDetails: { riderProfile }
  } = props;
  const bgImg = '/img/banners/rvv-2018.jpg';
  const backgroundStyles = {
    background: `linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)), url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: '60% 40%'
  };

  return (
    <Jumbotron className="profile-banner mb-0" style={backgroundStyles}>
      <Container>
        <Row>
          {riderProfile && (
            <Col>
              <h3 className="display-5">
                {userDetails.displayName}{' '}
                {riderProfile.countryCode && (
                  <FlagIcon code={riderProfile.countryCode.toLowerCase()} />
                )}
              </h3>
              <h5>
                <FontAwesomeIcon icon="map-marker-alt" /> {riderProfile.location}
              </h5>
              {user && user.isTeamManager && (
                <Fragment>
                  <ConnectionsButton
                    userId={riderProfile.riderId}
                    handleClick={props.connectionRequest}
                    userName={userDetails.displayName}
                    size="lg"
                    color={'rider'}
                  />
                  <FollowButton color="rider" userId={riderProfile.riderId} size="lg" />
                </Fragment>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </Jumbotron>
  );
};

ProfileHeader.propTypes = {
  userDetails: PropTypes.object.isRequired,
  user: PropTypes.object
};
