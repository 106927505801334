import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminHeader } from './';
import { adminActions } from '../_actions';
class SiteSettings extends React.Component {
  state = {};

  componentDidMount = () => {
    this.props.getSettings();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = { ...this.props.settings, ...this.state };
    this.props.updateSettings(data);
  };

  render() {
    const { settings } = this.props;
    return (
      <Fragment>
        <Helmet title="Site Settings - Cycling Connect" />
        <AdminHeader title="Site Settings" />
        <Container className="mb-4">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={12} lg={3}>
                <FormGroup>
                  <Label for="daysFeatured" id="feature-tooltip">
                    Days to Feature <FontAwesomeIcon icon="question-circle" />
                  </Label>
                  <UncontrolledTooltip placement="top" target="feature-tooltip">
                    The number of days to show latest Teams and Riders on the Dashboard (if
                    applicable).
                  </UncontrolledTooltip>
                  <Input
                    name="daysFeatured"
                    id="daysFeatured"
                    type="number"
                    min="1"
                    onChange={this.handleChange}
                    defaultValue={settings ? settings.daysFeatured : ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button color="success">Save</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  getSettings: adminActions.getSettings,
  updateSettings: adminActions.updateSettings
};
function mapStateToProps(state) {
  const { settings = null } = state.admin;
  return { settings };
}
const connectedSiteSettings = connect(
  mapStateToProps,
  mapDispatch
)(SiteSettings);
export { connectedSiteSettings as SiteSettings };
