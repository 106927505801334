import { dashboardConstants } from '../_constants';
const initialState = {
  openingsLoading: false,
  news: [],
  suggested: [],
  openings: []
};
export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.SUGGESTED_CONNECTIONS_REQUEST:
      return {
        ...state,
        openingsLoading: true
      };
    case dashboardConstants.LATEST_NEWS_REQUEST:
    case dashboardConstants.TEAM_OPENINGS_REQUEST:
      return {
        ...state
      };
    case dashboardConstants.LATEST_NEWS_SUCCESS:
      return {
        ...state,
        news: [...action.data]
      };
    case dashboardConstants.SUGGESTED_CONNECTIONS_SUCCESS:
      return {
        ...state,
        suggested: [...action.data]
      };
    case dashboardConstants.TEAM_OPENINGS_SUCCESS:
      return {
        ...state,
        openings: [...action.data],
        openingsLoading: false
      };
    case dashboardConstants.TEAM_OPENINGS_FAILURE:
    case dashboardConstants.LATEST_NEWS_FAILURE:
    case dashboardConstants.SUGGESTED_CONNECTIONS_FAILURE:
    default:
      return state;
  }
}
