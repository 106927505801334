import React from 'react';
import { Table, Button } from 'reactstrap';
import { dateHelper as dh } from '../_helpers';

export function AllAnnouncements(props) {
  const { all, handleDelete } = props;
  return (
    <Table bordered striped hover>
      <thead className="thead-dark">
        <tr>
          <th>Created</th>
          <th>Expires</th>
          <th>Content</th>
          <th>Display To</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {all.map((ann, idx) => (
          <tr key={idx}>
            <td>{dh.toShort(ann.created)}</td>
            <td>{dh.toShort(ann.expires)}</td>
            <td>{ann.content}</td>
            <td>
              {ann.riders && 'riders '} {ann.teams && 'teams'}
            </td>
            <td>
              <Button color="warning" onClick={() => handleDelete(ann.id)}>
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
