import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { newsActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';

class CategoryCreate extends React.Component {
  state = {};

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name } = this.state;
    if (name) {
      this.props.createCategory(name);
    }
  };

  render() {
    return (
      <Container className="account-form">
        <Row>
          <Col className="mb-4">
            <FormLabel text="Add Category" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={this.handleSubmit}>
              <InputGroup>
                <Input name="name" onChange={this.handleChange} />
                <InputGroupAddon addonType="append">
                  <Button color="success">
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapDispatch = {
  createCategory: newsActions.createCategory
};
function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const connectedCategoryCreate = connect(
  mapStateToProps,
  mapDispatch
)(CategoryCreate);
export { connectedCategoryCreate as CategoryCreate };
