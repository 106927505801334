import React, { Fragment } from 'react';
import { RiderCard, CardFooter } from './';
import { Droppable } from 'react-beautiful-dnd';
import { Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

export class RiderColumn extends React.Component {
  render() {
    const { riders, contactToggle, notesToggle } = this.props;
    return (
      <Fragment>
        <Col className="recruit-column grey-bg pb-3">
          <Row style={{ marginBottom: `2.25rem` }}>
            <Col>
              <FormLabel team text={this.props.stage.name} />
            </Col>
          </Row>
          <Droppable droppableId={this.props.stage.kebabName}>
            {(provided, snapshot) => (
              <div
                className={`recruit-list d-flex flex-column ${
                  snapshot.isDraggingOver ? 'dragging-over' : ''
                }`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {riders.length > 0 &&
                  riders.map((rec, index) => (
                    <RiderCard key={rec.id} dragId={rec.id} rider={rec.rider} index={index}>
                      <CardFooter
                        user={rec.rider}
                        id={rec.id}
                        contactToggle={contactToggle}
                        notesToggle={notesToggle}
                      />
                    </RiderCard>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Col>
      </Fragment>
    );
  }
}
