import { riderRefereeConstants } from '../_constants';

export function riderReferees(state = {}, action) {
  switch (action.type) {
    case riderRefereeConstants.CREATE_REFEREE_REQUEST:
    case riderRefereeConstants.CREATE_REFEREE_FAILURE:
    case riderRefereeConstants.DELETE_REFEREE_REQUEST:
    case riderRefereeConstants.LIST_REFEREES_FAILURE:
      return {
        ...state
      };
    case riderRefereeConstants.DELETE_REFEREE_SUCCESS:
    case riderRefereeConstants.CREATE_REFEREE_SUCCESS:
      return {
        ...state,
        list: action.data
      };
    case riderRefereeConstants.LIST_REFEREES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case riderRefereeConstants.LIST_REFEREES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data
      };
    case riderRefereeConstants.DELETE_REFEREE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
