import { account } from './account.reducer';
import { authentication } from './authentication.reducer';
import { notice } from './notice.reducer';
import { combineReducers } from 'redux';
import { connections } from './connections.reducer';
import { dashboard } from './dashboard.reducer';
import { myProfile } from './myProfile.reducer';
import { news } from './news.reducer';
import { racingLevels } from './racingLevels.reducer';
import { recruit } from './recruit.reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { refereeTypes } from './refereeTypes.reducer';
import { removeState } from '../_helpers';
import { riderAgeCategories } from './riderAgeCategories.reducer';
import { riderCareerHighlights } from './riderCareerHighlights.reducer';
import { riderCareerHistory } from './riderCareerHistory.reducer';
import { riderPower } from './riderPower.reducer';
import { riderProfiles } from './riderProfiles.reducer';
import { riderReferees } from './riderReferee.reducer';
import { riderSearch } from './riderSearch.reducer';
import { riderSexes } from './riderSexes.reducer';
import { riderStyles } from './riderStyles.reducer';
import { teamProfiles } from './teamProfiles.reducer';
import { teamRoster } from './teamRoster.reducer';
import { teamSearch } from './teamSearch.reducer';
import { races } from './race.reducer';
import { admin } from './admin.reducer';
import { announcement } from './announcement.reducer';
import { todaysPlan } from './todaysPlan.reducer';
import { contributors } from './contributor.reduder';
import { article } from './article.reducer';
import { register } from './register.reducer';
const appReducer = combineReducers({
  account,
  article,
  authentication,
  register,
  admin,
  announcement,
  notice,
  connections,
  contributors,
  dashboard,
  myProfile,
  news,
  racingLevels,
  refereeTypes,
  recruit,
  riderAgeCategories,
  riderCareerHighlights,
  riderCareerHistory,
  riderPower,
  riderProfiles,
  riderReferees,
  riderSearch,
  riderSexes,
  riderStyles,
  teamProfiles,
  teamRoster,
  teamSearch,
  todaysPlan,
  races,
  toastr: toastrReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USERS_LOGOUT') {
    state = undefined;
    removeState();
  }
  return appReducer(state, action);
};

export default rootReducer;
