import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';

export const RefereeTypeSelect = props => {
  const { types, handleChange, currentRefereeType, fromState, id } = props;
  const optionItems = types.map((type, index) => (
    <option key={index} value={type.id}>
      {type.name}
    </option>
  ));
  return (
    <FormGroup>
      <Label for="refereeTypeId">Relationship</Label>

      <Input
        type="select"
        className="custom-select"
        name="refereeTypeId"
        value={fromState}
        defaultValue={currentRefereeType}
        onChange={e => handleChange(e, id)}
        required
      >
        {currentRefereeType !== 'undefined' && <option>Choose...</option>}

        {optionItems}
      </Input>
    </FormGroup>
  );
};

RefereeTypeSelect.propTypes = {
  types: PropTypes.array.isRequired,
  currentRefereeType: PropTypes.number,
  handleChange: PropTypes.func.isRequired
};
