import { riderStylesConstants } from '../_constants';
import { riderStylesService } from '../_services';

export const riderStylesActions = {
  getRiderStyles
};

function getRiderStyles() {
  return dispatch => {
    dispatch(request());

    riderStylesService.getriderStyles().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: riderStylesConstants.RIDER_STYLES_GET_REQUEST, data };
  }
  function success(data) {
    return { type: riderStylesConstants.RIDER_STYLES_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: riderStylesConstants.RIDER_STYLES_GET_FAILURE, error };
  }
}
