import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { SignUpBanner } from '../Home';

export const About = () => {
  return (
    <Fragment>
      <Helmet title="About - Cycling Connect" />
      <Container>
        <Row>
          <Col>
            <h3 className="mt-4">About Us</h3>
            <p className="lead">
              <em>How do you connect with your dream team... or find your perfect rider?</em>
            </p>
            <p>
              Cycling connect is a hub that brings together riders and teams to create an open
              ecosystem. We help to initiate meaningful dialogue and create opportunities for all
              parties.
            </p>
            <hr />
            <p>
              Cycling Connect was created to solve a problem that countless athletes and teams are
              forced to go through each year.
            </p>
            <p>
              The issue that we have worked to alleviate is shared by seasoned pros and blissful
              juniors alike; how to connect with your cycling dream team!
            </p>
            <p>
              Likewise, teams have been unable to tap into the vast market of riders who are willing
              to give their all if they were just given the opportunity.
            </p>
            <p>Clearly, Cycling Connect was created out of necessity.</p>
            <p>
              We have worked with everyone from Tour de France stage winners to team owners and up
              and coming junior cyclists, all of whom have helped us create the most streamlined way
              for teams and riders to connect.
            </p>
            <p>
              Technology has revolutionised the way in which recruiting occurs in the professional
              world, yet the cycling world has remained in the dark ages.{' '}
            </p>
            Previously, teams and riders have missed out on potentially life changing opportunities
            due to a non-existent communication stream.
            <p>
              Our platform allows riders and teams to create and customise their profile, research,
              search, and of course, connect!
            </p>
            <hr />
            <h4>For Riders</h4>
            <p>
              Cycling Connect gives riders a chance to put their best foot forward, with completely
              customisable profiles which can then be shared with interested teams. Thanks to the
              platforms smart integration, riders can include not only top results and performance
              data, but also things such as education, social media profiles, blog posts and photos,
              so teams can see the complete person as opposed to simply a w/kg number.
            </p>
            <p>
              Cycling Connect’s search capabilities allow you to seamlessly compare, connect and
              apply for teams all over the world. The platform is all about transparency, so you
              will be able to find the team that is right for you, instead of turning up on day one
              finding nothing like that which was promised.
            </p>
            <p>
              Riders no longer have to just ‘settle’ for a team, when the entire cycling world is
              now your oyster.
            </p>
            <hr />
            <h4>For Teams</h4>
            <p>
              Cycling Connect allows team owners to fill their valuable spots with riders who best
              fit their teams needs and holistic ethos.
            </p>
            <p>
              Teams have the ability to customise their own profile, allowing them to include all
              the information riders are looking for, such as race calendars, staff, sponsors and
              top results, along with their team philosophy and developmental pathways.
            </p>
            <p>Lacking a young climber? No problem... here’s a list of 20!</p>
            <p>Need an experienced Rouleur? Easy done… check out this selection!</p>
            <p>
              Simply set your criteria and search for your ideal rider from our database of talented
              cyclists.
            </p>
            <p>
              Once potential candidates have been identified, team owners can easily analyse the
              riders profile, request to see power files and connect with the athlete to convince
              them that your team is the one for them!
            </p>
          </Col>
        </Row>
      </Container>
      <SignUpBanner />
    </Fragment>
  );
};
