import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noticeActions } from '../../_actions';

function ContactModal(props) {
  const [message, setMessage] = useState('');
  const { isOpen, toggle, contactName, sending, noticeId, sent } = props;
  // if the email was sent, then we close the modal
  useEffect(() => {
    if (sent) {
      toggle();
      setMessage('');
    }
  }, [sent, toggle]);

  const handleSend = noticeId => {
    if (message && message.length) {
      props.emailNoticePoster({ noticeId, message });
      setMessage('');
    }
  };

  const handleCancel = () => {
    setMessage('');
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Respond to {contactName}'s Notice</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            type="textarea"
            name="message"
            onChange={e => setMessage(e.target.value)}
            placeholder="Your message"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {sending ? (
          <Button color="info">
            <FontAwesomeIcon icon="spinner" spin /> Sending
          </Button>
        ) : (
          <Button color="primary" onClick={() => handleSend(noticeId)}>
            Send
          </Button>
        )}{' '}
        <Button color="danger" onClick={handleCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function mapStateToProps(state) {
  const { sent, sending } = state.notice;
  return { sent, sending };
}

const connectedContactModal = connect(
  mapStateToProps,
  { emailNoticePoster: noticeActions.emailNoticePoster }
)(ContactModal);
export { connectedContactModal as ContactModal };
