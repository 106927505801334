import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { RichEditor } from '../Inputs';
import { CategoryCreate, CategorySelect, AddFeaturedImage } from './';
import { newsActions } from '../_actions';
import { ProfileSectionHeader } from '../Shared';

class NewsEdit extends React.Component {
  state = {};
  componentDidMount = () => {
    const slug = this.props.match.params.slug;
    this.props.listCategories();
    this.props.getNewsBySlug(slug);
  };

  componentDidUpdate = () => {
    const { image } = this.props.news;
    if (typeof image !== 'undefined' && !this.state.image) {
      this.setState({ image });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'title') {
      const slug = value.replace(/\s+/g, '-').toLowerCase();
      this.setState({ slug });
    }
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const old = this.props.news.single;
    const news = { ...old, ...this.state };

    this.props.updateNews(news);
  };

  render() {
    const {
      news,
      news: { single }
    } = this.props;
    return (
      <div>
        <Helmet title="Edit News - Cycling Connect" />
        <Container className="news">
          <Row>
            <Col md={8} className="create">
              <Container className="account-form">
                <ProfileSectionHeader headerText="Edit News" />
                <Form onSubmit={this.handleSubmit}>
                  {!news.singleLoading && single && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            name="title"
                            required
                            onChange={this.handleChange}
                            placeholder="Title"
                            defaultValue={single.title}
                          />
                        </FormGroup>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>cc.com/news/</InputGroupText>
                                  <Input
                                    name="slug"
                                    type="text"
                                    value={this.state.slug || single.slug}
                                    readOnly
                                  />
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <CategorySelect
                                handleChange={this.handleChange}
                                categories={news.categories}
                                currentCategoryId={single.categoryId}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <RichEditor
                            name="content"
                            initialState={single.content}
                            handleChange={this.handleChange}
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Input
                                type="textarea"
                                name="excerpt"
                                onChange={this.handleChange}
                                placeholder="Excerpt"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Button outline color="primary">
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Container>
            </Col>
            <Col md={4}>
              <CategoryCreate />
              <AddFeaturedImage existingImage={single ? single.image : ''} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatch = {
  listCategories: newsActions.listCategories,
  getNewsBySlug: newsActions.getNewsBySlug,
  updateNews: newsActions.updateNews
};
function mapStateToProps(state) {
  const { news } = state;
  return { news };
}

const connectedNewsEdit = connect(
  mapStateToProps,
  mapDispatch
)(NewsEdit);
export { connectedNewsEdit as NewsEdit };
