import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

function MyConnections(props) {
  const {
    following = {},
    pending = {},
    requests = {},
    approved = {},
    showApproved = false,
    team = false,
    rider = false
  } = props;
  return (
    <Container className="my-connections mt-5 mb-5">
      <Row>
        <Col xs={12}>
          <FormLabel rider={rider} team={team} text="My Connections" />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} tag={Link} to="/connections">
          <div className="stage-card shadow d-flex">
            <span className="stage-name flex-grow-1">Following</span>
            <span className="rider-count">{Object.keys(following).length}</span>
          </div>
        </Col>
        <Col sm={12} tag={Link} to="/connections">
          <div className="stage-card shadow d-flex">
            <span className="stage-name flex-grow-1">Outbound Requests</span>
            <span className="rider-count">{Object.keys(pending).length}</span>
          </div>
        </Col>
        <Col sm={12} tag={Link} to="/connections">
          <div className="stage-card shadow d-flex">
            <span className="stage-name flex-grow-1">Inbound Requests</span>
            <span className="rider-count">{Object.keys(requests).length}</span>
          </div>
        </Col>
        {showApproved && (
          <Col sm={12} tag={Link} to="/connections">
            <div className="stage-card shadow d-flex">
              <span className="stage-name flex-grow-1">Approved Connections</span>
              <span className="rider-count">{Object.keys(approved).length}</span>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  const { following, pending, requests, approved } = state.connections;
  return { following, pending, requests, approved };
}
const connectedMyConnections = connect(mapStateToProps)(MyConnections);
export { connectedMyConnections as MyConnections };
