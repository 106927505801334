import { headers, authFetch } from '../_helpers';

export const adminProfileService = {
  getProfile,
  updateProfile
};

async function getProfile() {
  const res = await authFetch(`/api/AdminProfile/Index`, headers.get());
  return res;
}

function updateProfile() {}
