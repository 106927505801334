import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import { AdminHeader } from '../Admin/AdminHeader';
import { newsActions } from '../_actions';
import { KingOfMountainLoader } from '../Loaders';
import { NewsCard, AddArticle } from '../News';

export function MyArticles() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(newsActions.listForUser());
  }, [dispatch]);

  const handleDelete = e => {
    dispatch(newsActions.deleteNews(e.target.value));
  };

  const { forUser, newsLoading } = useSelector(state => state.news);

  return (
    <>
      <Helmet title="Manage News - Cycling Connect" />
      <AdminHeader title="My Articles" />
      <Container>
        <Row>
          {newsLoading && (
            <Col>
              <KingOfMountainLoader />
            </Col>
          )}
          {!newsLoading && <AddArticle text="Add Article" href="/Articles/Add" />}
          {!newsLoading &&
            !isEmpty(forUser) &&
            forUser.map((l, idx) => (
              <NewsCard key={idx} news={l}>
                <NewsActions slug={l.slug} id={l.id} handleDelete={handleDelete} />
              </NewsCard>
            ))}
        </Row>
      </Container>
    </>
  );
}

function NewsActions({ slug, id, handleDelete }) {
  return (
    <div className="actions d-flex">
      <Button className="flex-fill" color="primary" tag={Link} to={`edit/${slug}`}>
        Edit
      </Button>
      <Button className="flex-fill" color="danger" value={id} onClick={handleDelete}>
        Delete
      </Button>
    </div>
  );
}
