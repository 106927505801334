import { headers, authFetch } from '../_helpers';

export const raceService = {
  listRaces,
  listRaceResults,
  createRace,
  updateRace,
  createRaceResult,
  updateRaceResult,
  deleteRaceResult
};

async function listRaces() {
  const response = await authFetch(`/api/Race/ListRaces`, headers.get());
  return response;
}

async function listRaceResults(raceId) {
  const response = await authFetch(`/api/Race/ListRaceResults`, headers.postPrimitive(raceId));
  return response;
}

async function createRace(race) {
  const response = await authFetch(`/api/Race/Create`, headers.post(race));
  return response;
}

async function updateRace(updatedRace) {
  const response = await authFetch(`/api/Race/Update`, headers.post(updatedRace));
  return response;
}

async function createRaceResult(result) {
  const response = await authFetch(`/api/Race/CreateResult`, headers.post(result));
  return response;
}

async function updateRaceResult(result) {
  const response = await authFetch(`/api/Race/UpdateResult`, headers.post(result));
  return response;
}

async function deleteRaceResult(resultId) {
  const response = await authFetch(`/api/Race/DeleteResult`, headers.postPrimitive(resultId));
  return response;
}
