import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { recruitActions } from '../_actions';

class ContactModal extends React.Component {
  state = {};
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSend = recruitId => {
    const { message } = this.state;
    if (message && message.length) {
      this.props.emailRecruit({ recruitId, message });
      this.setState({ message: '' });
    }
  };
  // if the email was sent, then we close the modal
  componentDidUpdate(prevProps) {
    if (prevProps.sent !== this.props.sent) {
      if (this.props.sent) {
        this.props.toggle();
      }
    }
  }

  handleCancel = () => {
    this.setState({ message: '' });
    this.props.toggle();
  };

  render() {
    const { isOpen, toggle, recruitId, contactName, sending } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Send an email to {contactName}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="textarea"
              name="message"
              onChange={this.handleChange}
              placeholder="Your message"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {sending ? (
            <Button color="info">
              <FontAwesomeIcon icon="spinner" spin /> Sending
            </Button>
          ) : (
            <Button color="primary" onClick={() => this.handleSend(recruitId)}>
              Send
            </Button>
          )}{' '}
          <Button color="danger" onClick={this.handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  const { sent, sending } = state.recruit;
  return { sent, sending };
}

const connectedContactModal = connect(
  mapStateToProps,
  { emailRecruit: recruitActions.emailRecruit }
)(ContactModal);
export { connectedContactModal as ContactModal };
