import React from 'react';
import PropTypes from 'prop-types';

export const SectionHeader = props => {
  const justifyContent = props.centered ? 'justify-content-center' : 'justify-content-start';
  const classes = `section-title text-center d-flex ${justifyContent}`;
  return (
    <div className={classes}>
      <h5 className="shadow-lg rounded">{props.titleText}</h5>
    </div>
  );
};

SectionHeader.propTypes = {
  centered: PropTypes.bool,
  titleText: PropTypes.any.isRequired
};
