import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Form,
  Jumbotron,
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import Helmet from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { teamProfileActions, racingLevelsActions } from '../_actions';
import { MyAccount, NotificationPreferences } from '../Account';
import {
  TeamContactEdit,
  TeamProfileDataEdit,
  TeamMemberEdit,
  TeamSocialEdit,
  TeamSummaryEdit,
  TeamRosterCreator,
  TeamRosterEditor,
  RaceManager
} from '.';
import { FormLabel, UnsavedChanges, ImageEditor } from '../Shared';

class TeamProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      teamProfile: {},
      activeTab: '1',
      unsavedChanges: false,
      navOpen: false
    };
    this.props.getProfile();
    this.props.list();
  }

  toggle = tab => {
    this.setState({ navOpen: !this.state.navOpen });

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleContactChange = e => {
    const { contact } = this.state;
    const { name, value } = e.target;
    contact[name] = value;
    this.setState({ contact, unsavedChanges: true });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = { ...this.state };
    // well this is awful.
    delete data.activeTab;
    this.props.updateProfile(data);
    this.setState({ unsavedChanges: false });
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, unsavedChanges: true });
  };

  radioButtonChange = (value, name) => {
    const teamProfile = { ...this.state.teamProfile };
    teamProfile[name] = value;
    this.setState({ teamProfile });
  };

  render() {
    const { user } = this.props;
    const { activeTab, navOpen } = this.state;
    const bgImg = '/img/banners/40-giro-2018.jpg';
    const backgroundStyles = {
      background: `linear-gradient(0deg, rgba(37, 27, 33, 0.45), rgba(37, 27, 33, 0.45)), url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: '60% 65%'
    };
    return (
      <Fragment>
        <Helmet title="Edit Team Profile - Cycling Connect" />
        <Jumbotron className="profile-edit-banner" style={backgroundStyles}>
          <Container>
            <Row>
              <Col>
                <h2 className="display-4">Update your Team Profile</h2>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container className="team-profile-edit" fluid>
          <Row>
            <Col sm={3} className="team-profile-nav">
              <Nav vertical className={classnames('nav-fill', { open: navOpen })}>
                <NavItem className={classnames({ active: activeTab === '0' })}>
                  <NavLink
                    href="#"
                    onClick={() => {
                      this.toggle('0');
                    }}
                  >
                    <strong>My Account</strong>
                    <FontAwesomeIcon icon="chevron-down" />
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ active: activeTab === '1' })}>
                  <NavLink
                    href="#"
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    <strong>Team Profile</strong>
                    <FontAwesomeIcon icon="chevron-down" />
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ active: activeTab === '4' })}>
                  <NavLink
                    href="#"
                    onClick={() => {
                      this.toggle('4');
                    }}
                  >
                    <strong>Members &amp; Contact</strong>
                    <FontAwesomeIcon icon="chevron-down" />
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ active: activeTab === '2' })}>
                  <NavLink
                    href="#"
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    <strong>Race Results</strong>
                    <FontAwesomeIcon icon="chevron-down" />
                  </NavLink>
                </NavItem>
                <NavItem className={classnames({ active: activeTab === '3' })}>
                  <NavLink
                    href="#"
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    <strong>Roster</strong>
                    <FontAwesomeIcon icon="chevron-down" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col style={{ background: `#f8f8f8` }}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="0">
                  <MyAccount team />
                  <NotificationPreferences team />
                </TabPane>
                <TabPane tabId="1">
                  {user && (
                    <Fragment>
                      <TeamProfileDataEdit
                        profile={user.teamProfile}
                        handleChange={this.handleChange}
                        racingLevels={this.props.racingLevels}
                        radioButtonChange={this.radioButtonChange}
                        seekingRiders={this.state.teamProfile.seekingRiders}
                      />
                      <TeamSummaryEdit
                        profile={user.teamProfile}
                        handleChange={this.handleChange}
                      />

                      <TeamSocialEdit profile={user.teamProfile} handleChange={this.handleChange} />
                      <Container className="account-form">
                        <Row>
                          <Col xs={12} className="pb-4">
                            <FormLabel team text="My Photos" />
                          </Col>
                          <Col>
                            <ImageEditor btnColor="team" upload={this.props.uploadProfilePhoto} />
                          </Col>
                          <Col>
                            {user.teamProfile.profilePhoto && (
                              <Fragment>
                                <h5>Current Profile Photo</h5>
                                <img
                                  src={user.teamProfile.profilePhoto}
                                  className="img-fluid d-block m-auto"
                                  width="300"
                                  alt="current"
                                />
                              </Fragment>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Fragment>
                  )}
                  <Form onSubmit={this.handleSubmit}>
                    <Container className="account-form floating-button">
                      <Row>
                        <Col>
                          <Button outline color="team" className="float-right">
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </TabPane>
                <TabPane tabId="4">
                  {user && (
                    <Fragment>
                      <TeamContactEdit
                        contact={user.teamProfile.contact}
                        handleChange={this.handleContactChange}
                      />
                      <TeamMemberEdit
                        profile={user.teamProfile}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                      />
                    </Fragment>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.unsavedChanges && <UnsavedChanges handleSubmit={this.handleSubmit} />}
                  <RaceManager />
                </TabPane>
                <TabPane tabId="3">
                  <TeamRosterCreator />
                  <TeamRosterEditor />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapDispatch = {
  getProfile: teamProfileActions.getProfile,
  list: racingLevelsActions.list,
  updateProfile: teamProfileActions.updateProfile,
  uploadProfilePhoto: teamProfileActions.uploadProfilePhoto
};

function mapStateToProps(state) {
  const racingLevels = state.racingLevels.levels;
  const { authentication } = state;
  const user = state.myProfile.user || null;
  return {
    authentication,
    user,
    racingLevels
  };
}

const connectedTeamProfile = connect(
  mapStateToProps,
  mapDispatch
)(TeamProfileEdit);
export { connectedTeamProfile as TeamProfileEdit };
