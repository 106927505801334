import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colours } from '../_helpers';

export function Subheader(props) {
  const { profile } = props;
  return (
    <Fragment>
      {profile.profilePhoto && (
        <div
          className="imgwrap"
          style={{ position: `absolute`, left: `50%`, transform: `translate(-50%,-50%)` }}
        >
          <img
            className="img-fluid"
            src={profile.profilePhoto}
            alt={profile.teamName}
            style={{
              borderRadius: `50%`,
              maxWidth: `150px`,
              maxHeight: `150px`,
              border: `2px solid ${colours.teamTheme.hover}`
            }}
          />
        </div>
      )}
      <Row
        style={{
          height: `61px`,
          borderBottom: `1px solid ${colours.teamTheme.hover}`,
          alignItems: `center`
        }}
      >
        <Col
          className="d-flex h-100 align-items-center justify-content-center"
          style={{ fontSize: `1.1rem` }}
        >
          <FontAwesomeIcon className="mr-2" icon="home" /> {profile.location}
        </Col>
        <Col
          className="d-flex h-100 align-items-center justify-content-center"
          style={{ fontSize: `1.1rem` }}
        >
          <FontAwesomeIcon className="mr-2" icon="microphone" /> {profile.language}
        </Col>
        <Col />
        <Col
          className="d-flex h-100 align-items-center justify-content-center"
          style={{ fontSize: `1.1rem` }}
        >
          <FontAwesomeIcon className="mr-2" icon="bicycle" /> {profile.racingLevelName}
        </Col>
        <Col
          className="d-flex h-100 align-items-center justify-content-center"
          style={{ fontSize: `1.1rem` }}
        >
          {profile.seekingRiders ? (
            <Fragment>
              <FontAwesomeIcon className="mr-2" icon="birthday-cake" /> Recruiting
            </Fragment>
          ) : (
            <Fragment>
              <FontAwesomeIcon className="mr-2" icon="birthday-cake" /> Not Recruiting
            </Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
