import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Row, Col } from 'reactstrap';
import { TeamCard, FollowFooter, PendingFooter, RequestFooter, ApprovedFooter } from '.';
import { PlaceholderCard, FormLabel } from '../Shared';

export const RiderConnections = props => {
  const { pending, requests, following, approved } = props;
  const {
    connect,
    cancel,
    unfollow,
    follow,
    decline,
    approve,
    declineAndFollow,
    removeModalToggle
  } = props;
  const style = { marginBottom: `2.25rem` };

  return (
    <Row className="mt-5">
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel rider text="Follows" />
            </Col>
          </Row>
          {isEmpty(following) && <PlaceholderCard message="Teams you follow will be listed here" />}
          {following &&
            Object.keys(following).map((team, index) => (
              <TeamCard key={index} team={following[team]}>
                <FollowFooter
                  user={following[team]}
                  connect={connect}
                  unfollow={unfollow}
                  disableConnect={!!requests[team] || !!pending[team] || !!approved[team]}
                />
              </TeamCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel rider text="Pending" />
            </Col>
          </Row>
          {isEmpty(pending) && (
            <PlaceholderCard message="Your outbound requests will display here" />
          )}
          {pending &&
            Object.keys(pending).map((team, index) => (
              <TeamCard key={index} team={pending[team]}>
                <PendingFooter
                  user={pending[team]}
                  cancel={cancel}
                  follow={follow}
                  disableFollow={!!following[team]}
                />
              </TeamCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel rider text="Requests" />
            </Col>
          </Row>
          {isEmpty(requests) && (
            <PlaceholderCard message="Your inbound requests will display here" />
          )}
          {requests &&
            Object.keys(requests).map((team, index) => (
              <TeamCard key={index} team={requests[team]}>
                <RequestFooter
                  user={requests[team]}
                  decline={decline}
                  approve={approve}
                  declineAndFollow={declineAndFollow}
                  hideDeclineAndFollow={!!following[team]}
                />
              </TeamCard>
            ))}
        </div>
      </Col>
      <Col sm={3}>
        <div className="card-container pb-2 mb-2">
          <Row style={style}>
            <Col>
              <FormLabel rider text="Connections" />
            </Col>
          </Row>
          {isEmpty(approved) && (
            <PlaceholderCard message="Your approved connections will display here" />
          )}
          {approved &&
            Object.keys(approved).map((team, index) => (
              <TeamCard key={index} team={approved[team]}>
                <ApprovedFooter
                  user={approved[team]}
                  isRider={false}
                  removeModalToggle={removeModalToggle}
                />
              </TeamCard>
            ))}
        </div>
      </Col>
    </Row>
  );
};
