import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { KingOfMountainLoader } from '../Loaders';
import { RiderSearchCard, SearchPagination } from './';
import { PlaceholderCard } from '../Shared';
import isEmpty from 'lodash/isEmpty';

function RiderSearchResults(props) {
  const perPage = 30;
  const { pageChange, loading, success, page, totalResults, results } = props;
  const startResults = page * perPage + 1;
  const endResults = totalResults <= perPage ? totalResults : (page + 1) * perPage;

  return (
    <Container>
      {loading && (
        <Row>
          <Col>
            <KingOfMountainLoader />
          </Col>
        </Row>
      )}
      {isEmpty(results) && success && (
        <Row>
          <Col>
            <PlaceholderCard message="No results for these terms" />
          </Col>
        </Row>
      )}
      {!isEmpty(results) && (
        <Fragment>
          <Row>
            <Col>
              Showing results {startResults} to {endResults}
            </Col>
          </Row>
          <Row>
            {results.map((rider, index) => (
              <RiderSearchCard key={index} rider={rider} />
            ))}
          </Row>

          <SearchPagination
            activePage={page}
            itemsCountPerPage={perPage}
            totalItemsCount={totalResults}
            onChange={pageChange}
          />
        </Fragment>
      )}
    </Container>
  );
}

function mapStateToProps(state) {
  const { loading, success, page, totalPages, totalResults, results } = state.riderSearch;
  return { loading, success, page, totalPages, totalResults, results };
}

const connectedRiderSearchResults = connect(mapStateToProps)(RiderSearchResults);
export { connectedRiderSearchResults as RiderSearchResults };
