import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
import { FormLabel } from '../Shared';

export const RidingDetails = props => {
  const { riderProfile, userDetails } = props;
  return (
    <Col xs={6}>
      <Col className="rider-data riding-details shadow mb-5 p-4" xs={12}>
        <FormLabel rider text="Riding Data" />

        <Row>
          {riderProfile.ridingStyleName && (
            <Col>
              <Card>
                <div className="label">Style</div>
                <div className="data">{riderProfile.ridingStyleName}</div>
              </Card>
            </Col>
          )}

          <Col>
            <Card>
              <div className="label">Level</div>
              <div className="data">{riderProfile.racingLevelName}</div>
            </Card>
          </Col>

          {userDetails.age && (
            <Col>
              <Card>
                <div className="label">Age</div>
                <div className="data">{userDetails.age}</div>
              </Card>
            </Col>
          )}

          {riderProfile.height && (
            <Col>
              <Card>
                <div className="label">Height</div>
                <div className="data">{riderProfile.height} cm</div>
              </Card>
            </Col>
          )}

          {riderProfile.weight && (
            <Col>
              <Card>
                <div className="label">Weight</div>
                <div className="data">{riderProfile.weight} kg</div>
              </Card>
            </Col>
          )}

          <Col>
            <Card>
              <div className="label">Sex</div>
              <div className="data">{riderProfile.sex}</div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

RidingDetails.propTypes = {
  userDetails: PropTypes.object.isRequired,
  riderProfile: PropTypes.object.isRequired
};
