import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TeamCard = props => {
  const { team } = props;
  if (!team) {
    return null;
  }
  return (
    team && (
      <div className="team-card shadow">
        <div className="team">
          <Row>
            <Col sm={3}>
              {team.profilePhoto ? (
                <img src={team.profilePhoto} alt="placeholder" className="img-fluid" />
              ) : (
                <div className="align-middle text-center mx-auto placeholder">
                  <FontAwesomeIcon icon="user" className="img-fluid" size="5x" />
                </div>
              )}
            </Col>
            <Col>{team.teamName}</Col>
          </Row>
        </div>

        {props.children}
      </div>
    )
  );
};
