import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const ProfileSlugInput = props => (
  <FormGroup>
    <Label for="profileSlug">Profile URL</Label>
    <Input
      name="profileSlug"
      id="profileSlug"
      defaultValue={props.profileSlug}
      onChange={props.handleChange}
    />
  </FormGroup>
);

ProfileSlugInput.propTypes = {
  profileSlug: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
