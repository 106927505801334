import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FollowButton } from '../../Follow';
import { ConnectionsButton } from '../../Connections';

export function OpenTeam(props) {
  const { team } = props;
  const { profileSlug } = team;
  const imageStyles = {
    background: `url('${team.profilePhoto}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    minHeight: `80px`
  };

  const profileUrl = `/team/${profileSlug}`;
  const profilePhoto = <div to={profileUrl} style={imageStyles} className="flex-grow-1" />;
  const noPhoto = (
    <div className="align-middle text-center mx-auto placeholder">
      <FontAwesomeIcon icon="user" className="img-fluid" size="5x" />
    </div>
  );
  return (
    <div className="open-team shadow mb-3">
      <Row>
        <Col xs={3} md={2} tag={Link} to={profileUrl} className="profile-img d-flex">
          {team.profilePhoto ? profilePhoto : noPhoto}
        </Col>
        <Col xs={9} md={7} tag={Link} to={profileUrl}>
          <p>
            <strong>
              {team.teamName}
              {team.country && ` from ${team.country}`}
            </strong>
          </p>
          <p>
            {team.onRoster ? team.onRoster : 'No'} Riders on Roster and {team.raceResults} Race
            Results
          </p>
        </Col>
        <Col>
          <Row>
            <Col>
              <ConnectionsButton color="team" block userId={team.id} userName={team.teamName} />
              <hr style={{ margin: `0.25rem 0` }} />
              <FollowButton color="team" block userId={team.id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
