import React from 'react';
import { Row, Col, Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { FormLabel } from '../Shared';
import isEmpty from 'lodash/isEmpty';
import { dateHelper, colours } from '../_helpers';
import { ContactModal } from '../Dashboard/Rider';
const defaultState = { contactModal: false, contactName: '', noticeId: '' };

class Notices extends React.Component {
  state = { ...defaultState };
  contactToggle = (contactName, noticeId) => {
    if (!this.state.contactModal) {
      this.setState({
        contactName,
        noticeId
      });
    }
    this.setState({
      contactModal: !this.state.contactModal
    });
  };
  render() {
    const { notices, user } = this.props;
    const userCanContact = isEmpty(user) || !(user && user.isRider);
    if (isEmpty(notices)) {
      return null;
    }
    return (
      <Col xs={12} sm={12}>
        <FormLabel team text="Notices" />
        <Row className="team-border rounded mt-5">
          <Col xs={12} className="shadow pb-4">
            <Row>
              {notices.map((n, index) => (
                <Col key={index} md={3}>
                  <NoticeCard
                    notice={n}
                    userCanContact={userCanContact}
                    contactToggle={this.contactToggle}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <ContactModal
          isOpen={this.state.contactModal}
          toggle={this.contactToggle}
          noticeId={this.state.noticeId}
          contactName={this.state.contactName}
        />
      </Col>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedNotices = connect(mapStateToProps)(Notices);
export { connectedNotices as Notices };

const NoticeCard = props => {
  const { notice, contactToggle, userCanContact } = props;
  const { team } = notice;
  const expiryColour = colours.greenToRed(notice.timeConsumed);
  const styles = {
    backgroundColor: expiryColour,
    borderColor: expiryColour
  };
  return (
    <div className="notice-card shadow">
      <div className="notice-detail">
        <strong>{team.teamName} »</strong> <em>{notice.text}</em>
        <br />
        Seeking a {notice.riderStyle ? notice.riderStyle.riderStyleName : 'rider of any style'}{' '}
        located {notice.region.length ? notice.region : 'anywhere'}.
      </div>
      <div>
        <ButtonGroup className="btn-block">
          <Button
            className="col"
            color="primary"
            onClick={() => contactToggle(team.teamName, notice.id)}
            disabled={userCanContact}
          >
            Contact
          </Button>
          <Button className="col-sm-3" style={styles} id={`notice-exp-${notice.id}`}>
            <FontAwesomeIcon icon="clock" />
          </Button>
          <UncontrolledTooltip target={`notice-exp-${notice.id}`} placement="top">
            Expires: {dateHelper.toShort(notice.dateExpires)}
          </UncontrolledTooltip>
        </ButtonGroup>
      </div>
    </div>
  );
};
