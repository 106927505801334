import { authFetch, headers } from '../_helpers';

export const todaysPlanService = {
  tokenGrant,
  peakWattsPerKg
};

async function tokenGrant(code) {
  const td = await authFetch(`/api/RiderProfile/TodaysPlan`, headers.postPrimitive(code));
  return td;
}

async function peakWattsPerKg() {
  const td = await authFetch(`/api/RiderProfile/PeakWattsPerKg`, headers.post());
  return td;
}
