import { riderCareerHighlightsConstants } from '../_constants';

export function riderCareerHighlights(state = {}, action) {
  switch (action.type) {
    case riderCareerHighlightsConstants.LIST_HIGHLGHTS_REQUEST:
    case riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_REQUEST:
    case riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_REQUEST:
    case riderCareerHighlightsConstants.LIST_HIGHLGHTS_FAILURE:
    case riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_FAILURE:
    case riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_FAILURE:
      return {
        ...state
      };
    case riderCareerHighlightsConstants.DELETE_CAREER_HIGHLIGHT_SUCCESS:
    case riderCareerHighlightsConstants.CREATE_UPDATE_CAREER_HIGHLIGHT_SUCCESS:
    case riderCareerHighlightsConstants.LIST_HIGHLGHTS_SUCCESS:
      return {
        ...state,
        list: action.data
      };
    default:
      return state;
  }
}
