import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const RiderSexSelect = props => {
  const { currentSexId, handleChange, sexes = [] } = props;
  const optionItems = sexes.map(sex => (
    <option key={sex.id} value={sex.id}>
      {sex.name}
    </option>
  ));

  return (
    <FormGroup>
      <Label for="riderSexId">Sex</Label>
      <Input
        name="riderSexId"
        id="riderSexId"
        type="select"
        className="custom-select"
        onChange={handleChange}
        defaultValue={currentSexId}
      >
        {currentSexId !== 'undefined' && <option>Choose...</option>}
        {optionItems}
      </Input>
    </FormGroup>
  );
};
RiderSexSelect.propTypes = {
  sexes: PropTypes.array.isRequired,
  currentSexId: PropTypes.number,
  handleChange: PropTypes.func.isRequired
};
