import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../../Shared';
import { PlaceholderCard } from '../../Shared';
import { NoticeCard } from '../../Notices';
import { noticeActions } from '../../_actions';
import isEmpty from 'lodash/isEmpty';

function MyNotices(props) {
  const { listTeamNotices, current } = props;
  useEffect(() => {
    listTeamNotices();
  }, [listTeamNotices]);

  return (
    <Container className="grey-bg mb-5 current-notices">
      <Row>
        <Col>
          <FormLabel team text="Current Notices" />
        </Col>
      </Row>
      <Row>
        {isEmpty(current) && (
          <Col>
            <PlaceholderCard message="No active Notices" />
          </Col>
        )}
        {current.map((cur, index) => (
          <Col className="mt-4" xs={12} key={index}>
            <NoticeCard notice={cur} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  const { current = [] } = state.notice;
  return { current };
}
const connectedMyNotices = connect(
  mapStateToProps,
  { listTeamNotices: noticeActions.listTeamNotices }
)(MyNotices);
export { connectedMyNotices as MyNotices };
