import { accountConstants, registerConstants } from '../_constants';
import { accountService } from '../_services';

export const accountActions = {
  get,
  update,
  save,
  register,
  forgotPassword,
  resetPassword,
  confirmEmail,
  mailingListStatus,
  toggleMailingList,
  deleteFromMailingList
};

function deleteFromMailingList(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.deleteFromMailingList().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.DELETE_FROM_MAILING_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.DELETE_FROM_MAILING_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.DELETE_FROM_MAILING_LIST_FAILURE, error };
  }
}

function toggleMailingList(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.toggleMailingList().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.TOGGLE_MAILING_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.TOGGLE_MAILING_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.TOGGLE_MAILING_LIST_FAILURE, error };
  }
}

function mailingListStatus() {
  return dispatch => {
    dispatch(request());
    accountService.mailingListStatus().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.CHECK_MC_STATUS_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.CHECK_MC_STATUS_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.CHECK_MC_STATUS_FAILURE, error };
  }
}

function register(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.register(data).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: registerConstants.REGISTER_REQUEST, data };
  }
  function success(data) {
    return { type: registerConstants.REGISTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: registerConstants.REGISTER_FAILURE, error };
  }
}

function update(data) {
  return dispatch => dispatch({ type: accountConstants.UPDATE_ACCT, data });
}

function save(data) {
  return dispatch => {
    dispatch(request());
    accountService.save(data).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.UPDATE_ACCT_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.UPDATE_ACCT_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.UPDATE_ACCT_FAILURE, error };
  }
}

function get() {
  return dispatch => {
    dispatch(request());
    accountService.get().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.GET_ACCT_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.GET_ACCT_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.GET_ACCT_FAILURE, error };
  }
}

function forgotPassword(email) {
  return dispatch => {
    dispatch(request({ email }));
    accountService.forgotPassword(email).then(
      message => {
        dispatch(success(message));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(user) {
    return { type: accountConstants.FORGOT_PASSWORD_REQUEST, user };
  }
  function success(message) {
    return { type: accountConstants.FORGOT_PASSWORD_SUCCESS, message };
  }
  function failure(error) {
    return { type: accountConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.resetPassword(data).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(user) {
    return { type: accountConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(data) {
    return { type: accountConstants.RESET_PASSWORD_SUCCESS, data };
  }
  function failure(errors) {
    return { type: accountConstants.RESET_PASSWORD_FAILURE, errors };
  }
}

function confirmEmail(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.confirmEmail(data).then(
      message => {
        dispatch(success(message));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.CONFIRM_EMAIL_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.CONFIRM_EMAIL_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.CONFIRM_EMAIL_FAILURE, error };
  }
}
