export const dashboardConstants = {
  LATEST_NEWS_REQUEST: 'LATEST_NEWS_REQUEST',
  LATEST_NEWS_SUCCESS: 'LATEST_NEWS_SUCCESS',
  LATEST_NEWS_FAILURE: 'LATEST_NEWS_FAILURE',
  SUGGESTED_CONNECTIONS_REQUEST: 'SUGGESTED_CONNECTIONS_REQUEST',
  SUGGESTED_CONNECTIONS_SUCCESS: 'SUGGESTED_CONNECTIONS_SUCCESS',
  SUGGESTED_CONNECTIONS_FAILURE: 'SUGGESTED_CONNECTIONS_FAILURE',
  TEAM_OPENINGS_REQUEST: 'TEAM_OPENINGS_REQUEST',
  TEAM_OPENINGS_SUCCESS: 'TEAM_OPENINGS_SUCCESS',
  TEAM_OPENINGS_FAILURE: 'TEAM_OPENINGS_FAILURE'
};
