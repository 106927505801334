import React from 'react';
import { connect } from 'react-redux';
import { Form, Container, Row, Col, FormGroup, Label, Button, Input } from 'reactstrap';
import { RefereeTypeSelect } from './';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '../Shared';
import { refereeTypesActions, riderRefereeActions } from '../_actions';
const defaultState = { name: '', contact: '', text: '', refereeTypeId: '' };

class RefereeCreator extends React.Component {
  state = { ...defaultState };

  componentDidMount = () => {
    this.props.getRefereeTypes();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, contact, text, refereeTypeId } = this.state;
    if (name && contact && text && refereeTypeId) {
      this.props.create({ name, contact, text, refereeTypeId });
      this.setState({ ...defaultState });
    }
  };

  render() {
    const { refereeTypes } = this.props;
    return (
      <Container className="account-form">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={12} className="pb-4">
              <FormLabel rider text="Add New Referee" />
            </Col>
            <Col xs={6} lg={{ size: 4, order: 1 }}>
              <FormGroup>
                <Label for="name">Referee Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Referee Name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={6} lg={{ size: 4, order: 2 }}>
              <FormGroup>
                <Label for="contact">Contact Detail</Label>
                <Input
                  name="contact"
                  id="contact"
                  type="text"
                  placeholder="Referee contact detail"
                  value={this.state.contact}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col xs={12} lg={{ size: 9, order: 4 }}>
              <FormGroup>
                <Label for="text">Reference</Label>
                <Input
                  name="text"
                  id="text"
                  placeholder="Reference provided"
                  type="textarea"
                  value={this.state.text}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            <Col xs={6} sm={6} lg={{ size: 4, order: 3 }}>
              {refereeTypes.types && (
                <RefereeTypeSelect
                  types={refereeTypes.types}
                  handleChange={this.handleChange}
                  fromState={this.state.refereeTypeId}
                />
              )}
            </Col>
            <Col xs={6} lg={{ size: 3, order: 5 }}>
              <FormGroup>
                <Label>Add</Label>
                <Button block outline color="primary">
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
const mapDispatch = {
  getRefereeTypes: refereeTypesActions.getRefereeTypes,
  create: riderRefereeActions.create
};
function mapStateToProps(state) {
  const { refereeTypes } = state;
  return { refereeTypes };
}

const connectedRefereeCreator = connect(
  mapStateToProps,
  mapDispatch
)(RefereeCreator);
export { connectedRefereeCreator as RefereeCreator };
