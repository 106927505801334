import { riderCareerHistoryConstants } from '../_constants';

export function riderCareerHistory(state = {}, action) {
  switch (action.type) {
    case riderCareerHistoryConstants.DELETE_CAREER_REQUEST:
    case riderCareerHistoryConstants.CREATE_UPDATE_CAREER_REQUEST:
    case riderCareerHistoryConstants.DELETE_CAREER_FAILURE:
    case riderCareerHistoryConstants.CREATE_UPDATE_CAREER_FAILURE:
    case riderCareerHistoryConstants.LIST_HISTORY_REQUEST:
    case riderCareerHistoryConstants.LIST_HISTORY_FAILURE:
      return {
        ...state
      };
    case riderCareerHistoryConstants.DELETE_CAREER_SUCCESS:
    case riderCareerHistoryConstants.CREATE_UPDATE_CAREER_SUCCESS:
    case riderCareerHistoryConstants.LIST_HISTORY_SUCCESS:
      return {
        ...state,
        list: action.data
      };
    default:
      return state;
  }
}
