import React, { useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { contributorActions } from '../_actions';

export function CurrentContributors() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contributorActions.getContributors());
  }, [dispatch]);

  const { list } = useSelector(state => state.contributors);

  return (
    <Container>
      <Row>
        <Col>
          <Table hover striped bordered size="sm">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Member Since</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {list.map((contrib, index) => (
                <tr key={index}>
                  <td className="align-middle">{contrib.firstName}</td>
                  <td className="align-middle">{contrib.lastName}</td>
                  <td className="align-middle">{contrib.email}</td>
                  <td className="align-middle">{contrib.signUpDate}</td>
                  <td>
                    <Button
                      color="warning"
                      value={contrib.id}
                      onClick={() => dispatch(contributorActions.removeContributor(contrib.id))}
                    >
                      Revoke
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
