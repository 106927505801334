import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import { Label, FormGroup } from 'reactstrap';
import debounce from 'lodash/debounce';
import { getTheme } from '../_helpers';

export const reactSelectStyles = {
  option: (base, state) => {
    const isNew = state.data.__isNew__ || false;
    return {
      ...base,
      fontWeight: isNew ? `bold` : 'inherit'
    };
  },
  container: () => ({
    border: 'none'
  }),
  control: (styles, state) => {
    const { isFocused } = state;
    const { team, rider } = state.selectProps;
    const theme = getTheme(team, rider);
    return {
      ...styles,
      background: `#fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5rem center`,
      backgroundSize: `8px 10px`,
      boxShadow: 'none',
      border: `1px solid ${isFocused ? theme.primary : theme.opaque}`,
      borderRadius: 2,
      ':hover': {
        borderColor: theme.primary
      }
    };
  },
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: '22px'
  }),
  input: base => ({
    ...base,
    color: `#7b8a8b`
  }),
  menu: (styles, state) => {
    const { team, rider } = state.selectProps;
    const theme = getTheme(team, rider);
    return {
      backgroundColor: '#fff',
      position: 'absolute',
      zIndex: 1,
      marginBottom: 0,
      marginTop: 5,
      width: '100%',
      maxWidth: '250px',
      border: `1px solid ${theme.primary}`,
      borderRadius: 2
    };
  }
};

const filterResults = (inputValue, data) => {
  if (data.length) {
    const opts = data
      .filter(i => i.demonym.toLowerCase().includes(inputValue.toLowerCase()))
      .map(r => ({
        value: r.demonym,
        label: `${r.demonym} (${r.name})`,
        countryCode: r.alpha2Code
      }));
    return opts;
  }
};

const loadOptions = (inputValue, callback) => {
  fetch(`https://restcountries.eu/rest/v2/name/${inputValue}`)
    .then(response => response.json())
    .then(data => {
      callback(filterResults(inputValue, data));
    });
};
const realLoadOptions = debounce(loadOptions, 350);

export const NationalityInput = props => {
  const { team = false, rider = false } = props;
  return (
    <FormGroup>
      <Label for="nationality">Nationality</Label>
      <AsyncSelect
        cacheOptions
        loadOptions={realLoadOptions}
        name="nationality"
        styles={reactSelectStyles}
        defaultInputValue={props.nationality}
        onChange={val => {
          props.handleChange(val);
        }}
        team={team}
        rider={rider}
      />
    </FormGroup>
  );
};

NationalityInput.propTypes = {
  nationality: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
