import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';

export const CareerHistory = props => {
  const {
    riderProfile: { careerHistory }
  } = props;

  return (
    careerHistory.length >= 1 && (
      <Col xs={12} md={6}>
        <Col xs={12} className="shadow rider-data career-history mb-5 pb-4">
          <FormLabel rider text="Career History" />
          <Row className="history">
            <Col>
              <ul>
                {careerHistory.map((h, index) => (
                  <li key={index}>
                    <strong>{h.teamName}:</strong> {h.startYear} - {h.endYear}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Col>
    )
  );
};

CareerHistory.propTypes = {
  riderProfile: PropTypes.object.isRequired
};
