import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { riderCareerHighlightsActions } from '../_actions/';
import { CareerHighlightsCreator } from './CareerHighlightsCreator';
import { CareerHighlightsItems } from './CareerHighlightsItems';

class CareerHighlightsEditor extends React.Component {
  state = {
    place: '',
    event: ''
  };

  componentDidMount = () => {
    this.props.listCareerHighlights();
  };

  handleChange = e => {
    const target = e.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  handleCreate = e => {
    e.preventDefault();
    if (this.state.place && this.state.event) {
      this.props.createUpdateCareerHighlight({ ...this.state });
      this.setState({ place: '', event: '' });
    }
  };

  handleUpdate = careerHighlight => {
    this.props.createUpdateCareerHighlight(careerHighlight);
  };

  handleDelete = (e, id) => {
    e.preventDefault();
    this.props.deleteCareerHighlight(id);
  };

  render() {
    return (
      <Fragment>
        <CareerHighlightsCreator
          handleCreate={this.handleCreate}
          handleChange={this.handleChange}
          place={this.state.place}
          event={this.state.event}
        />
        <CareerHighlightsItems
          highlights={this.props.riderCareerHighlights}
          handleUpdate={this.handleUpdate}
          handleDelete={this.handleDelete}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { riderCareerHighlights } = state;

  return { riderCareerHighlights };
}
const mapDispatch = {
  listCareerHighlights: riderCareerHighlightsActions.listCareerHighlights,
  createUpdateCareerHighlight: riderCareerHighlightsActions.createUpdateCareerHighlight,
  deleteCareerHighlight: riderCareerHighlightsActions.deleteCareerHighlight
};
const connectedCareerHighlightsEditor = connect(
  mapStateToProps,
  mapDispatch
)(CareerHighlightsEditor);
export { connectedCareerHighlightsEditor as CareerHighlightsEditor };
