import { accountConstants } from '../_constants';

export function account(state = {}, action) {
  switch (action.type) {
    case accountConstants.UPDATE_ACCT_SUCCESS:
    case accountConstants.GET_ACCT_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    case accountConstants.UPDATE_ACCT:
      const { name, value } = action.data;
      state[name] = value;
      return {
        ...state
      };
    case accountConstants.FORGOT_PASSWORD_REQUEST:
      return {
        success: false
      };
    case accountConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        success: true,
        message: action.message
      };
    case accountConstants.FORGOT_PASSWORD_FAILURE:
      return {
        success: false,
        error: action.error
      };
    case accountConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        success: false
      };
    case accountConstants.RESET_PASSWORD_FAILURE:
      return {
        success: false,
        message: 'Unable to reset password.',
        error: action.error
      };
    case accountConstants.RESET_PASSWORD_SUCCESS:
      return {
        success: true,
        message: action.data
      };
    case accountConstants.CONFIRM_EMAIL_FAILURE:
      return {
        success: false,
        error: action.error
      };
    case accountConstants.CONFIRM_EMAIL_SUCCESS:
      return {
        success: true,
        message: action.message
      };
    case accountConstants.CONFIRM_EMAIL_REQUEST:
      return {
        success: false
      };
    case accountConstants.CHECK_MC_STATUS_SUCCESS:
      return {
        ...state,
        mailingList: action.data
      };
    case accountConstants.TOGGLE_MAILING_LIST_SUCCESS:
      return {
        ...state,
        mailingList: action.data,
        togglingList: false
      };
    case accountConstants.DELETE_FROM_MAILING_LIST_REQUEST:
    case accountConstants.TOGGLE_MAILING_LIST_REQUEST:
      return {
        ...state,
        togglingList: true
      };
    case accountConstants.DELETE_FROM_MAILING_LIST_FAILURE:
    case accountConstants.TOGGLE_MAILING_LIST_FAILURE:
      return {
        ...state,
        togglingList: false
      };
    case accountConstants.DELETE_FROM_MAILING_LIST_SUCCESS:
      return {
        ...state,
        togglingList: false,
        mailingList: false
      };
    case accountConstants.CHECK_MC_STATUS_REQUEST:
    case accountConstants.CHECK_MC_STATUS_FAILURE:
    case accountConstants.GET_ACCT_REQUEST:
    case accountConstants.GET_ACCT_FAILURE:
    case accountConstants.UPDATE_ACCT_REQUEST:
    case accountConstants.UPDATE_ACCT_FAILURE:
    default:
      return state;
  }
}
