import React from 'react';
import { Table } from 'reactstrap';
export const List = props => {
  const { levels } = props;
  return (
    <div>
      <h4>Current Racing Levels</h4>
      <Table hover striped size="sm">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {levels &&
            levels.map((l, index) => (
              <tr key={index}>
                <td>{l.racingLevelName}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
