import { racingLevelsConstants } from '../_constants';

export function racingLevels(state = {}, action) {
  switch (action.type) {
    case racingLevelsConstants.RACING_LEVELS_GET_REQUEST:
    case racingLevelsConstants.RACING_LEVELS_GET_FAILURE:
    case racingLevelsConstants.RACING_LEVEL_UPDATE_REQUEST:
    case racingLevelsConstants.RACING_LEVEL_UPDATE_FAILURE:
    case racingLevelsConstants.RACING_LEVEL_CREATE_REQUEST:
    case racingLevelsConstants.RACING_LEVEL_CREATE_FAILURE:
    case racingLevelsConstants.RACING_LEVEL_DELETE_REQUEST:
    case racingLevelsConstants.RACING_LEVEL_DELETE_FAILURE:
    case racingLevelsConstants.RACING_LEVEL_GET_USE_REQUEST:
    case racingLevelsConstants.RACING_LEVEL_GET_USE_FAILURE:
      return { ...state };
    case racingLevelsConstants.RACING_LEVEL_UPDATE_SUCCESS:
    case racingLevelsConstants.RACING_LEVEL_CREATE_SUCCESS:
    case racingLevelsConstants.RACING_LEVELS_GET_SUCCESS:
    case racingLevelsConstants.RACING_LEVEL_DELETE_SUCCESS:
      return {
        ...state,
        levels: action.data
      };
    case racingLevelsConstants.RACING_LEVEL_GET_USE_SUCCESS:
      return {
        ...state,
        use: action.data
      };
    default:
      return state;
  }
}
