import 'react-app-polyfill/ie11';
import './bootstrap-main.min.css';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Provider } from 'react-redux';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { configureStore, iconSet } from './components/_helpers/';
import { saveState } from './components/_helpers/localStorage';
import throttle from 'lodash/throttle';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import { history } from './components/_helpers';

// only run Google Analytics in production
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-123757382-1');
}

library.add(...iconSet);
const store = configureStore();

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}

serviceWorker.unregister();
