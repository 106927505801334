import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Container, CustomInput, Row, Col, FormGroup, FormText } from 'reactstrap';
import { FormLabel } from '../Shared';
import { accountActions } from '../_actions';

export function NotificationPreferences({ team = false, rider = false }) {
  const {
    update,
    save,
    mailingListStatus,
    toggleMailingList,
    deleteFromMailingList
  } = accountActions;
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const { emailNotifications, mailingList, togglingList } = account;

  useEffect(() => {
    dispatch(mailingListStatus());
  }, [dispatch, mailingListStatus]);

  const saveUpdate = e => {
    const { name, checked } = e.target;
    dispatch(update({ name, value: checked }));
    dispatch(save({ ...account }));
  };

  const permanentDelete = () => {
    if (!togglingList) {
      dispatch(deleteFromMailingList());
    }
  };

  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team={team} rider={rider} text="Notifications" />
        </Col>
      </Row>
      <Row className="pt-2">
        <Col>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="emailNotifications"
              name="emailNotifications"
              label="Receive email notifications"
              checked={emailNotifications}
              onChange={e => saveUpdate(e)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="mailingList"
              name="mailingList"
              label="Receive occasional emails from Cycling Connect"
              disabled={togglingList}
              checked={mailingList}
              onChange={() => dispatch(toggleMailingList())}
            />
            <FormText style={{ cursor: 'pointer' }} onClick={permanentDelete}>
              Permanently remove yourself from the mailing list
            </FormText>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}

NotificationPreferences.propTypes = {
  team: PropTypes.bool,
  rider: PropTypes.bool
};
