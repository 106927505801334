import { newsConstants } from '../_constants';
const initialState = {
  categories: [],
  list: [],
  forUser: [],
  newsLoading: false,
  addedCategory: false
};
export function news(state = initialState, action) {
  switch (action.type) {
    case newsConstants.LIST_FOR_USER_REQUEST:
      return {
        ...state,
        newsLoading: true
      };
    case newsConstants.LIST_FOR_USER_SUCCESS:
      return {
        ...state,
        forUser: action.data,
        newsLoading: false
      };
    case newsConstants.LIST_FOR_USER_FAILURE:
      return {
        ...state,
        newsLoading: false
      };
    case newsConstants.DELETE_NEWS_SUCCESS:
      const newsId = action.newsId;
      const list = state.list.filter(el => {
        return parseInt(el.id) !== parseInt(newsId);
      });
      // delete it out of here as well just in case
      const forUser = state.forUser.filter(el => {
        return parseInt(el.id) !== parseInt(newsId);
      });
      return {
        ...state,
        list,
        forUser
      };
    case newsConstants.CREATE_NEWS_REQUEST:
    case newsConstants.CREATE_NEWS_SUCCESS:
    case newsConstants.CREATE_NEWS_FAILURE:
    case newsConstants.DELETE_NEWS_REQUEST:
    case newsConstants.CREATE_CATEGORY_FAILURE:
    case newsConstants.UPDATE_NEWS_FAILURE:
    case newsConstants.UPDATE_CATEGORY_REQUEST:
    case newsConstants.UPDATE_CATEGORY_FAILURE:
    case newsConstants.LIST_CATEGORY_REQUEST:
    case newsConstants.LIST_NEWS_FAILURE:
    case newsConstants.DELETE_NEWS_FAILURE:
    case newsConstants.LIST_CATEGORY_FAILURE:
    case newsConstants.UPDATE_NEWS_REQUEST:
    case newsConstants.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        addedCategory: false
      };
    case newsConstants.LIST_NEWS_REQUEST:
      return {
        ...state,
        newsLoading: true
      };
    case newsConstants.LIST_NEWS_SUCCESS:
      return {
        ...state,
        newsLoading: false,
        list: action.data
      };
    case newsConstants.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.data],
        addedCategory: true
      };
    case newsConstants.LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.data
      };
    case newsConstants.ADD_FEATURED_IMAGE_REQUEST:
      return {
        ...state,
        imgUploading: true
      };
    case newsConstants.ADD_FEATURED_IMAGE_FAILIURE:
      return {
        ...state,
        imgLoading: false
      };
    case newsConstants.ADD_FEATURED_IMAGE_SUCCESS:
      return {
        ...state,
        imgLoading: false,
        image: action.data.imageUrl
      };
    case newsConstants.NEWS_BY_SLUG_REQUEST:
      return {
        ...state,
        loadingSingle: true
      };
    case newsConstants.NEWS_BY_SLUG_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        single: action.data
      };
    case newsConstants.NEWS_BY_SLUG_FAILURE:
      return {
        ...state,
        loadingSingle: false,
        error: true
      };
    case newsConstants.UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        single: action.data
      };

    case newsConstants.UPDATE_CATEGORY_SUCCESS:
      const categories = state.categories.filter(i => i.id !== action.data.id);
      return {
        ...state,
        categories: [...categories, action.data]
      };

    default:
      return state;
  }
}
