import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  ButtonGroup,
  Button
} from 'reactstrap';
import { FormLabel } from '../Shared';
import { RacingLevelSelect, ProfileSlugInput, LocationFinder } from '../Inputs';

export const TeamProfileDataEdit = props => {
  const { profile, handleChange, seekingRiders } = props;
  return (
    <Container className="account-form">
      <Row>
        <Col xs={12} className="pb-4">
          <FormLabel team text="Team Profile" />
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="teamName">Team Name</Label>
            <Input
              name="teamName"
              id="teamName"
              defaultValue={profile.teamName}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
          <LocationFinder location={profile.location} handleChange={handleChange} />
        </Col>
        <Col xs={12} sm={6}>
          <FormGroup>
            <Label for="language">Language</Label>
            <Input
              name="language"
              id="language"
              defaultValue={profile.language}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col xs={12} sm={6}>
          {props.racingLevels && (
            <RacingLevelSelect
              handleChange={handleChange}
              levels={props.racingLevels}
              currentLevelId={profile.racingLevelId}
            />
          )}
        </Col>
        <Col xs={12} sm={6}>
          <ProfileSlugInput profileSlug={profile.profileSlug} handleChange={handleChange} />
        </Col>
        <Col sm={3} className="yes-no-switch">
          <FormGroup>
            <Label>Recruiting</Label>
            <InputGroup>
              <ButtonGroup>
                <Button
                  color="team"
                  onClick={() => props.radioButtonChange(true, 'seekingRiders')}
                  active={
                    typeof seekingRiders !== 'undefined' ? seekingRiders : profile.seekingRiders
                  }
                >
                  Yes
                </Button>
                <Button
                  color="team"
                  onClick={() => props.radioButtonChange(false, 'seekingRiders')}
                  active={
                    typeof seekingRiders !== 'undefined' ? !seekingRiders : !profile.seekingRiders
                  }
                >
                  No
                </Button>
              </ButtonGroup>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};
TeamProfileDataEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  radioButtonChange: PropTypes.func.isRequired,
  seekingRiders: PropTypes.bool
};
