const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const dateHelper = {
  toShort: getShortDate,
  nowPlusWeek: weekFromNow,
  birthdate,
  now
};

function birthdate(date) {
  const d = new Date(date);
  return d.toLocaleDateString();
}

export function dateFormatter(date) {
  const d = new Date(date);
  return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`;
}

export function getShortDate(date) {
  const d = new Date(date);
  return `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getFullYear()}`;
}

export function weekFromNow() {
  const now = new Date();
  const d = new Date();
  d.setDate(now.getDate() + 7);
  return d;
}
export function now() {
  const d = new Date();
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${day}`;
}
