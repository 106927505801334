export const adminConstants = {
  LIST_ADMINS_REQUEST: 'LIST_ADMINS_REQUEST',
  LIST_ADMINS_FAILURE: 'LIST_ADMINS_FAILURE',
  LIST_ADMINS_SUCCESS: 'LIST_ADMINS_SUCCESS',
  USER_LOOKUP_REQUEST: 'USER_LOOKUP_REQUEST',
  USER_LOOKUP_FAILURE: 'USER_LOOKUP_FAILURE',
  USER_LOOKUP_SUCCESS: 'USER_LOOKUP_SUCCESS',
  MAKE_USER_ADMIN_REQUEST: 'MAKE_USER_ADMIN_REQUEST',
  MAKE_USER_ADMIN_FAILURE: 'MAKE_USER_ADMIN_FAILURE',
  MAKE_USER_ADMIN_SUCCESS: 'MAKE_USER_ADMIN_SUCCESS',
  REVOKE_USER_ADMIN_REQUEST: 'REVOKE_USER_ADMIN_REQUEST',
  REVOKE_USER_ADMIN_FAILURE: 'REVOKE_USER_ADMIN_FAILURE',
  REVOKE_USER_ADMIN_SUCCESS: 'REVOKE_USER_ADMIN_SUCCESS',
  GET_SITE_SETTINGS_REQUEST: 'GET_SITE_SETTINGS_REQUEST',
  GET_SITE_SETTINGS_SUCCESS: 'GET_SITE_SETTINGS_SUCCESS',
  GET_SITE_SETTINGS_FAILURE: 'GET_SITE_SETTINGS_FAILURE',
  UPDATE_SITE_SETTINGS_REQUEST: 'UPDATE_SITE_SETTINGS_REQUEST',
  UPDATE_SITE_SETTINGS_SUCCESS: 'UPDATE_SITE_SETTINGS_SUCCESS',
  UPDATE_SITE_SETTINGS_FAILURE: 'UPDATE_SITE_SETTINGS_FAILURE'
};
