import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RoleRoute = ({
  component: Component,
  adminRoute = false,
  riderRoute = false,
  teamRoute = false,
  contributorRoute = false,
  ...rest
}) => {
  const { loggedIn, user } = useSelector(state => state.authentication);
  // if the user isn't logged in we should route them to the login page.
  if (!loggedIn || !user) {
    return <Redirect to="/login" />;
  }

  if (user.isRider && riderRoute) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else if (user.isTeamManager && teamRoute) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else if (user.isAdmin && adminRoute) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else if (user.isContributor && contributorRoute) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  } else {
    return <Redirect to="/unauthorized" />;
  }
};

RoleRoute.propTypes = {
  adminRoute: PropTypes.bool,
  riderRoute: PropTypes.bool,
  teamRoute: PropTypes.bool,
  contributorRoute: PropTypes.bool
};
