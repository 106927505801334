import { authenticationConstants } from '../_constants';
import { authenticationService } from '../_services';
import { history } from '../_helpers';
import { riderProfileActions, teamProfileActions, adminProfileActions } from '.';
export const authenticationActions = {
  login,
  logout,
  logoutNoRedirect
};

function login(email, password, from) {
  return dispatch => {
    dispatch(request({ email }));

    authenticationService.login(email, password).then(
      user => {
        dispatch(success(user));
        if (user.isRider) {
          dispatch(riderProfileActions.getProfile());
        } else if (user.isTeamManager) {
          dispatch(teamProfileActions.getProfile());
        } else if (user.isAdmin) {
          dispatch(adminProfileActions.getProfile());
        }
        if (user.redirectTo) {
          history.push(user.redirectTo);
        } else if (from.pathname !== '/') {
          history.push(from.pathname);
        } else {
          history.push('/');
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: authenticationConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authenticationConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authenticationConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  authenticationService.logout();
  history.push('/');
  return { type: authenticationConstants.LOGOUT };
}

function logoutNoRedirect() {
  authenticationService.logout();
  return { type: authenticationConstants.LOGOUT };
}
