import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { FormLabel } from '../Shared';
import { AddRace, RaceResults, RaceSelector, BlankRaceResultForm } from '.';
import { raceActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';

class RaceManager extends React.Component {
  state = { addRace: false, raceId: 0 };

  componentDidMount = () => {
    // this.props.listRaces();
  };

  toggleAddRace = () => {
    this.setState({ addRace: !this.state.addRace, raceId: 0, raceIndex: -1 });
  };

  selectRace = (raceId, raceIndex) => {
    if (raceId) {
      this.props.listRaceResults(raceId);
      this.setState({ raceId, raceIndex });
    }
  };

  render() {
    const { races } = this.props;

    return (
      <Container className="account-form">
        <Row>
          <Col xs={12} className="pb-4">
            <FormLabel team text="Race Results" />
          </Col>
          <Col md={12}>
            <AddRace />
          </Col>
        </Row>
        {!isEmpty(races) && (
          <Fragment>
            <hr />
            <Row>
              <Col sm={4}>
                <RaceSelector
                  races={races}
                  selectRace={this.selectRace}
                  raceIndex={this.state.raceIndex}
                />
              </Col>

              {!!this.state.raceId ? (
                <RaceResults raceId={this.state.raceId} />
              ) : (
                <BlankRaceResultForm />
              )}
            </Row>
          </Fragment>
        )}
      </Container>
    );
  }
}
const mapDispatch = {
  listRaces: raceActions.listRaces,
  listRaceResults: raceActions.listRaceResults
};
function mapStateToProps(state) {
  const {
    races: { races }
  } = state;
  return { races };
}

const connectedRaceManager = connect(
  mapStateToProps,
  mapDispatch
)(RaceManager);
export { connectedRaceManager as RaceManager };
