import React from 'react';
import { AccountFormHeader } from '../Shared';
import { Container, Row, Col } from 'reactstrap';

export const NotFound404 = () => {
  return (
    <Container fluid>
      <Row className="account-container">
        <Col className="account-form" xs={12} sm={{ size: 6, offset: 3 }}>
          <AccountFormHeader headerText="Not Found" />
          <h4>:( Not Found</h4>
          <p className="lead">We couldn't find the page you are looking for.</p>
        </Col>
      </Row>
    </Container>
  );
};
