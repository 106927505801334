import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { announcementActions } from './_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserAnnouncements(props) {
  const { loggedIn, forUser, listForUser } = props;

  useEffect(() => {
    if (loggedIn) {
      listForUser();
    }
  }, [listForUser, loggedIn]);

  if (!loggedIn || !forUser) {
    return null;
  }

  return (
    <div className="user-announcements">
      {forUser.map((a, idx) => (
        <div key={idx} className="announcement">
          <div className="container">
            <div className="content" dangerouslySetInnerHTML={{ __html: a.content }} />
            <div className="hide" onClick={() => props.hide(a.id)}>
              <FontAwesomeIcon icon="times" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function mapStateToProps(state) {
  const { loggedIn = false } = state.authentication;
  const { forUser } = state.announcement;
  return { loggedIn, forUser };
}

const mapDispatchToProps = {
  listForUser: announcementActions.listForUser,
  hide: announcementActions.hide
};

const connectedAnnouncements = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAnnouncements);
export { connectedAnnouncements as UserAnnouncements };
