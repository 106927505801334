import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export const TwitterInput = props => (
  <FormGroup>
    <Label for="twitterUrl">Twitter</Label>
    <Input
      name="twitterUrl"
      id="twitterUrl"
      defaultValue={props.twitterUrl}
      onChange={props.handleChange}
    />
  </FormGroup>
);
TwitterInput.propTypes = {
  twitterUrl: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
