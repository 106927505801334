import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RacingLevelSelect = props => {
  const { levels, handleChange, currentLevelId, showTooltip, labelText = 'Racing Level' } = props;
  const optionItems = levels.map(level => (
    <option key={level.racingLevelId} value={level.racingLevelId}>
      {level.racingLevelName}
    </option>
  ));
  return (
    <FormGroup>
      <Label for="racingLevel">
        {labelText}
        {showTooltip && (
          <span href="#" id="racing-level-tooltip">
            {' '}
            <FontAwesomeIcon icon="question-circle" />
          </span>
        )}
      </Label>
      {showTooltip && (
        <UncontrolledTooltip placement="top" target="racing-level-tooltip">
          The current level you race at
        </UncontrolledTooltip>
      )}

      <Input
        type="select"
        className="custom-select"
        name="racingLevelId"
        defaultValue={currentLevelId}
        onChange={handleChange}
      >
        {currentLevelId !== 'undefined' && <option>Choose...</option>}

        {optionItems}
      </Input>
    </FormGroup>
  );
};

RacingLevelSelect.propTypes = {
  levels: PropTypes.array.isRequired,
  currentLevelId: PropTypes.number,
  handleChange: PropTypes.func.isRequired
};
