import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, CustomInput } from 'reactstrap';
export function EmailOptIn({ handleChange, checked, className = '' }) {
  return (
    <FormGroup className={`bg white p-1 ${className}`}>
      <CustomInput
        type="checkbox"
        id="newsletterOptIn"
        name="newsletterOptIn"
        label="Subscribe to Updates"
        checked={checked}
        onChange={() => handleChange(!checked)}
      />
    </FormGroup>
  );
}
EmailOptIn.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};
