import React from 'react';
import PropTypes from 'prop-types';

export const ProfileSectionHeader = props => (
  <div className="profile-section-title text-center d-flex justify-content-start">
    <h5 className="shadow-lg rounded">{props.headerText}</h5>
  </div>
);

ProfileSectionHeader.propTypes = {
  headerText: PropTypes.string.isRequired
};
