import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  Jumbotron,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Form
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyAccount, NotificationPreferences } from '../Account';
import {
  RiderAboutMeEdit,
  RidingDetailsEdit,
  RiderSocialEdit,
  RefereeCreator,
  RefereeEditor,
  RiderPowerEditor,
  CareerHistoryCreator,
  CareerHistoryEditor,
  CareerHighlightsEditor,
  PrivateDataNotice
} from '.';
import { UnsavedChanges, ImageEditor, FormLabel } from '../Shared';
import { RichEditor } from '../Inputs';
import {
  riderProfileActions,
  racingLevelsActions,
  riderStylesActions,
  riderSexesActions
} from '../_actions';
import { TodaysPlan } from './TodaysPlan';

class RiderProfileEdit extends React.Component {
  state = {
    riderProfile: {},
    careerHistory: {},
    activeTab: '1',
    unsavedChanges: false,
    navOpen: false
  };

  componentDidMount = () => {
    this.props.getProfile();
    this.props.list();
    this.props.getRiderStyles();
    this.props.getRiderSexes();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, dateOfBirth, riderProfile } = this.state;
    this.props.updateProfile(firstName, lastName, dateOfBirth, riderProfile);
    this.setState({ unsavedChanges: false });
  };

  handleUserChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value, unsavedChanges: true });
  };

  handleNationalityChange = value => {
    const riderProfile = { ...this.state.riderProfile };
    riderProfile['countryCode'] = value.countryCode;
    riderProfile['nationality'] = value.value;
    this.setState({ riderProfile, unsavedChanges: true });
  };

  handleProfileChange = e => {
    const { name, value } = e.target;
    const riderProfile = { ...this.state.riderProfile };
    riderProfile[name] = value;
    this.setState({ riderProfile, unsavedChanges: true });
  };

  radioButtonChange = (value, property) => {
    const riderProfile = { ...this.state.riderProfile };
    riderProfile[property] = value;
    this.setState({ riderProfile, unsavedChanges: true });
  };

  toggle = tab => {
    this.setState({ navOpen: !this.state.navOpen });
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const navItems = [
      { activeTab: '0', label: 'My Account' },
      { activeTab: '1', label: 'About Me' },
      { activeTab: '2', label: 'Career History' },
      { activeTab: '3', label: 'Career Highlights' },
      { activeTab: '4', label: 'Referees' },
      { activeTab: '5', label: 'Power Data' }
    ];
    const {
      user,
      riderSexes: { sexes },
      racingLevels,
      ridingStyles
    } = this.props;
    const { activeTab, navOpen } = this.state;
    return (
      <Fragment>
        <Helmet title="My Profile - Cycling Connect" />
        <Jumbotron className="rider-profile-edit-banner">
          <Container>
            <Row>
              <Col>
                <h2 className="display-4">Update your Profile</h2>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container className="rider-profile-edit" fluid>
          <Row>
            <Col sm={3} className="rider-profile-nav">
              <Nav vertical className={classnames('nav-fill', { open: navOpen })}>
                {navItems.map(n => (
                  <NavItem
                    key={n.activeTab}
                    className={classnames({ active: activeTab === n.activeTab })}
                  >
                    <NavLink
                      href="#"
                      onClick={() => {
                        this.toggle(n.activeTab);
                      }}
                    >
                      <strong>{n.label}</strong>
                      <FontAwesomeIcon icon="chevron-down" />
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
            <Col style={{ background: `#f8f8f8` }}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="0">
                  <MyAccount rider />
                  <NotificationPreferences rider />
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    {user && (
                      <Col sm="12">
                        <RiderAboutMeEdit
                          user={user}
                          profile={user.riderProfile}
                          sexes={sexes}
                          handleUserChange={this.handleUserChange}
                          handleProfileChange={this.handleProfileChange}
                          handleNationalityChange={this.handleNationalityChange}
                        />
                        <Container className="account-form">
                          <Row>
                            <Col xs={12} className="pb-5">
                              <FormLabel rider text="Biography" />
                            </Col>
                            <Col>
                              <RichEditor
                                initialState={user.riderProfile.biography}
                                handleChange={this.handleProfileChange}
                                name="biography"
                                className="rider"
                              />
                            </Col>
                          </Row>
                        </Container>
                        <RidingDetailsEdit
                          profile={user.riderProfile}
                          stateProfile={this.state.riderProfile}
                          ridingStyles={ridingStyles}
                          racingLevels={racingLevels}
                          handleProfileChange={this.handleProfileChange}
                          radioButtonChange={this.radioButtonChange}
                        />

                        <Container className="account-form">
                          <Row>
                            <Col xs={12} className="pb-4">
                              <FormLabel rider text="My Photos" />
                            </Col>
                            <Col>
                              <ImageEditor
                                btnColor="rider"
                                upload={this.props.uploadProfilePhoto}
                              />
                            </Col>
                            <Col>
                              {user.riderProfile.profilePhoto && (
                                <Fragment>
                                  <h5>Current Profile Photo</h5>
                                  <img
                                    src={user.riderProfile.profilePhoto}
                                    className="img-fluid d-block m-auto"
                                    width="300"
                                    alt="current"
                                  />
                                </Fragment>
                              )}
                            </Col>
                          </Row>
                        </Container>

                        <RiderSocialEdit
                          profile={user.riderProfile}
                          handleProfileChange={this.handleProfileChange}
                        />
                        <Form onSubmit={this.handleSubmit}>
                          <Container className="account-form floating-button">
                            <Row>
                              <Col>
                                <Button outline color="primary" className="float-right">
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Form>
                      </Col>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      {this.state.unsavedChanges && (
                        <UnsavedChanges handleSubmit={this.handleSubmit} />
                      )}

                      {user && user.riderProfile && (
                        <Fragment>
                          <CareerHistoryCreator />
                          <CareerHistoryEditor />
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <CareerHighlightsEditor />
                </TabPane>
                <TabPane tabId="4">
                  <PrivateDataNotice />
                  <RefereeCreator />
                  <RefereeEditor />
                </TabPane>
                <TabPane tabId="5">
                  <PrivateDataNotice />
                  <TodaysPlan />
                  <RiderPowerEditor />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  getProfile: riderProfileActions.getProfile,
  list: racingLevelsActions.list,
  getRiderStyles: riderStylesActions.getRiderStyles,
  getRiderSexes: riderSexesActions.getRiderSexes,
  updateProfile: riderProfileActions.updateProfile,
  uploadProfilePhoto: riderProfileActions.uploadProfilePhoto
};
function mapStateToProps(state) {
  const racingLevels = state.racingLevels.levels;
  const ridingStyles = state.riderStyles.styles;
  const { authentication, myProfile, riderSexes } = state;
  const user = state.myProfile.user || null;
  return {
    authentication,
    myProfile,
    user,
    ridingStyles,
    racingLevels,
    riderSexes
  };
}

const connectedRiderProfile = connect(
  mapStateToProps,
  mapDispatch
)(RiderProfileEdit);
export { connectedRiderProfile as RiderProfileEdit };
