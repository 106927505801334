import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { connectionsActions } from '../_actions';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RemoveModal } from './';
const defaultState = { hovering: false, removeModal: false };

class ConnectedButton extends React.Component {
  state = { ...defaultState };

  removeModalToggle = e => {
    const { removeModal } = this.state;
    if (!removeModal) {
      const { value } = e.target;
      this.props.setRemovingConnection(value);
    }

    this.setState({ removeModal: !removeModal });
  };

  mouseEnter = () => {
    this.setState({ hovering: !this.state.hovering });
  };

  mouseLeave = () => {
    this.setState({ hovering: !this.state.hovering });
  };

  render() {
    const { size, userId, block = false, color } = this.props;
    const { hovering } = this.state;
    return (
      <Fragment>
        <div
          className="connect approved"
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
        >
          {!hovering ? (
            <Button block={block} color={color} size={size}>
              <FontAwesomeIcon icon="check-circle" /> Connected
            </Button>
          ) : (
            <Button
              block={block}
              color={color}
              size={size}
              value={userId}
              onClick={this.removeModalToggle}
            >
              <FontAwesomeIcon icon="times-circle" style={{ color: 'red' }} /> Disconnect
            </Button>
          )}
        </div>
        <RemoveModal isOpen={this.state.removeModal} toggle={this.removeModalToggle} />
      </Fragment>
    );
  }
}
const mapDispatch = {
  setRemovingConnection: connectionsActions.setRemovingConnection
};
function mapStateToProps(state) {
  const { pending, approved, requests } = state.connections;
  return { pending, approved, requests };
}

const connectedConnectedButton = connect(
  mapStateToProps,
  mapDispatch
)(ConnectedButton);
export { connectedConnectedButton as ConnectedButton };
