import { racingLevelsConstants } from '../_constants';
import { racingLevelsService } from '../_services';

export const racingLevelsActions = {
  list,
  create,
  update,
  remove,
  getUse
};

function list() {
  return dispatch => {
    dispatch(request());

    racingLevelsService.list().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: racingLevelsConstants.RACING_LEVELS_GET_REQUEST, data };
  }
  function success(data) {
    return { type: racingLevelsConstants.RACING_LEVELS_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: racingLevelsConstants.RACING_LEVELS_GET_FAILURE, error };
  }
}

function create(newLevel) {
  return dispatch => {
    dispatch(request(newLevel));

    racingLevelsService.create(newLevel).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: racingLevelsConstants.RACING_LEVEL_CREATE_FAILURE, error };
  }
}

function update(racingLevel) {
  return dispatch => {
    dispatch(request(racingLevel));

    racingLevelsService.update(racingLevel).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_UPDATE_REQUEST, data };
  }
  function success(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: racingLevelsConstants.RACING_LEVEL_UPDATE_FAILURE, error };
  }
}

function remove(deleteRacingLevelDto) {
  return dispatch => {
    dispatch(request(deleteRacingLevelDto));

    racingLevelsService.remove(deleteRacingLevelDto).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_DELETE_REQUEST, data };
  }
  function success(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: racingLevelsConstants.RACING_LEVEL_DELETE_FAILURE, error };
  }
}

function getUse(racingLevelId) {
  return dispatch => {
    dispatch(request(racingLevelId));

    racingLevelsService.getUse(racingLevelId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_GET_USE_REQUEST, data };
  }
  function success(data) {
    return { type: racingLevelsConstants.RACING_LEVEL_GET_USE_SUCCESS, data };
  }
  function failure(error) {
    return { type: racingLevelsConstants.RACING_LEVEL_GET_USE_FAILURE, error };
  }
}
