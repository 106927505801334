import { connectionsConstants } from '../_constants';
import { connectionsService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const connectionsActions = {
  follow,
  unfollow,
  connect,
  approve,
  decline,
  declineAndFollow,
  cancel,
  list,
  remove,
  setRemovingConnection
};

function follow(toUserId) {
  return dispatch => {
    dispatch(request(toUserId));
    connectionsService.follow(toUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: connectionsConstants.FOLLOW_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.FOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.FOLLOW_FAILURE, error };
  }
}

function unfollow(toUserId) {
  return dispatch => {
    dispatch(request(toUserId));
    connectionsService.unfollow(toUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: connectionsConstants.UNFOLLOW_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.UNFOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.UNFOLLOW_FAILURE, error };
  }
}

function connect(toUserId, userName) {
  return dispatch => {
    dispatch(request());

    connectionsService.connect(toUserId).then(
      data => {
        dispatch(success(data));
        toastr.success(
          'Connection request sent!',
          `We've sent an email to ${userName}, we'll let you know what happens next.`,
          { timeOut: 15000 }
        );
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_CREATE_FAILURE, error };
  }
}

function cancel(toUserId) {
  return dispatch => {
    dispatch(request(toUserId));

    connectionsService.cancel(toUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_CANCEL_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_CANCEL_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_CANCEL_FAILURE, error };
  }
}

function approve(fromUserId) {
  return dispatch => {
    dispatch(request(fromUserId));

    connectionsService.approve(fromUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_APPROVE_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_APPROVE_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_APPROVE_FAILURE, error };
  }
}

function decline(fromUserId) {
  return dispatch => {
    dispatch(request(fromUserId));

    connectionsService.decline(fromUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_FAILURE, error };
  }
}

function declineAndFollow(fromUserId) {
  return dispatch => {
    dispatch(request(fromUserId));
    connectionsService.declineAndFollow(fromUserId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_DECLINE_FOLLOW_FAILURE, error };
  }
}

function list() {
  return dispatch => {
    dispatch(request());

    connectionsService.list().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.CONNECTIONS_LIST_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.CONNECTIONS_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.CONNECTIONS_LIST_FAILURE, error };
  }
}

function remove(remove) {
  return dispatch => {
    dispatch(request(remove));

    connectionsService.remove(remove).then(
      data => {
        dispatch(success(data));
        toastr.success('Connection Removed');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(data) {
    return { type: connectionsConstants.REMOVE_CONNECTION_REQUEST, data };
  }
  function success(data) {
    return { type: connectionsConstants.REMOVE_CONNECTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: connectionsConstants.REMOVE_CONNECTION_FAILURE, error };
  }
}

function setRemovingConnection(userId) {
  return dispatch => {
    dispatch(request(userId));
  };
  function request(userId) {
    return { type: connectionsConstants.SET_REMOVING_CONNECTION, userId };
  }
}
