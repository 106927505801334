import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { accountActions, authenticationActions as authActions } from '../_actions';
import { TextInput, PasswordInput, EmailInput, EmailOptIn } from '../HookInputs';
import { Error } from './Error';
import { FormLabel } from '../Shared';
import { useRegistration } from '../_hooks';

export function Register() {
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    newsletterOptIn,
    email,
    emailValid,
    password,
    passwordValid,
    setFirstName,
    setLastName,
    setNewsletterOptIn,
    setEmail,
    validateEmail,
    setPassword,
    validatePassword
  } = useRegistration();

  const [accountType, setAccountType] = useState(0);
  const accountTypes = ['Rider', 'Team Manager'];

  useEffect(() => {
    dispatch(authActions.logoutNoRedirect());
  }, [dispatch]);

  const { success, failure, registering, error } = useSelector(state => state.register);

  const handleSubmit = e => {
    e.preventDefault();
    if (email && password && accountType && !registering) {
      const data = { email, password, accountType, firstName, lastName, newsletterOptIn };
      dispatch(accountActions.register(data));
    }
  };

  const style = {
    position: `absolute`,
    backgroundColor: `var(--primary)`,
    padding: `0.5rem 1rem`,
    color: `#fff`,
    top: `-1.5rem`,
    fontSize: `1.25rem`,
    transform: `rotate(-3deg)`
  };
  return (
    <Container className="account-container registration" fluid>
      <Helmet title="Create Your Account - Cycling Connect">
        <meta
          name="description"
          content="Sign up to Cycling Connect in a matter of seconds and start building your dream career."
        />
      </Helmet>
      {!success ? (
        <Row style={{ width: `100%` }}>
          <Col xs={12} md={{ size: 6, offset: 3 }} className="account-form">
            <FormLabel text="Register" />

            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="accountType" className="sr-only">
                  I am a
                </Label>
                <Input
                  type="select"
                  className="custom-select mt-5"
                  name="accountType"
                  id="accountType"
                  required
                  value={accountType}
                  onChange={e => setAccountType(e.target.value)}
                >
                  <option value="">Select your account type</option>
                  {accountTypes.map((acct, idx) => (
                    <option key={idx} value={acct}>
                      {acct}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <TextInput
                id="firstName"
                label="First Name"
                name="firstName"
                value={firstName}
                placeholder="First Name"
                required
                handleChange={setFirstName}
              />
              <TextInput
                id="lastName"
                label="Last Name"
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                required
                handleChange={setLastName}
              />
              <EmailInput
                valid={emailValid}
                handleChange={setEmail}
                value={email}
                validate={validateEmail}
              />
              <PasswordInput
                valid={passwordValid}
                handleChange={setPassword}
                value={password}
                validate={validatePassword}
              />
              <EmailOptIn
                className="border-primary br-2"
                checked={newsletterOptIn}
                handleChange={setNewsletterOptIn}
              />
              {failure && <Error className="border-primary br-2" error={error} />}
              <Row>
                <Col xs={12} sm={4}>
                  <Button color="primary" disabled={registering}>
                    Register
                  </Button>
                </Col>
                <Col xs={12} sm={8} className="d-flex justify-content-end">
                  <Button tag={Link} to="/forgotpassword">
                    Reset Password
                  </Button>{' '}
                  <Button color="info" tag={Link} to="/login">
                    Already Registered?
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} md={{ size: 6, offset: 3 }} className="account-form">
            <span style={style}>Thanks for Registering!</span>
            <p className="message">
              Thanks for creating an account, please check your email to confirm your account. Once
              confirmed, you can log in and create your profile.
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
}
