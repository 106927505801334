import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
export function PasswordInput({ valid, handleChange, value, validate }) {
  return (
    <FormGroup>
      <Label for="password" className="sr-only">
        Password
      </Label>
      <Input
        type="password"
        name="password"
        id="password"
        placeholder="Password"
        required
        autoComplete="new-password"
        invalid={valid != null && !valid}
        valid={valid}
        value={value}
        onChange={e => {
          handleChange(e.target.value);
          validate(e.target.value);
        }}
      />
      <FormFeedback>
        Passwords must contain upper and lower case characters, at least one number, and be at least
        six characters long
      </FormFeedback>
    </FormGroup>
  );
}
PasswordInput.propTypes = {
  valid: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired
};
