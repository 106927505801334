import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { RichEditor } from '../Inputs';
import { CategoryCreate, CategorySelect, AddFeaturedImage } from './';
import { newsActions } from '../_actions';
import { FormLabel } from '../Shared';

class NewsCreate extends React.Component {
  state = { image: '', slug: '' };

  componentDidMount = () => {
    this.props.listCategories();
  };

  componentDidUpdate = () => {
    const { image } = this.props.news;
    if (typeof image !== 'undefined' && !this.state.image) {
      this.setState({ image });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === 'title') {
      const slug = value.replace(/\s+/g, '-').toLowerCase();
      this.setState({ slug });
    }
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { sendTo } = this.props;
    this.props.createNews(this.state, sendTo);
  };

  render() {
    const { news } = this.props;
    return (
      <div>
        <Helmet title="Add News - Cycling Connect" />
        <Container className="news">
          <Row>
            <Col md={8} className="create">
              <Container className="account-form">
                <Row>
                  <Col className="pb-4">
                    <FormLabel text="Create News" />
                  </Col>
                </Row>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          type="text"
                          name="title"
                          required
                          onChange={this.handleChange}
                          placeholder="Title"
                        />
                      </FormGroup>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>cc.com/news/</InputGroupText>
                                <Input name="slug" type="text" value={this.state.slug} readOnly />
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <CategorySelect
                              handleChange={this.handleChange}
                              categories={news.categories}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <RichEditor name="content" handleChange={this.handleChange} />
                      </FormGroup>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="excerpt"
                              onChange={this.handleChange}
                              placeholder="Excerpt"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Button outline color="primary">
                          Post
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Col>
            <Col md={4}>
              <CategoryCreate />
              <AddFeaturedImage createNews />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapDispatch = {
  listCategories: newsActions.listCategories,
  createNews: newsActions.createNews
};
function mapStateToProps(state) {
  const { news } = state;
  const { isAdmin } = state.authentication.user;
  const sendTo = isAdmin ? '/Admin/News' : '/Articles';
  return { news, sendTo };
}

const connectedNewsCreate = connect(
  mapStateToProps,
  mapDispatch
)(NewsCreate);
export { connectedNewsCreate as NewsCreate };
